//@ts-nocheck
import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";

interface S3 {
  objectURL: string;
}

const initialState: S3 = {
  objectURL: "",
};

const s3Slice = createSlice({
  name: "s3",
  initialState,
  reducers: {
    SET_OBJECT_URL: (state, action: PayloadAction<string>) => {
      state.objectURL = action.payload;
    },
  },
});

export const { SET_OBJECT_URL } = s3Slice.actions;
export default s3Slice.reducer;
