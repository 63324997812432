import {
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import {
  SET_BANK_NAME,
  SET_BIN_CODE,
  SET_CARD1_DETAILS,
  SET_CARD2_DETAILS,
  SET_JOINT_POPUP,
} from "../../Features/cart";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  inputRoot: {
    "& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button":
    {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
});

const JointPopup = () => {
  //@ts-ignore
  const classes = useStyles();
  const payment = useSelector((state: RootState) => state.cart.payment);
  const dispatch = useDispatch();
  const [bank1Name, setBank1Name] = useState("");
  const [bin1Code, setBin1Code] = useState<number>(0);
  const [bank2Name, setBank2Name] = useState("");
  const [bin2Code, setBin2Code] = useState<number>(0);
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  return (
    <div>
      {/* <Modal
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: "10px",
          minWidth: 400,
          border: 0,
        }}
        disableAutoFocus={true}
        open={payment.jointPopup}
        onClose={() => dispatch(SET_JOINT_POPUP(false))}
      > */}
      <div
        className={`bg-[${
          !darkMode
            ? pos_config?.popup_color
            : pos_config?.popup_color_darkmode
        }] p-[20px] rounded-[12px] flex-col items-center justify-between border-none w-[90%] max-w-[450px] mx-auto select-none max-h-[45vh] md:max-h-[30vh] overflow-y-auto`}
      >
        <div
          className={`text-[${
            !darkMode
              ? pos_config?.popup_text_color
              : pos_config?.popup_text_color_darkmode
          }] text-[20px] md:text-[24px] font-[700] text-center`}
        >
          <div className="flex items-center justify-between text-[14px] md:text-[15px]">
            {/* <div>Cash Amount</div> */}
            <TextField
              InputLabelProps={{
                sx: darkMode
                  ? { color: `${pos_config?.popup_text_color_darkmode}` }
                  : { color: `${pos_config?.popup_text_color}` },
              }}
              InputProps={{
                classes: { root: classes.inputRoot },
                sx: darkMode
                  ? { color: `${pos_config?.popup_text_color_darkmode}` }
                  : { color: `${pos_config?.popup_text_color}` },
              }}
              type="number"
              fullWidth
              label="Cash Amount"
            />
          </div>
          <div className="border-t-2 border-slate-300 border-dashed pt-[20px] md:pt-[25px] my-[20px] md:my-[25px]">
            <div className="mb-[8px] md:mb-[10px] flex text-[14px] md:text-[15px]">
              {/* <div>Card Amount</div> */}
              <TextField
                type="number"
                InputLabelProps={{
                  sx: darkMode
                    ? { color: `${pos_config?.popup_text_color_darkmode}` }
                    : { color: `${pos_config?.popup_text_color}` },
                }}
                InputProps={{
                  classes: { root: classes.inputRoot },
                  sx: darkMode
                    ? { color: `${pos_config?.popup_text_color_darkmode}` }
                    : { color: `${pos_config?.popup_text_color}` },
                }}
                fullWidth
                label="Card Amount"
              />
            </div>
            <div className="flex flex-col md:flex-row items-center my-auto justify-between">
              <div className="w-full md:w-[45%] h-full mb-[10px] md:mb-0">
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={
                      darkMode
                        ? {
                          color: `${pos_config?.popup_text_color_darkmode}`,
                        }
                        : { color: `${pos_config?.popup_text_color}` }
                    }
                  >
                    Bank Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={bank1Name}
                    sx={
                      darkMode
                        ? {
                          color: `${pos_config?.popup_text_color_darkmode}`,
                        }
                        : { color: `${pos_config?.popup_text_color}` }
                    }
                    label="Bank Name"
                    onChange={(e) => {
                      setBank1Name(e.target.value);
                    }}
                  >
                    {payment.bankOptions.map((item, i) => (
                      <MenuItem key={i} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="flex w-[45%] h-full">
                <TextField
                  InputLabelProps={{
                    sx: darkMode
                      ? { color: `${pos_config?.popup_text_color_darkmode}` }
                      : { color: `${pos_config?.popup_text_color}` },
                  }}
                  InputProps={{
                    classes: { root: classes.inputRoot },
                    sx: darkMode
                      ? { color: `${pos_config?.popup_text_color_darkmode}` }
                      : { color: `${pos_config?.popup_text_color}` },
                  }}
                  type="number"
                  fullWidth
                  label="Bin Code"
                  onChange={(e) => {
                    setBin1Code(parseInt(e.target.value));
                  }}
                />
              </div>
            </div>
          </div>
          <div className="border-t-2 border-slate-300 border-dashed pt-[20px] md:pt-[25px] my-[20px] md:my-[25px]">
            <div className="mb-[8px] md:mb-[10px] flex text-[14px] md:text-[15px]">
              {/* <div>Card Amount</div> */}
              <TextField
                type="number"
                InputLabelProps={{
                  sx: darkMode
                    ? { color: `${pos_config?.popup_text_color_darkmode}` }
                    : { color: `${pos_config?.popup_text_color}` },
                }}
                InputProps={{
                  classes: { root: classes.inputRoot },
                  sx: darkMode
                    ? { color: `${pos_config?.popup_text_color_darkmode}` }
                    : { color: `${pos_config?.popup_text_color}` },
                }}
                fullWidth
                label="Card Amount"
              />
            </div>
            <div className="flex flex-col md:flex-row items-center my-auto justify-between">
              <div className="w-full md:w-[45%] h-full mb-[10px] md:mb-0">
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={
                      darkMode
                        ? {
                          color: `${pos_config?.popup_text_color_darkmode}`,
                        }
                        : { color: `${pos_config?.popup_text_color}` }
                    }
                  >
                    Bank Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={bank2Name}
                    sx={
                      darkMode
                        ? {
                          color: `${pos_config?.popup_text_color_darkmode}`,
                        }
                        : { color: `${pos_config?.popup_text_color}` }
                    }
                    label="Bank Name"
                    onChange={(e) => {
                      setBank2Name(e.target.value);
                    }}
                  >
                    {payment.bankOptions.map((item, i) => (
                      <MenuItem key={i} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="flex w-full md:w-[45%] h-full">
                <TextField
                  type="number"
                  InputLabelProps={{
                    sx: darkMode
                      ? { color: `${pos_config?.popup_text_color_darkmode}` }
                      : { color: `${pos_config?.popup_text_color}` },
                  }}
                  InputProps={{
                    classes: { root: classes.inputRoot },
                    sx: darkMode
                      ? { color: `${pos_config?.popup_text_color_darkmode}` }
                      : { color: `${pos_config?.popup_text_color}` },
                  }}
                  fullWidth
                  label="Bin Code"
                  onChange={(e) => {
                    setBin2Code(parseInt(e.target.value));
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-around mt-[20px]">
            <div
              className="cursor-pointer w-full md:w-[165px] h-[46px] rounded-[62px] flex items-center justify-center bg-[#EF233C] text-[#FFFFFF] text-[16px] font-[700] mt-[10px] md:mt-0"
              onClick={() => {
                dispatch(
                  SET_CARD2_DETAILS({
                    bankName: bank1Name,
                    binCode: bin1Code,
                  })
                );
                dispatch(SET_JOINT_POPUP(false));
              }}
            >
              OK
            </div>
          </div>
        </div>
      </div>
      {/* </Modal> */}
    </div>
  );
};

export default JointPopup;
