import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import {
  SET_BATCH_NUMBER,
  SET_ITEMID,
  SET_SENDREQUEST,
} from "../../Features/rightBarHeader";
import { AiFillCamera } from "react-icons/ai";
import QRScanner from "./QRScanner";
import { SET_SEARCH_FIELD_TEXT } from "../../Features/leftBarHeader";
import {
  ADD_MENU_ITEM_TO_CURRENT_ORDER,
  handleOpen,
  INITIALIZE_MENU_ITEM,
  SET_ISEDITING_ADDON,
} from "../../Features/cart";
import {
  SET_COMINGFROM,
  SET_ITEMS_STATES_DATA,
  SET_ITERATOR2,
  SET_TO_INITIAL_ITEMS_STATE,
} from "../../Features/ItemsStates";

const useKeyPress = function (targetKey: any) {
  const [keyPressed, setKeyPressed] = useState(false);

  // @ts-ignore
  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  //@ts-ignore
  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  });

  return keyPressed;
};

const SearchDiv = () => {
  const dispatch = useDispatch();

  const orderMenu = useSelector((state: RootState) => state.orderMenu.value);
  const [products, setProducts] = useState<Array<object> | null>(null);
  const [filterdArray, setFilteredArray] = useState<
    Array<object> | null | undefined
  >(null);
  const [toggle, setToggle] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const [index, setIndex] = useState(0);
  const [qrCode, setQrCode] = useState(false);
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const cart = useSelector((state: RootState) => state.cart);
  const leftBarHeader = useSelector((state: RootState) => state.leftHeader);

  useEffect(() => {
    if (value?.length && filterdArray && filterdArray.length > 0 && downPress) {
      setIndex((prevState) =>
        prevState < filterdArray.length - 1 ? prevState + 1 : prevState
      );
    }
  }, [downPress]);

  useEffect(() => {
    if (value?.length && filterdArray && filterdArray.length > 0 && upPress) {
      setIndex((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
  }, [upPress]);

  const changeFunction = (e: any) => {
    setValue(e.target.value);
    const temp = e.target.value.toString();
    setIndex(0);

    const filteredProducts = products?.flatMap((val: any) => {
      if (
        val.Name.toUpperCase().includes(e.target.value.toUpperCase()) ||
        val.ID.includes(
          e.target.value.substr(
            pos_config.batch_number_length,
            pos_config.product_ref_code_length
          )
        )
      ) {
        if (val.MenuSizesList && val.MenuSizesList.length > 0) {
          return val.MenuSizesList.map((size: any) => ({
            ...val,
            displayName: `${val.Name}${
              size.Size ? (size.Size === "-" ? "" : `- ${size.Size}`) : ""
            }`,
            DiscountedPrice: size.DiscountedPrice,
            sizeID: size.ID,
          }));
        }
        return [{ ...val, displayName: val.Name }];
      }
      return [];
    });

    setFilteredArray(filteredProducts);

    setToggle(e.target.value.length > 0);
  };

  const handleKeyDown = async (e: any) => {
    if (e.key === " ") {
      e.preventDefault();
      setValue((prevValue) => prevValue + " ");
      return;
    }
    if (e.key === "Enter") {
      if (filterdArray && filterdArray.length > 0 && value?.length > 0) {
        const selectedItem = filterdArray[index];
        handleItemClick(selectedItem);
        setValue("");
      }
    }
  };

  // const handleItemClick = (item: any) => {
  //   if (
  //     !(
  //       cart.selectedOrderStatus === "DISPATCHED" ||
  //       cart.selectedOrderStatus === "SUSPENDED" ||
  //       cart.selectedOrderStatus === "RETURNED"
  //     )
  //   ) {
  //     dispatch(SET_COMINGFROM("Click"));
  //     dispatch(SET_TO_INITIAL_ITEMS_STATE());
  //     dispatch(SET_ITERATOR2(index));

  //     if (item.MenuSizesList === null) {
  //       dispatch(
  //         INITIALIZE_MENU_ITEM({
  //           itemName: item.Name,
  //           price: item.DiscountedPrice,
  //           category: "Category",
  //           quantity: 1,
  //           MinimumDelivery: item.MinimumDelivery || 0,
  //           sizeID: 0,
  //           itemID: item.ID,
  //           _id: item._id,
  //           objArray: [],
  //           batchNumber: "",
  //         })
  //       );
  //       dispatch(ADD_MENU_ITEM_TO_CURRENT_ORDER());
  //     } else {
  //       const selectedSize =
  //         item.MenuSizesList.find((size: any) => size.ID === item.sizeID) ||
  //         item.MenuSizesList[0];
  //       dispatch(
  //         SET_ITEMS_STATES_DATA({
  //           name: item.Name,
  //           quantity: 1,
  //           data: item,
  //           size: item.MenuSizesList.length,
  //           flavor: selectedSize.FlavourAndToppingsList,
  //           itemPrice: selectedSize.DiscountedPrice,
  //           itemID: item.ID,
  //           MinimumDelivery: item.MinimumDelivery,
  //           sizeID: selectedSize.ID,
  //           category: "Category",
  //         })
  //       );
  //       dispatch(handleOpen(true));
  //     }
  //   }
  // };

  const handleItemClick = (item: any) => {
    if (
      !(
        cart.selectedOrderStatus === "DISPATCHED" ||
        cart.selectedOrderStatus === "SUSPENDED" ||
        cart.selectedOrderStatus === "RETURNED"
      )
    ) {
      dispatch(SET_COMINGFROM("Click"));
      dispatch(SET_TO_INITIAL_ITEMS_STATE());
      dispatch(SET_ITERATOR2(index));
      dispatch(SET_ISEDITING_ADDON(false));
  
      const selectedSize = item.MenuSizesList 
        ? (item.MenuSizesList.find((size: any) => size.ID === item.sizeID) || item.MenuSizesList[0])
        : null;
  
      const categoryName = orderMenu.find((category: any) => 
        category.MenuItemsList.some((menuItem: any) => menuItem.ID === item.ID)
      )?.Name || "Category";
  
      dispatch(
        SET_ITEMS_STATES_DATA({
          name: item.Name,
          quantity: 1,
          data: item,
          size: item.MenuSizesList ? item.MenuSizesList.length : 0,
          flavor: selectedSize ? selectedSize.FlavourAndToppingsList : [],
          itemPrice: selectedSize ? selectedSize.DiscountedPrice : item.DiscountedPrice,
          itemID: item.ID,
          MinimumDelivery: item.MinimumDelivery || 0,
          sizeID: selectedSize ? selectedSize.ID : 0,
          category: categoryName,
        })
      );
  
      dispatch(handleOpen(true));
  
      if (!item.MenuSizesList || item.MenuSizesList.length === 0) {
        dispatch(
          INITIALIZE_MENU_ITEM({
            itemName: item.Name,
            price: item.DiscountedPrice,
            category: categoryName,
            quantity: 1,
            MinimumDelivery: item.MinimumDelivery || 0,
            sizeID: 0,
            itemID: item.ID,
            _id: item._id,
            objArray: [],
            batchNumber: "",
          })
        );
        dispatch(ADD_MENU_ITEM_TO_CURRENT_ORDER());
      }
    }
  };
  

  useEffect(() => {
    let arr: any[] = [];
    orderMenu.map((val: any) => {
      val.MenuItemsList.map((innerVal: any) => {
        arr.push(innerVal);
      });
    });
    setProducts(arr);
  }, [orderMenu]);

  return (
    <>
      <div
        //onClick={() => {}}
        className={`flex border-[1px] items-center rounded-[60px] 
        h-[35px] pl-[15px]  mx-[20px] mb-[10px]
        lg:h-[40px] lg:pl-[20px] lg:pr-[20px] lg:mx-[20px] lg:mb-[13px]
        xl:h-[50px] xl:pl-[30px] xl:pr-[20px] xl:mx-[20px] xl:mb-[16px]
        ${
          darkMode
            ? "text-white border-[#595d69]"
            : "text-black border-[#E8E8E8]"
        }
        `}
      >
        <BsSearch />
        <input
          onBlur={() => setValue("")}
          value={value}
          onKeyDown={handleKeyDown}
          onChange={(e) => changeFunction(e)}
          className={`${
            darkMode ? "bg-[#111827] text-[white]" : "bg-[white] text-[#111827]"
          } focus:outline-none ml-[20px]  w-[100%] h-[100%]`}
          type="text"
          placeholder="Search Product"
        />
        <div
          className="lg:hidden px-[2px] cursor-pointer"
          onClick={() => setQrCode(true)}
        >
          <AiFillCamera />
        </div>
      </div>
      {toggle &&
        filterdArray &&
        filterdArray.length > 0 &&
        value.length > 0 && (
          <ul
            autoFocus={true}
            className={`absolute bg-white max-h-[201px] w-[350px] overflow-y-scroll mt-[-15px] border ${
              leftBarHeader.isLeftBarOnRight === "right"
                ? "right-[40px]"
                : "left-[40px]"
            }`}
          >
            {filterdArray.map((val: any, key: any) => (
              <li
                id={`list-${key}`}
                className={`${
                  index === key
                    ? darkMode
                      ? "bg-slate-700"
                      : "bg-slate-100"
                    : "border-[#E8E8E8]"
                } ${
                  index === key &&
                  document.getElementById(`list-${key}`)?.scrollIntoView({
                    block: "nearest",
                    inline: "nearest",
                  })
                } h-[50px] flex items-center px-[16px] border-b ${
                  darkMode
                    ? "bg-[#111827] text-[white]"
                    : "bg-[white] text-[#111827]"
                }`}
                key={key}
              >
                {val.displayName}
              </li>
            ))}
          </ul>
        )}
      {qrCode && <QRScanner setQrCode={setQrCode} />}
    </>
  );
};

export default SearchDiv;
