import React, { useState } from "react";
import PromoBar from "./PromoBar";
import PaymentOptions from "./PaymentOptions";
import CheckoutBar from "./Checkout";
import { useSelector } from "react-redux";
import { RootState } from "../..";
import TakeAwayCheckout from "./TakeAwayCheckout";
import BarcodePrintPagePopUp from "./BarcodePrintPagePopUp";

const Footer = () => {
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  return (
    <div
      className={`${
        darkMode ? "bg-[#111827] border-[#2b2c37]" : "bg-white "
      } h-fit w-full flex border-t-[1px] items-center justify-center px-2 `}
    >
      <CheckoutBar />
      <BarcodePrintPagePopUp />
    </div>
  );
};

export default Footer;
