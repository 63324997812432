import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useIsFirstRender } from "usehooks-ts";
import { RootState } from "..";
import PriceCheckerSearchDiv from "../Components/RightBar/PriceChecker/PriceCheckerSearchDiv";
import PriceCheckerBg from "../assets/RightBar/price-checker.png";
import PriceCheckerLogo from "../assets/RightBar/AlNaseebLogo.png";

const PriceCheckerPage = () => {
  const priceCheckerItem = useSelector(
    (state: RootState) => state.header.priceCheckerItem
  );
  const priceCheckerTrigger = useSelector(
    (state: RootState) => state.header.priceCheckerTrigger
  );

  return (
    <div className="flex items-start justify-center flex-col w-full">
      <img
        className="absolute w-full h-full max-w-full max-h-full object-cover opacity-50 top-0"
        src={PriceCheckerBg}
        alt=""
      />

      <img
        className="z-10 flex items-center justify-center absolute top-[100px] right-1/2 translate-x-1/2 w-[560px] h-[200px]"
        src={PriceCheckerLogo}
        alt=""
      />

      <div className="absolute z-10 right-0 top-[60px] w-[100px] h-[10px] opacity-5">
        <PriceCheckerSearchDiv />
      </div>

      <div className="flex items-start justify-start w-full flex-col z-10 gap-1 mt-[280px]">
        <div className="w-full flex items-center justify-between h-[144px] gap-1">
          <div className="flex items-center justify-start w-[32%] flex-col h-[144px]">
            <div className="text-[32px] font-bold text-red-600 h-[54px] w-full flex items-center justify-center bg-white drop-shadow-lg">
              PRICE
            </div>
            <div className="text-[64px] font-bold text-[#074497] whitespace-nowrap h-[90px] w-full flex items-center justify-center bg-white opacity-80">
              {priceCheckerItem.DiscountedPrice}
            </div>
          </div>
          <div className="flex items-center justify-start w-[36%] flex-col h-[144px]">
            <div className="text-[32px] font-bold text-green-500 h-[54px] w-full flex items-center justify-center bg-white drop-shadow-lg">
              DISCOUNT
            </div>
            <div className="text-[64px] font-bold text-[#074497] whitespace-nowrap h-[90px] w-full flex items-center justify-center bg-white opacity-80">
              {priceCheckerItem.Discount}
            </div>
          </div>
          <div className="flex items-center justify-start w-[32%] flex-col h-[144px]">
            <div className="text-[32px] font-bold text-orange-400 h-[54px] w-full flex items-center justify-center bg-white drop-shadow-lg">
              LAST PRICE
            </div>
            <div className="text-[64px] font-bold text-[#074497] whitespace-nowrap h-[90px] w-full flex items-center justify-center bg-white opacity-80">
              {priceCheckerItem.Cost}
            </div>
          </div>
        </div>

        <div className="w-full flex items-center justify-between h-[200px] gap-1">
          <div className="flex items-center justify-start w-[60%] flex-col h-[200px]">
            <div className="text-[24px] font-bold text-[#074497] h-[54px] w-full flex items-center justify-center bg-white  drop-shadow-xl shadow-2xl">
              DESCRIPTION
            </div>
            <div className="text-[64px] font-semibold text-orange-400 whitespace-nowrap h-full w-full flex items-center justify-center bg-white opacity-80">
              {priceCheckerItem.Name}
            </div>
          </div>
          <div className="flex items-center justify-start w-[40%] flex-col h-[200px]">
            <div className="text-[24px] font-bold text-[#074497] h-[54px] w-full flex items-center justify-center bg-white drop-shadow-xl shadow-2xl">
              STOCK AVAILABLE
            </div>
            <div className="text-[64px] font-bold text-[#074497] whitespace-nowrap h-full w-full flex items-center justify-center bg-white opacity-80">
              {priceCheckerItem.StockNumber}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceCheckerPage;
