import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { IoMdArrowDropdown } from "react-icons/io";
import { useSelector } from "react-redux";
import { RootState } from "../../..";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  select: {
    borderRadius: "100px",
    height: "50px",
  },
});

interface SelectSectionProps {
  onSectionChange: (section: string | null) => void;
}

const SelectSection: React.FC<SelectSectionProps> = ({ onSectionChange }) => {
  const classes = useStyles();
  const [section, setSection] = useState<string | null>("All");
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const handleChange = (event: SelectChangeEvent) => {
    const newSection =
      event.target.value === "All" ? null : (event.target.value as string);
    setSection(newSection);
    onSectionChange(newSection);
  };

  return (
    <div
      className={`flex justify-end items-center py-[10px] ${
        darkMode ? "text-white" : "text-black"
      }`}
    >
      <div className="">
        <FormControl
          fullWidth
          sx={{
            m: 1,
            minWidth: "150px",
          }}
        >
          <InputLabel sx={{}}>Select Section</InputLabel>
          <Select
            className={classes.select}
            value={section || "All"}
            label="Select Section"
            onChange={handleChange}
            inputProps={{ "aria-label": "Without label" }}
            sx={
              darkMode
                ? {
                    color: "#FFFFFF",
                  }
                : { color: "#000000" }
            }
            IconComponent={() => {
              return (
                <IoMdArrowDropdown
                  className="h-[20px] w-[30px] mr-[10px]"
                  style={darkMode ? { color: "#FFFFFF" } : { color: "#000000" }}
                />
              );
            }}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="1st Floor">1st Floor</MenuItem>
            <MenuItem value="2nd Floor">2nd Floor</MenuItem>
            <MenuItem value="3rd Floor">3rd Floor</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default SelectSection;
