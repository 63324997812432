import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import LeftBar from "../LeftBar/LeftBar";
import RightBar from "../RightBar/RightBar";
import {
  SET_DARK_MODE,
  SET_DRAWER,
  SET_LEFT_BAR_POSITION,
  SET_SELECTED_LEFT_HEADER,
} from "../../Features/leftBarHeader";
import { Drawer } from "@mui/material";

const Body = () => {
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const leftBarHeader = useSelector((state: RootState) => state.leftHeader);
  const dispatch = useDispatch();
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const selectedHeader = useSelector(
    (state: RootState) => state.header.selectedHeader
  );

  const shouldHideLeftBar =
    selectedHeader === "Dine-in" ||
    selectedHeader === "Orders" ||
    selectedHeader === "Delivery";

  useEffect(() => {
    if (pos_config?.is_restaurant === "yes") {
      dispatch(SET_SELECTED_LEFT_HEADER("Dine-in"));
    } else {
      dispatch(SET_SELECTED_LEFT_HEADER("Pickup"));
    }
  }, []);

  useEffect(() => {
    if (pos_config && pos_config.cartposition) {
      if (pos_config.cartposition) {
        dispatch(SET_LEFT_BAR_POSITION(pos_config.cartposition));
      }
    }
  }, [pos_config, dispatch]);

  return (
    <div
      className={`flex w-full h-full ${darkMode ? "bg-[#111827]" : "bg-white"}`}
    >
      {leftBarHeader.isLeftBarOnRight === "left" && (
        <div
          className={`h-full border-[#2b2c37] border-r ml-[10px] transition-all duration-300 ${
            shouldHideLeftBar
              ? "w-0 overflow-hidden"
              : "min-w-[300px] lg:min-w-[350px] xl:min-w-[400px]"
          }  hidden lg:flex   `}
        >
          <LeftBar />
        </div>
      )}

      <Drawer
        PaperProps={{
          sx: {
            backgroundColor: `${darkMode ? "#111827" : "#fff"}`,
            padding: "1rem",
            transition: "width 0.3s ease",
          },
        }}
        open={leftBarHeader.drawer}
        onClose={() => {
          dispatch(SET_DRAWER(false));
        }}
        anchor={leftBarHeader.isLeftBarOnRight === "right" ? "right" : "left"}
      >
        <div
          className={`${darkMode ? "bg-[#111827]" : "bg-white"} h-full flex`}
        >
          <LeftBar />
        </div>
      </Drawer>

      <div className="w-full h-[100%]">
        <RightBar />
      </div>

      {leftBarHeader.isLeftBarOnRight === "right" && (
        <div
          className={`order-last h-full border-[#2b2c37] border-r ml-[10px] transition-all duration-300 ${
            shouldHideLeftBar
              ? "w-0 overflow-hidden"
              : "min-w-[300px] lg:min-w-[350px] xl:min-w-[400px]"
          } hidden lg:flex`}
        >
          <LeftBar />
        </div>
      )}
    </div>
  );
};

export default Body;
