import React from "react";
import DealsList from "./DealsList";
import { useSelector } from "react-redux";
import IDealsList from "../../Interfaces/IDealsList";
import CartPriceDetails from "./CartPriceDetails";

//Shows all the selected items
const DealsBar = () => {
  const cart = useSelector((state: any) => state.cart);
  console.log("Cart", cart.order);
  return (
    <div className="flex flex-col items-center justify-between w-full scrollbar-hide overflow-y-scroll">
      <div className="w-full">
        <div className="w-full flex flex-col-reverse gap-4 mt-2">
          {cart.order?.map((item: IDealsList, i: number) => (
            <DealsList
              item={{
                batchNumber: item.batchNumber,
                categoryName: item.categoryName,
                itemID: item.itemID,
                total: item.total,
                itemPrice: item.itemPrice,
                itemName: item.itemName,
                quantity: item.quantity,
                addOns: item.addOns,
                sizeID: item.sizeID,
                orderTotal: item.orderTotal,
                _id: item._id,
                isComingFromAPI: item.isComingFromAPI,
              }}
              index={i}
              key={i}
            />
          ))}
        </div>
        <div>
          <CartPriceDetails />
        </div>
      </div>
    </div>
  );
};

export default DealsBar;
