import {
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import {
  SET_CARD1_DETAILS,
  SET_CARD_POPUP,
  SET_IS_CARD_PAYMENT,
  SET_ONLINE_BANK_PAYMENT,
} from "../../Features/cart";
import { makeStyles } from "@mui/styles";
import { useIsFirstRender } from "usehooks-ts";

const useStyles = makeStyles({
  inputRoot: {
    "& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button":
      {
        "-webkit-appearance": "none",
        margin: 0,
      },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
});

const CardPopup = () => {
  const payment = useSelector((state: RootState) => state.cart.payment);
  const dispatch = useDispatch();
  const [bankName, setBankName] = useState("Online Bank Payment");
  const [binCode, setBinCode] = useState<number>(0);
  //@ts-ignore
  const classes = useStyles();
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );

  return (
    <div>
      {/* <Modal
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          minWidth: 400,
          border: 0,
        }}
        disableAutoFocus={true}
        open={payment.cardPopup}
        onClose={() => dispatch(SET_CARD_POPUP(false))}
      > */}
        <div
          className={`bg-[${
            !darkMode
              ? pos_config?.popup_color
              : pos_config?.popup_color_darkmode
          }] p-4 sm:p-6 md:p-8 rounded-lg shadow-md flex-col items-center justify-between border-none w-[90vw] sm:w-[80vw] md:w-[450px] mx-auto select-none overflow-y-auto max-h-[30vh]`}
        >
          <div
            className={`text-[${
              !darkMode
                ? pos_config?.popup_text_color
                : pos_config?.popup_text_color_darkmode
            }] text-lg sm:text-xl md:text-[24px] font-[700] text-center`}
          >
            <div className="mb-4">Card Details</div>
            <div>
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={
                    darkMode
                      ? { color: `${pos_config?.popup_text_color_darkmode}` }
                      : { color: `${pos_config?.popup_text_color}` }
                  }
                >
                  Bank Name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={bankName}
                  label="Bank Name"
                  sx={
                    darkMode
                      ? { color: `${pos_config?.popup_text_color_darkmode}` }
                      : { color: `${pos_config?.popup_text_color}` }
                  }
                  onChange={(e) => {
                    setBankName(e.target.value);
                  }}
                >
                  {payment.bankOptions.map((item, i) => (
                    <MenuItem key={i} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div
              className={`flex mt-[20px] text-[${
                darkMode ? "#FFFFFF" : "#000000"
              }]`}
            >
              <TextField
                fullWidth
                label="Bin Code"
                type="number"
                InputLabelProps={{
                  sx: darkMode
                    ? { color: `${pos_config?.popup_text_color_darkmode}` }
                    : { color: `${pos_config?.popup_text_color}` },
                }}
                InputProps={{
                  classes: { root: classes.inputRoot },
                  sx: darkMode
                    ? { color: `${pos_config?.popup_text_color_darkmode}` }
                    : { color: `${pos_config?.popup_text_color}` },
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    dispatch(SET_CARD1_DETAILS({ bankName, binCode }));
                    dispatch(SET_CARD_POPUP(false));
                  }
                }}
                onChange={(e) => {
                  setBinCode(parseInt(e.target.value));
                }}
              />
            </div>

            <div className="flex flex-row justify-around pt-[20px]">
              <div
                className={`cursor-pointer w-full sm:w-[165px] h-[46px] rounded-[62px] flex items-center justify-center bg-[${
                  !darkMode
                    ? pos_config?.popup_secondary_button_color
                    : pos_config?.popup_secondary_button_color_darkmode
                }] text-[${
                  !darkMode
                    ? pos_config?.popup_secondary_button_text_color
                    : pos_config?.popup_secondary_button_text_color_darkmode
                }] text-[14px] sm:text-[16px] font-[700]`}
                onClick={() => {
                  if (bankName === "Online Bank Payment") {
                    dispatch(SET_ONLINE_BANK_PAYMENT(true));
                    dispatch(SET_IS_CARD_PAYMENT(false));
                    console.log("I ran");
                  } else {
                    dispatch(SET_ONLINE_BANK_PAYMENT(false));
                    dispatch(SET_IS_CARD_PAYMENT(true));
                  }

                  dispatch(SET_CARD1_DETAILS({ bankName, binCode }));
                  dispatch(SET_CARD_POPUP(false));
                }}
              >
                OK
              </div>
            </div>
          </div>
        </div>
      {/* </Modal> */}
    </div>
  );
};

export default CardPopup;
