import React, { useState } from "react";
import SelectSection from "./SelectSection";
import TablesList from "./TablesList";

const TableaArea = () => {
  const [selectedSection, setSelectedSection] = useState<string|null>(null);
  const handleSectionChange = (section: string|null) => {
    setSelectedSection(section);
  };
  return (
    <div className="mt-[5px] px-[20px]">
      <SelectSection onSectionChange={handleSectionChange} />
      <TablesList section={selectedSection} />
    </div>
  );
};

export default TableaArea;
