import React, { useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { MdDeleteForever } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import {
  DEFAULT_ITEM_PROPERTIES,
  INITIALIZE_ORDER_DATA,
  SET_CURRENT_TABLE,
  SET_SELECTED_ORDER,
  SET_SHOW_CUSTOMER_POPUP,
} from "../../Features/cart";
import { SET_SELECTED_LEFT_HEADER } from "../../Features/leftBarHeader";
import {
  SET_SELECTEDHEADER,
  SET_VISUAL_HEADER,
} from "../../Features/rightBarHeader";
import SelectSection from "./TableArea/SelectSection";
import { INITIALIZE_PRODUCT_CONSUMPTION_DETAIL } from "../../Features/OrderConsumption";
import { SET_CUSTOMER_DATA } from "../../Features/customer";

const RightBarHeader = () => {
  const header = useSelector((state: RootState) => state.header);
  const leftBarHeader = useSelector((state: RootState) => state.leftHeader);
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const headerOptions = ["Dine-in", "Pickup", "Delivery", "Orders"];
  const dispatch = useDispatch();
  const visualHeader = useSelector(
    (state: RootState) => state.header.visualHeader
  );

  return (
    <div className="flex flex-row justify-between items-center p-2 gap-2 sm:p-4">
      <div className="overflow-x-clip">
        <div className="flex gap-2 sm:gap-4 min-w-max">
          {headerOptions.map((name, i) => (
            <div
              onClick={() => {
                dispatch(SET_SELECTEDHEADER(name));
                dispatch(SET_SELECTED_LEFT_HEADER(name));
                dispatch(SET_VISUAL_HEADER(name));
                dispatch(INITIALIZE_ORDER_DATA());
                dispatch(SET_SELECTED_ORDER(null));
                dispatch(INITIALIZE_PRODUCT_CONSUMPTION_DETAIL());
                dispatch(DEFAULT_ITEM_PROPERTIES());
                if (name === "Delivery") {
                  dispatch(SET_CUSTOMER_DATA(undefined));
                  if (document.location.search.length > 2)
                    document.location.search = "";
                }
                if (name === "Pickup") {
                  dispatch(SET_CUSTOMER_DATA(undefined));
                  dispatch(SET_SHOW_CUSTOMER_POPUP(true));
                }
              }}
              key={i}
              className={`${
                visualHeader === name
                  ? !darkMode
                    ? `bg-[${pos_config?.primary_button_hover_color}] text-[${pos_config?.primary_button_hover_text_color}]`
                    : `bg-[${pos_config?.primary_button_hover_color_darkmode}] text-[${pos_config?.primary_button_hover_text_color_darkmode}]`
                  : !darkMode
                  ? `bg-[${pos_config?.primary_button_color}] text-[${pos_config?.primary_button_text_color}]`
                  : `bg-[${pos_config?.primary_button_color_darkmode}] text-[${pos_config?.primary_button_text_color_darkmode}]`
              } 
          ${
            darkMode
              ? `hover:bg-[${pos_config.primary_button_hover_color_darkmode}] hover:text-[${pos_config?.primary_button_hover_text_color_darkmode}] border-[#2b2c37]`
              : `hover:bg-[${pos_config.primary_button_hover_color}] hover:text-[${pos_config?.primary_button_hover_text_color}] border-[#E8E8E8]`
          }
          cursor-pointer border-[1px] font-[500] flex items-center justify-center text-xs sm:text-sm md:text-base
          px-2 sm:px-3 md:px-4 py-1 sm:py-2 rounded-full h-10
          `}
            >
              {name}
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center sm:justify-end mt-2 sm:mt-0">
        <div
          onClick={() => {
            if (
              !leftBarHeader.isDineIn &&
              leftBarHeader.selectedHeader === "Dine-in"
            ) {
              dispatch(SET_CURRENT_TABLE(0));
            }

            dispatch(INITIALIZE_ORDER_DATA());
            dispatch(SET_SELECTED_ORDER(null));
            dispatch(INITIALIZE_PRODUCT_CONSUMPTION_DETAIL());
            dispatch(DEFAULT_ITEM_PROPERTIES());
          }}
          className={`flex items-center justify-center h-10 sm:h-12 rounded-full border px-3 sm:px-4 border-green-600 bg-green-600 cursor-pointer text-white gap-2 hover:scale-105 transition duration-200 ease-in-out`}
        >
          <div className="font-bold text-xs sm:text-sm whitespace-nowrap">
            New Order
          </div>
          <AiOutlinePlusCircle className="w-5 h-5 sm:w-6 sm:h-6" />
        </div>
      </div>
    </div>
  );
};

export default RightBarHeader;
