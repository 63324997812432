import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface orderMenuType {
  value: Array<{
    Name: string;
    notForSale: boolean;
    MenuItemsList: Array<{
      _id: string;
      ID: number;
      Name: string;
      Discount: number;
      DiscountedPrice: number;
    }>;
  }>;
}

const initialState: orderMenuType = {
  value: [
    {
      Name: "",
      notForSale: false,
      MenuItemsList: [
        {
          _id: "",
          ID: 0,
          Name: "",
          Discount: 0,
          DiscountedPrice: 0,
        },
      ],
    },
  ],
};

const orderMenuSlice = createSlice({
  name: "orderMenu",
  initialState,
  reducers: {
    createMenu: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { createMenu } = orderMenuSlice.actions;
export default orderMenuSlice.reducer;
