import { Modal } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@mui/styles";
import { RootState } from "..";
import { SET_PORT, SET_SETTINGS_POPUP } from "../Features/rightBarHeader";

const useStyles = makeStyles({
  inputRoot: {
    "& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button":
      {
        "-webkit-appearance": "none",
        margin: 0,
      },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
});

const SettingsPopupPage = () => {
  //@ts-ignore
  const classes = useStyles();
  const rightBarHeader = useSelector((state: RootState) => state.header);
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const dispatch = useDispatch();
  const [port, setPort] = useState(null);

  const connect = async () => {
    try {
      const port = await (navigator as any).serial.requestPort();
      await port.open({ baudRate: 9600 });
      dispatch(SET_PORT(port));
      //dispatch(SET_READER(port.readable.getReader()));
    } catch (error) {
      console.error("Error connecting to serial port:", error);
    }
  };

  return (
    <div>
      <Modal
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          minWidth: 300,
          border: 0,
        }}
        disableAutoFocus={true}
        open={rightBarHeader.settingsPopup}
        onClose={() => {
          dispatch(SET_SETTINGS_POPUP(false));
        }}
      >
        <div
          className={`bg-[${
            !darkMode
              ? pos_config?.popup_color
              : pos_config?.popup_color_darkmode
          }] p-[32px] mt-[100px] rounded-[12px] flex-col items-center justify-center border-none w-[450px] mx-auto select-none`}
        >
          <div className="text-[#000000] text-[24px] font-[700] text-center flex items-center justify-center">
            <div
              onClick={() => {
                connect();
              }}
              className={`cursor-pointer w-[165px] h-[46px] rounded-[62px] flex items-center justify-center bg-[${
                !darkMode
                  ? pos_config?.popup_primary_button_color
                  : pos_config?.popup_primary_button_color_darkmode
              }] text-[${
                !darkMode
                  ? pos_config?.popup_primary_button_text_color
                  : pos_config?.popup_primary_button_text_color_darkmode
              }] text-[16px] font-[700]`}
            >
              Connect to ECR
            </div>
          </div>
          <div className="flex flex-row justify-around pt-[20px]">
            <div
              onClick={() => {
                dispatch(SET_SETTINGS_POPUP(false));
              }}
              className={`cursor-pointer w-[165px] h-[46px] rounded-[62px] flex items-center justify-center bg-[${
                !darkMode
                  ? pos_config?.popup_secondary_button_color
                  : pos_config?.popup_secondary_button_color_darkmode
              }] text-[${
                !darkMode
                  ? pos_config?.popup_secondary_button_text_color
                  : pos_config?.popup_secondary_button_text_color_darkmode
              }] text-[16px] font-[700]`}
            >
              Cancel
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SettingsPopupPage;
