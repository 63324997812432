import { useSelect } from "@mui/base";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "..";
import { Modal } from "@mui/material";
import { BaseService } from "../assets/BaseService";
import { SET_ROUTE, SET_SELECTED_BOOKING_ID } from "../Features/rightBarHeader";

const ReservationPage = () => {
  const openingTime: number = 11;
  const closingTime: number = 23;
  let timeSlot: Array<number> = Array.from(
    { length: closingTime - openingTime + 1 },
    (_, i) => i
  );
  const token = localStorage.getItem("access_token");
  const [sections, setSections] = useState<string[] | null>(null);
  const [tableData, setTableData] = useState(null);
  const [bookings, setBookings] = useState(null);
  const [offset3D, setOffset3D] = useState<number[][][] | null>(null);
  const [bookingPopup, setBookingPopup] = useState<boolean>(false);
  const [customerPhoneNo, setCustomerPhoneNo] = useState<string | undefined>(
    undefined
  );
  const [selectedBranch, setSelectedBranch] = useState<
    { value: string; label: string } | undefined
  >(undefined);
  const [selectedSection, setSelectedSection] = useState<
    { value: string; label: string } | undefined
  >(undefined);
  const [selectedTable, setSelectedTable] = useState<
    { value: string; label: string } | undefined
  >(undefined);
  const [startTime, setStartTime] = useState<string | undefined>(undefined);
  const [endTime, setEndTime] = useState<string | undefined>(undefined);
  const [apiTrigger, setApiTrigger] = useState<number>(0);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [clickedTime, setClickedTime] = useState<Date | null>(null);

  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const userId = useSelector(
    (state: RootState) => state.leftHeader.response_id
  );

  const dispatch = useDispatch();

  const DatePicker = ({ onDateChange }: any) => {
    const [isCustom, setIsCustom] = useState(false);

    const formatDate = (date: Date) => {
      return date.toLocaleDateString("en-US", {
        weekday: "short",
        month: "short",
        day: "numeric",
      });
    };

    const handleDateChange = (option: string) => {
      let newDate: Date;
      if (option === "today") {
        newDate = new Date();
      } else if (option === "tomorrow") {
        newDate = new Date();
        newDate.setDate(newDate.getDate() + 1);
      } else if (option === "custom") {
        setIsCustom(true);
        return;
      }

      //@ts-ignore
      setSelectedDate(newDate);
      setIsCustom(false);
      //@ts-ignore
      onDateChange(newDate);
    };

    return (
      <div className="relative">
        <div className="flex items-center space-x-2">
          <span className="text-sm font-medium">Selected Date:</span>
          {isCustom ? (
            <input
              type="date"
              className="p-2 border rounded bg-white"
              onChange={(e) => {
                const newDate = new Date(e.target.value);
                setSelectedDate(newDate);
                setIsCustom(false);
                onDateChange(newDate);
              }}
            />
          ) : (
            <select
              className="p-2 border rounded bg-white text-gray-800 cursor-pointer hover:bg-gray-50 transition-colors"
              onChange={(e) => handleDateChange(e.target.value)}
              value={
                selectedDate.toDateString() === new Date().toDateString()
                  ? "today"
                  : selectedDate.toDateString() ===
                    new Date(
                      new Date().setDate(new Date().getDate() + 1)
                    ).toDateString()
                  ? "tomorrow"
                  : "custom"
              }
            >
              <option value="today">Today</option>
              <option value="tomorrow">Tomorrow</option>
              <option value="custom">Custom Date</option>
            </select>
          )}
          <span className="text-sm font-bold">{formatDate(selectedDate)}</span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    BaseService.post(
      `/dynamic/query/employee-with-branch?userId=${userId}`,
      {},
      {}
    ).then((res) => {
      console.log("res", res.data.data);

      setSelectedBranch({
        label: res.data.data[0].branch[0].branchName,
        value: res.data.data[0].branch[0]._id,
      });

      setSections(
        res.data.data[0].branch[0].sections.map(
          (val: { name: string; description: string }) => val.name
        )
      );
      BaseService.post(
        "/dynamic/get",
        {
          collection: "tables",
          filter: {
            "section.value": {
              $in: res.data.data[0].branch[0].sections.map(
                (val: { name: string; description: string }) => val.name
              ),
            },
          },
        },
        {}
      )
        .then((res) => {
          console.log("res 2", res.data.data);
          setTableData(res.data.data);
        })
        .then(() => {
          console.log(document.getElementById("timeslot")?.clientWidth! * 24);

          BaseService.post(
            "/dynamic/get",
            {
              collection: "booking",
            },
            {}
          )
            .then((res) => {
              console.log("res 3", res.data.data);
              setBookings(res.data.data);
            })
            .then(() => {});
        });
    });
  }, [apiTrigger]);

  useEffect(() => {
    if (bookings !== null) {
      let offset3D: number[][][] = [];
      sections !== null &&
        sections.map((name: string) => {
          let i: number = 0;
          let offset2D: Array<number[]> = [];
          tableData !== null &&
            tableData
              // @ts-ignore
              .filter((val: any) => val.section.value === name)
              .map((val: any) => {
                let j: number = 0;
                console.log("i-name", i, j);
                let offset1D: number[] = [];

                bookings !== null &&
                  bookings
                    // @ts-ignore
                    .filter(
                      (innerVal: any) => val.name === innerVal.table?.label
                    )
                    .map((innerVal: any) => {
                      offset1D.push(
                        (new Date(innerVal.endTime).getUTCHours() -
                          new Date(innerVal.startTime).getUTCHours()) *
                          92
                      );

                      j++;
                    });
                offset2D.push(offset1D);
                i++;
              });
          offset3D.push(offset2D);
        });
      console.log("offset 3D", offset3D);
      setOffset3D(offset3D);
    }
  }, [sections, tableData, bookings]);

  const SumFunction = (array: number[], len: number) => {
    let sum: number = 0;
    for (let i = 0; i < len; i++) {
      sum += array[i];
    }
    return sum;
  };

  const SubmitHandler = (e: any) => {
    e.preventDefault();
    BaseService.post(
      "/dynamic/create",
      {
        collection: "booking",
        data: {
          customerPhoneNo: {
            label: customerPhoneNo,
            value: customerPhoneNo,
          },
          branch: {
            label: selectedBranch?.label,
            value: selectedBranch?.value,
          },
          section: {
            label: selectedSection?.label,
            value: selectedSection?.value,
          },
          table: {
            label: selectedTable?.label,
            value: selectedTable?.value,
          },
          startTime: new Date(startTime!),
          endTime: new Date(endTime!),
        },
      },
      {}
    ).then((res) => {
      console.log(res.data);
      setBookingPopup(false);
      setApiTrigger(apiTrigger + 1);
    });
  };

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };

  const handleTimeSlotClick = (
    e: React.MouseEvent,
    table: any,
    section: string,
    time: number
  ) => {
    const clickedDate = new Date(selectedDate);
    clickedDate.setHours(openingTime + time, 0, 0, 0);
    const endDate = new Date(clickedDate);
    endDate.setHours(endDate.getHours() + 1);

    console.log({ "clicked date": clickedDate, "end Date": endDate });

    setClickedTime(clickedDate);
    setStartTime(clickedDate.toISOString());
    setEndTime(endDate.toISOString());
    setSelectedTable({
      label: table.name,
      value: table._id,
    });
    setSelectedSection({ label: section, value: section });
    setBookingPopup(true);
  };

  return (
    <div className="flex flex-col items-start justify-start h-full w-full overflow-hidden p-4 bg-gray-100">
      <div className="flex justify-between items-center p-4 border-b w-full bg-white shadow-md">
        <h1 className="text-3xl font-bold text-gray-800">Reservation Page</h1>
        <DatePicker onDateChange={handleDateChange} />
      </div>
      <div className="flex items-start justify-start w-full h-full overflow-y-scroll mt-4">
        <div className="text-base font-semi-bold min-w-[150px] w-[150px] h-auto flex flex-col items-center justify-start bg-white shadow-lg rounded-lg">
          <div className="border-b-[1px] min-w-[150px] w-[150px] h-[50px] flex items-center justify-center border-r-[1px] border-gray-300 bg-gray-50">
            Reservation
          </div>
          {sections !== null &&
            sections.map((name: string) => (
              <div key={name}>
                <div className="border-b-[1px] min-w-[150px] w-[150px] h-[50px] flex items-center justify-center border-r-[1px] border-gray-300 bg-white hover:bg-gray-100 transition">
                  {name}
                </div>

                {tableData !== null &&
                  tableData
                    // @ts-ignore
                    .filter((val: any) => val.section.value === name)
                    .map((val: any) => (
                      <div
                        key={val._id}
                        className="border-b-[1px] min-w-[150px] w-[150px] h-[50px] flex items-center justify-between px-2 border-r-[1px] border-gray-300 bg-white hover:bg-gray-50 transition"
                      >
                        <h1 className="font-normal text-gray-700 italic">
                          {val.name}
                        </h1>
                        <div className="bg-gray-200 rounded-full px-3 py-1 font-light text-xs">
                          1 : {val.seating}
                        </div>
                      </div>
                    ))}
              </div>
            ))}
        </div>
        <div className="flex items-start justify-start h-auto w-full overflow-x-scroll flex-col ml-4">
          <div className="flex items-center justify-start w-full bg-white shadow-md rounded-lg overflow-hidden">
            {timeSlot.map((val: number) => (
              <div
                key={val}
                id="timeslot"
                className={`flex items-center justify-center font-medium text-lg min-h-[50px] min-w-[100px] border-r-[1px] border-b-[1px] border-gray-300 ${
                  val % 2 === 0 ? "text-gray-800" : "text-gray-500"
                } bg-gray-50`}
              >
                {(val + openingTime) % 12 === 0 ? 12 : (val + openingTime) % 12}
                :00{" "}
                <span className="text-sm flex items-end justify-center">
                  {val + openingTime < 12 ? "AM" : "PM"}
                </span>
              </div>
            ))}
          </div>
          <div className="flex items-start justify-start h-auto w-full overflow-x-scroll flex-col">
            {sections !== null &&
              sections.map((name: string, k: number) => (
                <div key={name} className="w-full">
                  <div className="border-b-[1px] w-full h-[50px] flex items-center justify-center border-r-[1px] border-gray-300 bg-white"></div>
                  {tableData !== null &&
                    tableData
                      // @ts-ignore
                      .filter((val: any) => val.section.value === name)
                      .map((val: any, i: number) => (
                        <div
                          key={val._id}
                          className="cursor-pointer border-b-[1px] w-full h-[50px] flex items-center p-2 border-r-[1px] border-gray-300 bg-white relative z-10"
                          style={{
                            display: "grid",
                            gridTemplateColumns: `repeat(${timeSlot.length}, 100px)`,
                          }}
                        >
                          {timeSlot.map((time) => (
                            <div
                              key={time}
                              className="w-[100px] h-full hover:bg-blue-100 transition-colors duration-200"
                              onClick={(e) =>
                                handleTimeSlotClick(e, val, name, time)
                              }
                            />
                          ))}
                          {bookings !== null &&
                            bookings
                              // @ts-ignore
                              .filter(
                                (innerVal: any) =>
                                  val.name === innerVal.table?.label &&
                                  name === innerVal.section?.value &&
                                  selectedDate.getUTCDate() ===
                                    new Date(innerVal.startTime).getUTCDate()
                              )
                              .map((innerVal: any, j: number) => (
                                <div
                                  key={innerVal._id}
                                  className="absolute h-full rounded-lg bg-red-500 text-white flex items-center justify-center z-40"
                                  style={{
                                    width: `${
                                      (new Date(innerVal.endTime).getHours() -
                                        new Date(
                                          innerVal.startTime
                                        ).getHours()) *
                                      100
                                    }px`,
                                    left: `${
                                      (new Date(innerVal.startTime).getHours() -
                                        openingTime) *
                                      100
                                    }px`,
                                  }}
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    console.log("my-booking", innerVal);
                                    dispatch(
                                      SET_SELECTED_BOOKING_ID(innerVal._id)
                                    );
                                    dispatch(SET_ROUTE("/PosPage"));
                                  }}
                                >
                                  {new Date(innerVal.startTime).getUTCHours() +
                                    5}{" "}
                                  :{" "}
                                  {new Date(innerVal.endTime).getUTCHours() + 5}
                                </div>
                              ))}
                        </div>
                      ))}
                </div>
              ))}
          </div>
        </div>
      </div>
      <Modal
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          minWidth: 300,
          border: 0,
        }}
        disableAutoFocus={true}
        open={bookingPopup}
        onClose={() => setBookingPopup(false)}
      >
        <div
          className={`bg-[${
            !darkMode
              ? pos_config?.popup_color
              : pos_config?.popup_color_darkmode
          }] p-[32px] mt-[100px] rounded-lg shadow-lg flex-col items-center justify-between border-none w-[800px] mx-auto select-none`}
        >
          <div
            className={`text-[${
              !darkMode
                ? pos_config?.popup_text_color
                : pos_config?.popup_text_color_darkmode
            }] text-2xl font-bold text-center`}
          >
            Booking Popup
          </div>
          <div className="w-full h-auto p-5">
            <form
              onSubmit={SubmitHandler}
              className="flex items-center justify-center flex-col w-full h-auto gap-6"
            >
              <div className="flex items-center justify-between w-full gap-4">
                <input
                  className="w-[48%] border h-[40px] rounded-md px-[10px] shadow-sm"
                  placeholder="Enter Customer Phone No."
                  onChange={(e) => setCustomerPhoneNo(e.target.value)}
                  type="text"
                  name="customerPhoneNo"
                />
                <input
                  className="w-[48%] border h-[40px] rounded-md px-[10px] italic text-sm text-gray-500 shadow-sm"
                  placeholder="Enter Branch"
                  value={selectedBranch?.label}
                  disabled
                  type="text"
                  name="branch"
                />
              </div>
              <div className="flex items-center justify-between w-full gap-4">
                <input
                  className="w-[48%] border h-[40px] rounded-md px-[10px] italic text-sm text-gray-500 shadow-sm"
                  placeholder="Enter Section"
                  disabled
                  value={selectedSection?.label}
                  type="text"
                  name="section"
                />
                <input
                  className="w-[48%] border h-[40px] rounded-md px-[10px] italic text-sm text-gray-500 shadow-sm"
                  placeholder="Enter Table"
                  disabled
                  value={selectedTable?.label}
                  type="text"
                  name="table"
                />
              </div>
              <div className="flex items-center justify-between w-full gap-4">
                <input
                  className="w-[48%] border h-[40px] rounded-md px-[10px] italic text-sm text-gray-500 shadow-sm"
                  placeholder="Start Time"
                  value={
                    startTime
                      ? new Date(startTime).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        })
                      : ""
                  }
                  disabled
                  type="text"
                  name="startTime"
                />
                <input
                  className="w-[48%] border h-[40px] rounded-md px-[10px] italic text-sm text-gray-500 shadow-sm"
                  placeholder="End Time"
                  value={
                    endTime
                      ? new Date(endTime).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        })
                      : ""
                  }
                  type="text"
                  name="endTime"
                  disabled
                />
              </div>
              <div className="w-full mt-4">
                <button
                  type="submit"
                  className={`cursor-pointer w-full h-[46px] rounded-[62px] bg-[${
                    !darkMode
                      ? pos_config?.popup_secondary_button_color
                      : pos_config?.popup_secondary_button_color_darkmode
                  }] text-[${
                    !darkMode
                      ? pos_config?.popup_secondary_button_text_color
                      : pos_config?.popup_secondary_button_text_color_darkmode
                  }] text-[16px] font-[700]`}
                >
                  Confirm Booking
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ReservationPage;
