import { Modal, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import {
  PLACE_ORDER,
  SET_CASH_PAID,
  SET_CASH_PAYMENT_POPUP,
  SET_ORDER_DETAILS,
  SET_IS_ORDER_READY,
  SET_SELECTED_ORDER,
  SET_AMOUNT_NOT_REACHED_PROMPT,
  SET_CHECKOUT_POPUP,
} from "../../Features/cart";
import { makeStyles } from "@mui/styles";
import {
  SET_ISCHECKOUT,
  SET_PRINTERPOPUP,
  SET_SELECTEDHEADER,
  SET_VISUAL_HEADER,
} from "../../Features/rightBarHeader";
import axios from "axios";
import { SET_CUSTOMER_DATA } from "../../Features/customer";
import { BaseService, createNewBaseService } from "../../assets/BaseService";
import { OrderDetails } from "../../Interfaces/IOrderDetails";
import { Root2 } from "../../Interfaces/IOrderData";
import { INITIALIZE_PRODUCT_CONSUMPTION_DETAIL } from "../../Features/OrderConsumption";

const useStyles = makeStyles({
  inputRoot: {
    "& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button":
      {
        "-webkit-appearance": "none",
        margin: 0,
      },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
});

const CashPaymentPopup = () => {
  const payment = useSelector((state: RootState) => state.cart.payment);
  const dispatch = useDispatch();
  const [cashPaid, setCashPaid] = useState<number>(0);
  const leftHeader = useSelector((state: RootState) => state.leftHeader);
  const db = useSelector((state: RootState) => state.database.db);
  const slug = useSelector((state: RootState) => state.leftHeader.slug);
  const cart = useSelector((state: RootState) => state.cart);
  const orderConsumption = useSelector(
    (state: RootState) => state.orderConsumption
  );
  //@ts-ignore
  const classes = useStyles();
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const NewBaseService = createNewBaseService(pos_config);

  const orderConsumptionUpdation = async (orderCode: string) => {
    await BaseService.post(`/dynamic/get`, {
      collection: "order-consumption",
      filter: {
        orderID: orderCode,
      },
    }).then(async (res) => {
      console.log("order consumption res check", res.data);
      if (res.data.data.length === 0) {
        console.log("order consumption create scenes");
        await BaseService.post(`/dynamic/create`, {
          collection: "order-consumption",
          data: {
            orderID: orderCode,
            products: orderConsumption.orderConsumption.products,
          },
        });
      } else {
        console.log(
          "order consumption update scenes",
          orderConsumption.orderConsumption.products
        );
        await BaseService.patch(`/dynamic/update`, {
          collection: "order-consumption",
          filter: {
            orderID: orderCode,
          },
          data: {
            products: orderConsumption.orderConsumption.products,
          },
        }).then((res) => {
          console.log("order consumption update scenes api response", res);
        });
      }
    });
  };

  return (
    <div>
      <div
        className={`bg-[${
          !darkMode ? pos_config?.popup_color : pos_config?.popup_color_darkmode
        }] p-4 sm:p-6 md:p-8 rounded-lg shadow-md space-y-4 sm:space-y-6 w-[90vw] sm:w-[80vw] md:w-[450px] mx-auto select-none`}
      >
        <h2
          className={`text-[${
            !darkMode
              ? pos_config?.popup_text_color
              : pos_config?.popup_text_color_darkmode
          }] text-lg sm:text-xl font-bold text-center`}
        >
          Cash Payment Details
        </h2>

        <TextField
          fullWidth
          label="Cash Paid"
          autoFocus
          type="number"
          InputLabelProps={{
            sx: darkMode
              ? { color: `${pos_config?.popup_text_color_darkmode}` }
              : { color: `${pos_config?.popup_text_color}` },
          }}
          InputProps={{
            classes: { root: classes.inputRoot },
            sx: darkMode
              ? { color: `${pos_config?.popup_text_color_darkmode}` }
              : { color: `${pos_config?.popup_text_color}` },
          }}
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              if (payment.cashPaid - payment.cashAmount >= 0) {
                if (navigator.onLine) {
                  console.log("order-details", cart.orderJson);
                  dispatch(SET_ISCHECKOUT(true));
                  dispatch(SET_PRINTERPOPUP(true));

                  if (cart.selectedOrderCode) {
                    await NewBaseService.post(
                      `/dynamic/update`,
                      {
                        collection: "orders",
                        filter: {
                          id: cart.selectedOrderCode,
                        },
                        data: cart.orderJson,
                      },
                      {}
                    ).then((res) => {
                      console.log("Order API response on Update", res);
                      const orderGet: Root2 = res.data.data;
                      orderConsumptionUpdation(orderGet.code);
                    });
                  } else {
                    await NewBaseService.post(
                      `/dynamic/create`,
                      {
                        collection: "orders",
                        data: cart.orderJson,
                      },
                      {}
                    ).then((res) => {
                      console.log(
                        "Order API response after creating order request",
                        res
                      );
                      const orderGet: Root2 = res.data.data;
                      orderConsumptionUpdation(orderGet.code);
                    });
                  }
                } else {
                  // // @ts-ignore
                  // db.put({
                  //   _id: new Date().toISOString(),
                  //   order: cart.orderDetails,
                  // });
                  // // @ts-ignore
                  // db.allDocs({ include_docs: true }).then((res: any) =>
                  //   console.log(res)
                  // );
                }

                dispatch(SET_CASH_PAYMENT_POPUP(false));
                dispatch(SET_CHECKOUT_POPUP(false))
                pos_config && pos_config.customer_tab_as_default_tab
                  ? dispatch(SET_SELECTEDHEADER("Delivery"))
                  : dispatch(SET_SELECTEDHEADER("Dine-in"));
                  dispatch(SET_VISUAL_HEADER("Dine-in"))
              } else {
                console.log("WRONG PAYMENT ");
                dispatch(SET_AMOUNT_NOT_REACHED_PROMPT(true));
              }
            }
          }}
          onChange={(e) => {
            dispatch(
              SET_CASH_PAID(
                e.target.value === undefined || e.target.value === ""
                  ? 0
                  : parseInt(e.target.value)
              )
            );
          }}
        />
        <div
          className={`space-y-2 text-[${
            !darkMode
              ? pos_config?.popup_text_color
              : pos_config?.popup_text_color_darkmode
          }]`}
        >
          <div className="flex justify-between items-center">
            <span>Cash Payable: </span>
            <span>Rs. {payment.cashAmount}</span>
          </div>
          <div className="flex justify-between items-center">
            <span>Cash Returned: </span>
            <span>
              Rs.{" "}
              {typeof Number(payment.cashAmount) === "number" &&
              typeof Number(payment.cashPaid) === "number"
                ? (payment.cashPaid - payment.cashAmount).toFixed(2)
                : 0}
            </span>
          </div>
        </div>
      </div>
      <Modal
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          minWidth: 300,
          border: 0,
        }}
        disableAutoFocus={true}
        open={cart.payment.amountNotReachedPrompt}
        onClose={() => dispatch(SET_AMOUNT_NOT_REACHED_PROMPT(false))}
      >
        <div className="bg-[#FFFFFF] p-4 sm:p-6 md:p-8 mt-[10vh] rounded-lg flex-col items-center justify-between border-none w-[90vw] sm:w-[80vw] md:w-[450px] mx-auto select-none">
          <div className="text-[#000000] text-lg sm:text-xl md:text-[24px] font-[700] text-center">
            Order Amount Rs {cart.discountedAndTaxedAmount} is not reached
          </div>
          <div className="flex flex-row justify-around pt-4">
            <div
              onClick={() => {
                dispatch(SET_AMOUNT_NOT_REACHED_PROMPT(false));
              }}
              className="cursor-pointer w-full sm:w-[165px] h-[46px] rounded-[62px] flex items-center justify-center bg-[#EF233C] text-[#FFFFFF] text-[14px] sm:text-[16px] font-[700]"
            >
              OK
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CashPaymentPopup;
