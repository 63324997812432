import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../..";
import logoSrc from "../../assets/RightBar/Logo.png";

function Invoice() {
  const cart = useSelector((state: RootState) => state.cart);
  const customer = useSelector((state: RootState) => state.customer);
  const posConfig = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const payment = useSelector((state: RootState) => state.cart.payment);

  return (
    <div className="p-2 my-print w-[400px]">
      <div className="w-full flex items-center justify-start flex-col">
        <div className="w-full flex items-center justify-center bg-slate-200">
          <img className="" src={posConfig.logo_img[0].url} alt="" />
        </div>
        <h2 className="text-center text-[13px] font-bold">operating partner</h2>
        <h2 className="text-center text-[13px] font-bold">www.cloubuzz.com</h2>
        <h2 className="text-center text-[13px] font-bold">
          House # 82 - Block B2, Gulberg III, Lahore, Pakistan
        </h2>
        {/* <h2 className="text-center text-[13px] font-bold">NTN # 7371255-3</h2>
        <h2 className="text-center text-[13px] font-bold">
          STRN # 3277876136470
        </h2>
        <h2 className="text-center text-[13px] font-bold">
          FREE VALET PARKING
        </h2> */}
        <h2 className="text-center text-[15px] font-bold">
          For Delivery & Whatsapp :
        </h2>
        <h2 className="text-center text-[15px] font-bold">0334-0654474</h2>
        <h2 className="text-center text-[11px] font-bold">
          Contact: 04210098130
        </h2>
        <div className="w-full border-t-[1px] border-black"></div>
      </div>
      <div className="w-full bg-black flex items-center justify-center mt-1">
        <h2 className="text-white text-[16px] font-bold">Sale Receipt</h2>
      </div>
      <div className="w-full">
        <div className="w-full border-b-[1px] border-black h-[30px] flex items-center">
          <div className="w-[100px] text-[12px] border-r-[1px] border-black h-full flex items-center">
            <h3 className="font-bold">Date & Time:</h3>
          </div>
          <div className="w-[300px] text-[12px] h-full flex items-center justify-center">
            {new Date().toLocaleDateString(undefined, {
              hour: "numeric",
              hour12: true,
              minute: "2-digit",
            })}
          </div>
        </div>
        <div className="w-full border-b-[1px] border-black h-[30px] flex items-center">
          <div className="w-[100px] text-[12px] border-r-[1px] border-black h-full flex items-center">
            <h3 className="font-bold">Transaction:</h3>
          </div>

          <div className="w-[300px] text-[12px] pl-1">
            {payment.isCashPayment && "Cash"}
            {payment.isCardPayment && "Card"}
            {payment.isCreditPayment && "Credit"}
            {payment.isOnlineBankPayment && "Online"}
            {payment.isJointPayment && "Joint"}
          </div>
        </div>
        <div className="w-full border-b-[1px] border-black h-[30px] flex items-center">
          <div className="w-[220px] text-[12px] border-r-[1px] border-black h-full flex items-center">
            <h3 className="font-bold">
              User:{" "}
              <span className="font-normal">{cart.loginData.employeeName}</span>
            </h3>
          </div>
          <div className="w-[180px] text-[12px] ml-1">
            <h3 className="font-bold">
              Counter: <span className="font-normal">COUNTER 1</span>
            </h3>
          </div>
        </div>
        <div className="w-full border-b-[1px] border-black h-[30px] flex items-center">
          <div className="w-[220px] text-[12px] border-r-[1px] border-black h-full flex items-center">
            <h3 className="font-bold">
              Name: {/* @ts-ignore */}
              <span className="font-normal">
                {customer.data && customer.data.Name}
              </span>
            </h3>
          </div>
          <div className="w-[180px] text-[12px] ml-1">
            <h3 className="font-bold">
              {" "}
              Phone No: {/* @ts-ignore */}
              <span className="font-normal">
                {customer.data && customer.data.Mobile}
              </span>
            </h3>
          </div>
        </div>
      </div>
      <div className="w-full bg-black mt-1 flex items-center h-[25px]">
        <div className="w-[150px] text-white font-bold text-[15px] pl-1">
          Description
        </div>
        <div className="text-white font-bold text-[15px] w-[60px] text-center">
          Qty
        </div>
        <div className="text-white font-bold text-[15px] w-[80px] text-center">
          Rate
        </div>
        <div className="text-white font-bold text-[15px] w-[90px] text-end pr-1">
          Amount
        </div>
      </div>
      <div className="w-full border-t-[1px] border-black mt-1"></div>
      {/* map here */}
      {cart.order?.map((item: any, index: number) => (
        <div
          key={index}
          className="w-full flex justify-start items-start flex-col"
        >
          <div className="text-[14px] w-full mt-2">*{item.itemName}</div>
          <div className="flex items-center border-b border-dashed border-slate-300">
            <div className="w-[150px] text-[12px] text-start">
              {item.itemID}
            </div>
            <div className="w-[60px] text-[12px] text-center">
              {item.quantity}.00
            </div>
            <div className="w-[80px] text-[12px] text-center">
              {item.total}.00
            </div>
            <div className="w-[90px] text-[12px] text-end">
              {item.orderTotal}.00
            </div>
          </div>
        </div>
      ))}

      <div className="w-full h-[25px] border-y-[1px] border-black flex items-center justify-between mt-1">
        <div className="w-[110px] flex items-center justify-between">
          <h4 className="text-[10px]">No. Of Items(s)</h4>
          <h4 className="text-[10px]">{cart.order?.length}</h4>
        </div>
        <div className="w-[90px] flex items-center justify-between">
          <h4 className="ml-[20px] text-[10px]">Total Qty:</h4>
          <h4 className="text-[10px]">{cart.order?.length}</h4>
        </div>
        <div className="w-[80px] text-[10px] text-end">{cart.cartTotal}.00</div>
      </div>

      <div className="w-full flex items-center mt-1">
        <div className="w-[190px] flex flex-col items-end justify-start">
          {payment.isCashPayment && (
            <div className="text-[11px]">Cash Paid:</div>
          )}
          {payment.isCashPayment && (
            <div className="text-[11px]">Cash Returned:</div>
          )}
          <div className="text-[12px] font-bold">Discount:</div>
          <div className="text-[12px] font-bold">Tax:</div>
          <div className="text-[12px] font-bold">Net Amount:</div>
        </div>
        <div className="w-[110px] flex flex-col justify-start items-end">
          {payment.isCashPayment && (
            <div className="text-[11px]">{payment.cashPaid}</div>
          )}
          {payment.isCashPayment && (
            <div className="text-[11px]">
              {(payment.cashPaid - payment.cashAmount).toFixed(2)}
            </div>
          )}
          <div className="text-[12px] font-bold">{cart.discount}</div>
          <div className="text-[12px] font-bold">{cart.taxAmount}</div>
          <div className="text-[12px] font-bold">
            {cart.discountedAndTaxedAmount}
          </div>
        </div>
      </div>

      {/* <div className="w-full text-center font-mono">* 053148/Ja *</div> */}

      {/* <div className="w-full flex items-center justify-center my-[30px]">
        <img
          className="w-[100px] h-[100px]"
          src="https://pbs.twimg.com/profile_images/1490297222663180296/xVXIV10z_400x400.jpg"
          alt=""
        />
      </div> */}

      <div className="w-full border-b-[1px] border-black mt-1"></div>
    </div>
  );
}

export default Invoice;
