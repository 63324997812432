import { useSelector } from "react-redux";
import { RootState } from "../..";
import { objQuantity, objSum, objSum2, sumExpenses } from "./SalesReportUtils";

function SalesReport() {
  const cart = useSelector((state: RootState) => state.cart);
  const posConfig = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const salesReport = useSelector((state: RootState) => state.salesReport);

  // const RevenueGenerator = () => {
  //   if (salesReport.salesInfo && salesReport.expenseData) {
  //     return (
  //       objSum2(salesReport.salesInfo) - sumExpenses(salesReport.expenseData)
  //     );
  //   } else if (salesReport.salesInfo) {
  //     return objSum2(salesReport.salesInfo);
  //   } else if (salesReport.expenseData) {
  //     return 0 - sumExpenses(salesReport.expenseData);
  //   } else {
  //     return 0;
  //   }
  // };

  return (
    <div className="p-2 my-print w-[400px]">
      <div className="w-full flex items-center justify-start flex-col">
        <div className="w-full flex items-center justify-center bg-slate-200">
          <img className="" src={posConfig.logo_img[0].url} alt="" />
        </div>
        <h2 className="text-center text-[13px] font-bold">operating partner</h2>
        <h2 className="text-center text-[13px] font-bold">www.cloubuzz.com</h2>
        <h2 className="text-center text-[13px] font-bold">
          Al Naseeb, Quetta, Pakistan
        </h2>

        <h2 className="text-center text-[15px] font-bold">
          For Delivery & Whatsapp :
        </h2>
        <h2 className="text-center text-[15px] font-bold">0334-0654474</h2>
        <h2 className="text-center text-[11px] font-bold">
          Contact: 04210098130
        </h2>
        <div className="w-full border-t-[1px] border-black"></div>
      </div>
      <div className="w-full bg-black flex items-center justify-center mt-1">
        <h2 className="text-white text-[16px] font-bold">Sales Report</h2>
      </div>
      <div className="w-full">
        <div className="w-full border-b-[1px] border-black h-[30px] flex items-center">
          <div className="w-[100px] border-r-[1px] border-black text-[12px] h-full flex items-center justify-center text-center font-bold">
            Employee:
          </div>
          <div className="w-[300px] text-[12px] h-full flex items-center justify-center">
            {cart.loginData.employeeName} ({cart.loginData.employeeID})
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="w-full border-b-[1px] border-black h-[30px] flex items-center">
          <div className="w-[100px] border-r-[1px] border-black text-[12px] h-full flex items-center justify-center text-center font-bold">
            Opening Time:
          </div>
          <div className="w-[300px] text-[12px] h-full flex items-center justify-center">
            {salesReport.openingDate &&
              salesReport.openingDate.toLocaleDateString("en-GB", {
                month: "long",
                day: "numeric",
                year: "2-digit",
                hour: "2-digit",
                hour12: true,
                minute: "2-digit",
              })}
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="w-full border-b-[1px] border-black h-[30px] flex items-center">
          <div className="w-[100px] border-r-[1px] border-black text-[12px] h-full flex items-center justify-center text-center font-bold">
            Opening Cash:
          </div>
          <div className="w-[300px] text-[12px] h-full flex items-center justify-center">
            Rs.{salesReport.openingCash}
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="w-full border-b-[1px] border-black h-[30px] flex items-center">
          <div className="w-[100px] border-r-[1px] border-black text-[12px] h-full flex items-center justify-center text-center font-bold">
            Closing Time:
          </div>
          <div className="w-[300px] text-[12px] h-full flex items-center justify-center">
            {salesReport.closingDate &&
              salesReport.closingDate.toLocaleDateString("en-GB", {
                month: "long",
                day: "numeric",
                year: "2-digit",
                hour: "2-digit",
                hour12: true,
                minute: "2-digit",
              })}
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="w-full border-b-[1px] border-black h-[30px] flex items-center">
          <div className="w-[100px] border-r-[1px] border-black text-[12px] h-full flex items-center justify-center text-center font-bold">
            Closing Cash:
          </div>
          <div className="w-[300px] text-[12px] h-full flex items-center justify-center">
            Rs.{salesReport.closingCash}
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="w-full border-b-[1px] border-black h-[30px] flex items-center">
          <div className="w-[100px] border-r-[1px] border-black text-[12px] h-full flex items-center justify-center text-center font-bold">
            Delta:
          </div>
          <div className="w-[300px] text-[12px] h-full flex items-center justify-center">
            Rs.{salesReport.delta}
          </div>
        </div>
      </div>

      <div className="w-full bg-black mt-1 flex items-center h-[25px]">
        <div className="w-[150px] text-white font-bold text-[15px] pl-1">
          Categories
        </div>
        <div className="text-white font-bold text-[15px] w-[60px] text-center">
          Qty
        </div>
        <div className="text-white font-bold text-[15px] w-[80px] text-center"></div>
        <div className="text-white font-bold text-[15px] w-[90px] text-end pr-1">
          Amount
        </div>
      </div>
      <div className="w-full border-t-[1px] border-black mt-1"></div>
      {/* map here */}
      {salesReport.salesInfoByCategory &&
        salesReport.salesInfoByCategory.map((item: any, index: number) => (
          <div
            key={index}
            className="w-full flex justify-start items-start flex-col"
          >
            <div className="text-[14px] w-full mt-2"></div>
            <div className="flex items-center border-b border-dashed border-slate-300">
              <div className="w-[150px] max-w-[150px] text-[12px] text-start truncate">
                {item.productName}
              </div>
              <div className="w-[60px] text-[12px] text-center">
                {item.count}.00
              </div>
              <div className="w-[80px] text-[12px] text-center">
                {/* {Math.round(Number(item.totalProductValue / item.count))} */}
              </div>
              <div className="w-[90px] text-[12px] text-end">
                {item.totalProductValue}.00
              </div>
            </div>
          </div>
        ))}

      <div className="w-full h-[25px] border-y-[1px] border-black flex items-center justify-between mt-1">
        <div className="w-[110px] flex items-center justify-between">
          <h4 className="text-[10px]">No. Of Items(s)</h4>
          <h4 className="text-[10px]">
            {salesReport.salesInfoByCategory &&
              salesReport.salesInfoByCategory.length}
          </h4>
        </div>
        <div className="w-[90px] flex items-center justify-between">
          <h4 className="ml-[20px] text-[10px]">Total Qty:</h4>
          <h4 className="text-[10px]">
            {salesReport.salesInfoByCategory
              ? objQuantity(salesReport.salesInfoByCategory)
              : 0}
          </h4>
        </div>
        <div className="w-[80px] text-[10px] text-end">
          {salesReport.salesInfoByCategory
            ? objSum2(salesReport.salesInfoByCategory)
            : 0}
          .00
        </div>
      </div>

      {/* <div className="w-full bg-black mt-1 flex items-center h-[25px]">
        <div className="w-[150px] text-white font-bold text-[15px] pl-1">
          Products
        </div>
        <div className="text-white font-bold text-[15px] w-[60px] text-center">
          Qty
        </div>
        <div className="text-white font-bold text-[15px] w-[80px] text-center"></div>
        <div className="text-white font-bold text-[15px] w-[90px] text-end pr-1">
          Amount
        </div>
      </div>
      <div className="w-full border-t-[1px] border-black mt-1"></div> */}
      {/* map here */}
      {/* {salesReport.salesInfo &&
        salesReport.salesInfo.map((item: any, index: number) => (
          <div
            key={index}
            className="w-full flex justify-start items-start flex-col"
          >
            <div className="text-[14px] w-full mt-2"></div>
            <div className="flex items-center border-b border-dashed border-slate-300">
              <div className="w-[150px] max-w-[150px] text-[12px] text-start truncate">
                {item.productName}
              </div>
              <div className="w-[60px] text-[12px] text-center">
                {item.count}.00
              </div>
              <div className="w-[80px] text-[12px] text-center">
              </div>
              <div className="w-[90px] text-[12px] text-end">
                {item.totalProductValue}.00
              </div>
            </div>
          </div>
        ))}

      <div className="w-full h-[25px] border-y-[1px] border-black flex items-center justify-between mt-1">
        <div className="w-[110px] flex items-center justify-between">
          <h4 className="text-[10px]">No. Of Items(s)</h4>
          <h4 className="text-[10px]">
            {salesReport.salesInfo && salesReport.salesInfo.length}
          </h4>
        </div>
        <div className="w-[90px] flex items-center justify-between">
          <h4 className="ml-[20px] text-[10px]">Total Qty:</h4>
          <h4 className="text-[10px]">
            {salesReport.salesInfo ? objQuantity(salesReport.salesInfo) : 0}
          </h4>
        </div>
        <div className="w-[80px] text-[10px] text-end">
          {salesReport.salesInfo ? objSum2(salesReport.salesInfo) : 0}.00
        </div>
      </div> */}

      <div className="w-full bg-black mt-1 flex items-center h-[25px]">
        <div className="w-[150px] text-white font-bold text-[15px] pl-1">
          Payment Type
        </div>
        <div className="text-white font-bold text-[15px] w-[60px] text-center">
          Qty
        </div>
        <div className="text-white font-bold text-[15px] w-[80px] text-center"></div>
        <div className="text-white font-bold text-[15px] w-[90px] text-end pr-1">
          Amount
        </div>
      </div>
      <div className="w-full border-t-[1px] border-black mt-1"></div>
      {/* map here */}
      {salesReport.salesInfoByPaymentType &&
        salesReport.salesInfoByPaymentType.map((item: any, index: number) => (
          <div
            key={index}
            className="w-full flex justify-start items-start flex-col"
          >
            <div className="text-[14px] w-full mt-2"></div>
            <div className="flex items-center border-b border-dashed border-slate-300">
              <div className="w-[150px] max-w-[150px] text-[12px] text-start truncate">
                {item.paymentType}
              </div>
              <div className="w-[60px] text-[12px] text-center">
                {item.count}
              </div>
              <div className="w-[80px] text-[12px] text-center"></div>
              <div className="w-[90px] text-[12px] text-end">
                {item.totalOrderAmount}.00
              </div>
            </div>
          </div>
        ))}

      <div className="w-full h-[25px] border-y-[1px] border-black flex items-center justify-between mt-1">
        <div className="w-[110px] flex items-center justify-between">
          <h4 className="text-[10px]"></h4>
          <h4 className="text-[10px]"></h4>
        </div>
        <div className="w-[90px] flex items-center justify-between">
          <h4 className="ml-[20px] text-[10px]">Total Qty:</h4>
          <h4 className="text-[10px]">
            {salesReport.salesInfoByPaymentType
              ? objQuantity(salesReport.salesInfoByPaymentType)
              : 0}
          </h4>
        </div>
        <div className="w-[80px] text-[10px] text-end">
          {salesReport.salesInfoByPaymentType
            ? objSum(salesReport.salesInfoByPaymentType)
            : 0}
          .00
        </div>
      </div>

      <div className="w-full bg-black mt-1 flex items-center h-[25px]">
        <div className="w-[150px] text-white font-bold text-[15px] pl-1">
          Expenses
        </div>
        <div className="text-white font-bold text-[15px] w-[60px] text-center"></div>
        <div className="text-white font-bold text-[15px] w-[80px] text-center"></div>
        <div className="text-white font-bold text-[15px] w-[90px] text-end pr-1">
          Amount
        </div>
      </div>
      <div className="w-full border-t-[1px] border-black mt-1"></div>
      {/* map here */}
      {salesReport.expenseData &&
        salesReport.expenseData.map((item: any, index: number) => (
          <div
            key={index}
            className="w-full flex justify-start items-start flex-col"
          >
            <div className="text-[14px] w-full mt-2"></div>
            <div className="flex items-center border-b border-dashed border-slate-300">
              <div className="w-[150px] max-w-[150px] text-[12px] text-start truncate">
                {item.description}
              </div>
              <div className="w-[60px] text-[12px] text-center"></div>
              <div className="w-[80px] text-[12px] text-center"></div>
              <div className="w-[90px] text-[12px] text-end">
                {item.amount}.00
              </div>
            </div>
          </div>
        ))}

      <div className="w-full h-[25px] border-y-[1px] border-black flex items-center justify-between mt-1">
        <div className="w-[110px] flex items-center justify-between">
          <h4 className="text-[10px]">No. Of Items(s)</h4>
          <h4 className="text-[10px]">
            {salesReport.expenseData && salesReport.expenseData.length}
          </h4>
        </div>
        <div className="w-[90px] flex items-center justify-between">
          <h4 className="ml-[20px] text-[10px]"></h4>
          <h4 className="text-[10px]"></h4>
        </div>
        <div className="w-[80px] text-[10px] text-end">
          {salesReport.expenseData ? sumExpenses(salesReport.expenseData) : 0}
          .00
        </div>
      </div>

      <div className="w-full flex items-center mt-1">
        <div className="w-[190px] flex flex-col items-end justify-start">
          <div className="text-[12px] font-bold">Discount:</div>
          <div className="text-[12px] font-bold">Total Sales:</div>
        </div>
        <div className="w-[110px] flex flex-col justify-start items-end">
          <div className="text-[12px] font-bold">{cart.discount}.00</div>
          <div className="text-[12px] font-bold">
            {salesReport.salesInfo ? objSum2(salesReport.salesInfo) : 0}.00
          </div>
        </div>
      </div>

      <div className="w-full border-b-[1px] border-black mt-1"></div>
    </div>
  );
}

export default SalesReport;
