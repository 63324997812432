import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ExpenseObject } from "../Interfaces/IExpenseObject";

interface SalesReport {
  openingCash: number;
  openingDate: Date | undefined;
  closingCash: number;
  closingDate: Date | undefined;
  salesReportPopUp: boolean;
  salesInfo:
    | Array<{
        count: number;
        totalProductValue: number;
        totalDiscountGiven: number;
        productName: string;
      }>
    | undefined;
  salesInfoByCategory:
    | Array<{
        count: number;
        totalProductValue: number;
        totalDiscountGiven: number;
        productName: string;
      }>
    | undefined;
  salesInfoByPaymentType:
    | Array<{
        count: number;
        totalOrderAmount: number;
        paymentType: string;
      }>
    | undefined;
  expenseData: ExpenseObject[] | undefined;
  delta: number;
}

const initialState: SalesReport = {
  openingCash: 0,
  openingDate: undefined,
  closingCash: 0,
  closingDate: undefined,
  salesReportPopUp: false,
  salesInfo: undefined,
  salesInfoByCategory: undefined,
  salesInfoByPaymentType: undefined,
  expenseData: undefined,
  delta: 0,
};

const salesReportSlice = createSlice({
  name: "salesReport",
  initialState,
  reducers: {
    SET_DELTA: (state, action: PayloadAction<number>) => {
      state.delta = action.payload;
    },
    SET_OPENING_CASH: (state, action: PayloadAction<number>) => {
      state.openingCash = action.payload;
    },
    SET_CLOSING_CASH: (state, action: PayloadAction<number>) => {
      state.closingCash = action.payload;
    },
    SET_OPENING_DATE: (state, action: PayloadAction<Date>) => {
      state.openingDate = action.payload;
    },
    SET_CLOSING_DATE: (state, action: PayloadAction<Date>) => {
      state.closingDate = action.payload;
    },
    SET_SALESREPORTPOPUP: (state, action: PayloadAction<boolean>) => {
      state.salesReportPopUp = action.payload;
    },
    SET_SALESINFO: (
      state,
      action: PayloadAction<
        Array<{
          count: number;
          totalProductValue: number;
          totalDiscountGiven: number;
          productName: string;
        }>
      >
    ) => {
      state.salesInfo = action.payload;
    },
    SET_SALESINFO_BY_CATEGORY: (
      state,
      action: PayloadAction<
        Array<{
          count: number;
          totalProductValue: number;
          totalDiscountGiven: number;
          productName: string;
        }>
      >
    ) => {
      state.salesInfoByCategory = action.payload;
    },
    SET_SALESINFO_BY_PAYMENT_TYPE: (
      state,
      action: PayloadAction<
        Array<{ count: number; totalOrderAmount: number; paymentType: string }>
      >
    ) => {
      state.salesInfoByPaymentType = action.payload;
    },

    SET_EXPENSE_DATA: (state, action: PayloadAction<ExpenseObject[]>) => {
      state.expenseData = action.payload;
    },
  },
});

export const {
  SET_OPENING_CASH,
  SET_CLOSING_CASH,
  SET_CLOSING_DATE,
  SET_OPENING_DATE,
  SET_DELTA,
  SET_SALESREPORTPOPUP,
  SET_SALESINFO_BY_PAYMENT_TYPE,
  SET_SALESINFO_BY_CATEGORY,
  SET_SALESINFO,
  SET_EXPENSE_DATA,
} = salesReportSlice.actions;
export default salesReportSlice.reducer;
