import React from "react";
import OrderOptions from "./OrderOptions";
import OrderList from "./OrdersList";

const OrderBody = () => {
  return (
    <div className="px-[20px] py-[15px]">
      <OrderList />
    </div>
  );
};

export default OrderBody;
