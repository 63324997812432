import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface OrderType {
  value: Array<object>;
  total: number;
}

const initialState: OrderType = {
  value: [],
  total: 0,
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    createOrder: (
      state,
      action: PayloadAction<{ object: object; total: number }>
    ) => {
      state.value.push(action.payload.object);

      state.total += action.payload.total;
    },
    INCREASE_ITEM_QUANTITY: (
      state,
      action: PayloadAction<{
        index: number;
        quantity: number;
        total: number;
        price: number;
      }>
    ) => {
      const obj = state.value.map((item, i) => {
        if (i === action.payload.index) {
          return {
            ...item,
            //@ts-ignore
            quantity: action.payload.quantity,
            //@ts-ignore
            total: action.payload.total,
          };
        }
        return item;
      });
      state.value = obj;
      state.total += action.payload.price;
    },
    DECREASE_ITEM_QUANTITY: (
      state,
      action: PayloadAction<{
        index: number;
        quantity: number;
        total: number;
        price: number;
      }>
    ) => {
      const obj = state.value.map((item, i) => {
        if (i === action.payload.index) {
          return {
            ...item,
            //@ts-ignore
            quantity: action.payload.quantity,
            //@ts-ignore
            total: action.payload.total,
          };
        }
        return item;
      });
      state.value = obj;
      state.total -= action.payload.price;
    },
    REMOVE_ITEM_FROM_ORDER: (
      state,
      action: PayloadAction<{ index: number; total: number }>
    ) => {
      const obj = state.value;
      obj.splice(action.payload.index, 1);
      state.value = obj;
      state.total -= action.payload.total;
    },
  },
});

export const {
  createOrder,
  INCREASE_ITEM_QUANTITY,
  DECREASE_ITEM_QUANTITY,
  REMOVE_ITEM_FROM_ORDER,
} = orderSlice.actions;
export default orderSlice.reducer;
