import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";

interface ItemsStates {
  name: string;
  data: any | null;
  size: number;
  quantity: number;
  flavor: Array<object> | null;
  itemPrice: number;
  itemID: number | null;
  category: string;
  MinimumDelivery: number;
  firstUpdatedAddonIndex: number;
  sizeID: number;
  comingFrom: string;
  counter: number;
  arrowKeyLevel: number;
  iterator: number;
  iterator2: number;
  iterator3: number;
  trigger: number;
  trigger2: number;
  popUpIterator: number;
  popUpSection: number;
  popUpTrigger: number;
  topLevelCategory: string;
  checkoutTrigger: number;
  holdTrigger: number;
  printTrigger: number;
  isFlavoursAndToppingsOpen: boolean;
}

const initialState: ItemsStates = {
  name: "",
  data: null,
  size: 0,
  quantity: 0,
  firstUpdatedAddonIndex: 0,
  flavor: null,
  itemPrice: 0,
  itemID: null,
  category: "",
  MinimumDelivery: 0,
  sizeID: 0,
  comingFrom: "",
  counter: 0,
  arrowKeyLevel: 0,
  iterator: 0,
  iterator2: 0,
  iterator3: 0,
  trigger: 0,
  trigger2: 0,
  popUpIterator: 0,
  popUpSection: 0,
  popUpTrigger: 0,
  topLevelCategory: "",
  checkoutTrigger: 0,
  holdTrigger: 0,
  printTrigger: 0,
  isFlavoursAndToppingsOpen: false,
};

const itemsStates = createSlice({
  name: "itemsStates",
  initialState,
  reducers: {
    SET_FLAVOURS_AND_TOPPINGS_OPEN: (state, action: PayloadAction<boolean>) => {
      state.isFlavoursAndToppingsOpen = action.payload;
    },

    SET_ITEMS_STATES_DATA: (
      state,
      action: PayloadAction<{
        name: string;
        data: object;
        size: number;
        flavor: Array<object> | null;
        itemPrice: number;
        itemID: number;
        category: string;
        MinimumDelivery: number;
        sizeID: number;
        quantity: number;
      }>
    ) => {
      state.data = action.payload.data;
      state.flavor = action.payload.flavor;
      state.itemID = action.payload.itemID;
      state.quantity = action.payload.quantity;
      state.itemPrice = action.payload.itemPrice;
      state.name = action.payload.name;
      state.size = action.payload.size;
      state.MinimumDelivery = action.payload.MinimumDelivery;
      state.category = action.payload.category;
      state.sizeID = action.payload.sizeID;
    },
    SET_TO_INITIAL_ITEMS_STATE: (state) => {
      state.name = "";
      state.data = null;
      state.size = 0;
      state.flavor = null;
      state.itemPrice = 0;
      state.itemID = null;
      state.category = "";
      state.quantity = 0;
      state.MinimumDelivery = 0;
      state.sizeID = 0;
    },
    SET_SIZES_UPDATES: (
      state,
      action: PayloadAction<{
        flavor: any;
        itemPrice: number;
        MinimumDelivery: number;
        sizeID: number;
      }>
    ) => {
      state.flavor = action.payload.flavor;
      state.sizeID = action.payload.sizeID;
      state.itemPrice = action.payload.itemPrice;
      state.MinimumDelivery = action.payload.MinimumDelivery;
    },
    SET_COMINGFROM: (state, action: PayloadAction<string>) => {
      state.comingFrom = action.payload;
    },
    SET_ITERATOR: (state, action: PayloadAction<number>) => {
      state.iterator = action.payload;
    },
    SET_ITERATOR2: (state, action: PayloadAction<number>) => {
      state.iterator2 = action.payload;
    },
    SET_ITERATOR3: (state, action: PayloadAction<number>) => {
      state.iterator3 = action.payload;
    },
    SET_TRIGGER: (state, action: PayloadAction<number>) => {
      state.trigger = action.payload;
    },
    SET_TRIGGER2: (state, action: PayloadAction<number>) => {
      state.trigger2 = action.payload;
    },
    SET_POPUPITERATOR: (state, action: PayloadAction<number>) => {
      state.popUpIterator = action.payload;
    },
    SET_UPDATEDADDONITEMINDEX: (state, action: PayloadAction<number>) => {
      state.firstUpdatedAddonIndex = action.payload;
    },
    SET_POPUPSECTION: (state, action: PayloadAction<number>) => {
      state.popUpSection = action.payload;
    },
    SET_POPUPTRIGGER: (state, action: PayloadAction<number>) => {
      state.popUpTrigger = action.payload;
    },
    SET_TOPLEVELCATEGORY: (state, action: PayloadAction<string>) => {
      state.topLevelCategory = action.payload;
    },
    SET_CHECKOUTTRIGGER: (state, action: PayloadAction<number>) => {
      state.checkoutTrigger = action.payload;
    },
    SET_HOLDTRIGGER: (state, action: PayloadAction<number>) => {
      state.holdTrigger = action.payload;
    },
    SET_PRINTTRIGGER: (state, action: PayloadAction<number>) => {
      state.printTrigger = action.payload;
    },
    SET_ARROWKEYLEVEL: (state, action: PayloadAction<number>) => {
      state.arrowKeyLevel = action.payload;
    },
    INCREMENT_COUNTER: (state) => {
      state.counter = state.counter + 1;
    },
  },
});

export const {
  SET_SIZES_UPDATES,
  SET_ITEMS_STATES_DATA,
  SET_TO_INITIAL_ITEMS_STATE,
  SET_UPDATEDADDONITEMINDEX,
  SET_COMINGFROM,
  SET_ARROWKEYLEVEL,
  SET_ITERATOR,
  SET_ITERATOR2,
  SET_ITERATOR3,
  SET_TRIGGER,
  SET_TRIGGER2,
  SET_POPUPITERATOR,
  SET_POPUPSECTION,
  SET_POPUPTRIGGER,
  SET_TOPLEVELCATEGORY,
  SET_CHECKOUTTRIGGER,
  SET_HOLDTRIGGER,
  SET_PRINTTRIGGER,
  INCREMENT_COUNTER,
  SET_FLAVOURS_AND_TOPPINGS_OPEN
} = itemsStates.actions;
export default itemsStates.reducer;
