import { ExpenseObject } from "../../Interfaces/IExpenseObject";

export const sumExpenses = (objs: ExpenseObject[]) => {
  let sum: number = 0;
  for (let obj of objs) {
    sum += obj.amount;
  }
  return sum;
};

export const objSum = (
  objs: {
    count: number;
    totalOrderAmount: number;
  }[]
) => {
  let sum: number = 0;
  for (let obj of objs) {
    sum += obj.totalOrderAmount;
  }
  return sum;
};

export const objSum2 = (
  objs: {
    count: number;
    totalProductValue: number;
  }[]
) => {
  let sum: number = 0;
  for (let obj of objs) {
    sum += obj.totalProductValue;
  }
  return sum;
};

export const objQuantity = (
  objs: {
    count: number;
  }[]
) => {
  let quantity: number = 0;
  for (let obj of objs) {
    quantity += obj.count;
  }
  return quantity;
};
