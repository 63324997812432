import { Modal } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@mui/styles";
import { RootState } from "..";
import {
  SET_ADD_DISCOUNT_POPUP_PAGE,
  SET_PORT,
  SET_SETTINGS_POPUP,
} from "../Features/rightBarHeader";
import { SET_MANUAL_DISCOUNT } from "../Features/cart";
import axios from "axios";
import { BaseService } from "../assets/BaseService";

const useStyles = makeStyles({
  inputRoot: {
    "& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button":
      {
        "-webkit-appearance": "none",
        margin: 0,
      },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
});

const AddDiscountPopupPage = () => {
  const rightBarHeader = useSelector((state: RootState) => state.header);
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const dispatch = useDispatch();

  const [pin, setPin] = useState("");
  const manualDiscount = useSelector(
    (state: RootState) => state.cart.manualDiscount
  );
  const [enterPinPopup, setEnterPinPopup] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [discountSuccessfulPrompt, setDiscountSuccessfulPrompt] =
    useState(false);
  const [invalidPinPrompt, setInvalidPinPrompt] = useState(false);
  const token = localStorage.getItem("access_token");

  const pinValidation = async () => {
    await BaseService.post("/dynamic/get", {
      collection: "employee",
      filter: {
        secretKey: `${pin}`,
      },
      select: {
        isDiscountAllowed: 1,
      },
    }).then((res) => {
      console.log(res.data.data);
      if (res.data.data[0]) {
        if (res.data.data[0].isDiscountAllowed) {
          setDiscountSuccessfulPrompt(true);
          dispatch(SET_MANUAL_DISCOUNT(discount));
        } else {
          setInvalidPinPrompt(true);
        }
      } else {
        setInvalidPinPrompt(true);
      }
    });
  };
  return (
    <div>
      <Modal
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          minWidth: 300,
          border: 0,
        }}
        disableAutoFocus={true}
        open={rightBarHeader.addDiscountPopupPage}
        onClose={() => {
          dispatch(SET_ADD_DISCOUNT_POPUP_PAGE(false));
        }}
      >
        <div
          className={`bg-[${
            !darkMode
              ? pos_config?.popup_color
              : pos_config?.popup_color_darkmode
          }] z-9999 p-[32px] mt-[100px] rounded-[12px] flex-col items-center justify-between border-none w-[450px] mx-auto select-none`}
        >
          <div
            className={`text-[${
              !darkMode
                ? pos_config?.popup_text_color
                : pos_config?.popup_text_color_darkmode
            }] text-[24px] font-[700] text-center flex items-center justify-center`}
          >
            <div>Discount: Rs</div>

            <div className="border-2 border-black p-[5px] mr-[5px] w-[100px] rounded-[10px]">
              <input
                defaultValue={manualDiscount}
                autoFocus
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                  }
                }}
                type="number"
                className={`bg-[${
                  !darkMode
                    ? pos_config?.popup_color
                    : pos_config?.popup_color_darkmode
                }] w-[80px] focus:outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setDiscount(0);
                  } else {
                    setDiscount(Number(e.target.value));
                  }
                }}
              />
            </div>
          </div>
          <div className="flex flex-row justify-around pt-[20px]">
            <div
              className={`cursor-pointer w-[165px] h-[46px] rounded-[62px] flex items-center justify-center bg-[${
                !darkMode
                  ? pos_config?.popup_secondary_button_color
                  : pos_config?.popup_secondary_button_color_darkmode
              }] text-[${
                !darkMode
                  ? pos_config?.popup_secondary_button_text_color
                  : pos_config?.popup_secondary_button_text_color_darkmode
              }] text-[16px] font-[700]`}
              onClick={() => {
                dispatch(SET_ADD_DISCOUNT_POPUP_PAGE(false));
              }}
            >
              Cancel
            </div>
            <div
              className={`cursor-pointer w-[165px] h-[46px] rounded-[62px] flex items-center justify-center bg-[${
                !darkMode
                  ? pos_config?.popup_primary_button_color
                  : pos_config?.popup_primary_button_color_darkmode
              }] text-[${
                !darkMode
                  ? pos_config?.popup_primary_button_text_color
                  : pos_config?.popup_primary_button_text_color_darkmode
              }] text-[16px] font-[700]`}
              onClick={() => {
                setEnterPinPopup(true);
              }}
            >
              Yes
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          minWidth: 400,
          border: 0,
        }}
        disableAutoFocus={true}
        open={enterPinPopup}
        onClose={() => setEnterPinPopup(false)}
      >
        <div
          className={`bg-[${
            !darkMode
              ? pos_config?.popup_color
              : pos_config?.popup_color_darkmode
          }] p-[32px] mt-[100px] rounded-[12px] flex-col items-center justify-center border-none w-[450px] mx-auto select-none`}
        >
          <div
            className={`text-[${
              !darkMode
                ? pos_config?.popup_text_color
                : pos_config?.popup_text_color_darkmode
            }] text-[24px] font-[700] text-center flex items-center justify-center`}
          >
            <div>Enter Pin:</div>

            <div className="border-2 border-black p-[5px] mr-[5px] w-[150px] rounded-[10px]">
              <input
                type="password"
                autoFocus
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                  }
                }}
                className={`bg-[${
                  !darkMode
                    ? pos_config?.popup_color
                    : pos_config?.popup_color_darkmode
                }] w-[80px] focus:outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                onChange={(e) => {
                  setPin(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="flex flex-row justify-around pt-[20px]">
            <div
              className={`cursor-pointer w-[165px] h-[46px] rounded-[62px] flex items-center justify-center bg-[${
                !darkMode
                  ? pos_config?.popup_secondary_button_color
                  : pos_config?.popup_secondary_button_color_darkmode
              }] text-[${
                !darkMode
                  ? pos_config?.popup_secondary_button_text_color
                  : pos_config?.popup_secondary_button_text_color_darkmode
              }] text-[16px] font-[700]`}
              onClick={() => {
                setEnterPinPopup(false);
              }}
            >
              Cancel
            </div>
            <div
              className={`cursor-pointer w-[165px] h-[46px] rounded-[62px] flex items-center justify-center bg-[${
                !darkMode
                  ? pos_config?.popup_primary_button_color
                  : pos_config?.popup_primary_button_color_darkmode
              }] text-[${
                !darkMode
                  ? pos_config?.popup_primary_button_text_color
                  : pos_config?.popup_primary_button_text_color_darkmode
              }] text-[16px] font-[700]`}
              onClick={() => {
                pinValidation();
              }}
            >
              Proceed
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          minWidth: 300,
          border: 0,
        }}
        disableAutoFocus={true}
        open={discountSuccessfulPrompt}
        onClose={() => {
          setDiscountSuccessfulPrompt(false);
          setPin("");
          setEnterPinPopup(false);
          dispatch(SET_ADD_DISCOUNT_POPUP_PAGE(false));
        }}
      >
        <div className="bg-[#FFFFFF] p-[32px] mt-[100px] rounded-[12px] flex-col items-center justify-between border-none w-[450px] mx-auto select-none">
          <div className="text-[#000000] text-[24px] font-[700] text-center">
            Discount Applied Successfully
          </div>
          <div className="flex flex-row justify-around pt-[20px]">
            <div
              onClick={() => {
                setDiscountSuccessfulPrompt(false);
                setPin("");
                setEnterPinPopup(false);
                dispatch(SET_ADD_DISCOUNT_POPUP_PAGE(false));
              }}
              className="cursor-pointer w-[165px] h-[46px] rounded-[62px] flex items-center justify-center bg-[#EF233C] text-[#FFFFFF] text-[16px] font-[700]"
            >
              OK
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          minWidth: 300,
          border: 0,
        }}
        disableAutoFocus={true}
        open={invalidPinPrompt}
        onClose={() => {
          setInvalidPinPrompt(false);
          setPin("");
        }}
      >
        <div className="bg-[#FFFFFF] p-[32px] mt-[100px] rounded-[12px] flex-col items-center justify-between border-none w-[450px] mx-auto select-none">
          <div className="text-[#000000] text-[24px] font-[700] text-center">
            Invalid Pin Entered
          </div>
          <div className="flex flex-row justify-around pt-[20px]">
            <div
              onClick={() => {
                setInvalidPinPrompt(false);
                setPin("");
              }}
              className="cursor-pointer w-[165px] h-[46px] rounded-[62px] flex items-center justify-center bg-[#EF233C] text-[#FFFFFF] text-[16px] font-[700]"
            >
              OK
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddDiscountPopupPage;
