import React, { useState } from "react";
import Items from "./Items";
import { useDispatch, useSelector } from "react-redux";
import { BiDrink } from "react-icons/bi";
import { RootState } from "../..";
import { Modal } from "@mui/material";
import {
  SET_ITEMID,
  SET_PROMPTBUTTON,
  SET_SENDREQUEST,
} from "../../Features/rightBarHeader";
import {
  SET_ARROWKEYLEVEL,
  SET_ITERATOR,
  SET_TOPLEVELCATEGORY,
  SET_TO_INITIAL_ITEMS_STATE,
} from "../../Features/ItemsStates";
import OrderShortcut from "../Shortcuts Manager/OrderShortcut";
import CustomerPopup from "./Customers/CustomerPopup";

const Category = () => {
  const dispatch = useDispatch();
  const pos_config = useSelector((state: RootState) => state.leftHeader.pos_config);
  const [itemID, setItemID] = useState(null);
  const orderMenu = useSelector((state: RootState) => state.orderMenu);
  const rightBarHeader = useSelector((state: RootState) => state.header);
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const item = useSelector((state: RootState) => state.itemStates);
  const showCustomerPopup = useSelector((state: RootState) => state.cart.showCustomerPopup);

  const onChangeFunction = (e: any) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    setItemID(onlyNums);
  };

  const handleKeyDown = async (e: any) => {
    if (e.key === "Enter") {
      dispatch(SET_PROMPTBUTTON(false));
      dispatch(SET_ITEMID(itemID));
      dispatch(SET_SENDREQUEST(1));
      setItemID(null);
    }
  };

  return (
    <div id="category-div" className="px-4 py-3 sm:px-6 md:px-8 lg:px-10 xl:px-12">
      <OrderShortcut />
      {showCustomerPopup && <CustomerPopup/>}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7 gap-4 mt-4">
        {orderMenu.value.map(
          (items: { Name: string; notForSale: boolean }, i: number) =>
            !items.notForSale && (
              <button
                key={i}
                className={`
                  p-4 rounded-lg flex flex-col items-center justify-center
                  transition-all duration-300 ease-in-out
                  ${
                    item.iterator === i
                      ? darkMode
                        ? `bg-[${pos_config?.category_button_hover_color_darkmode}] text-[${pos_config?.category_button_hover_text_color_darkmode}]`
                        : `bg-[${pos_config?.category_button_hover_color}] text-[${pos_config?.category_button_hover_text_color}]`
                      : darkMode
                      ? `bg-[${pos_config?.category_button_color_darkmode}] text-[${pos_config?.category_button_text_color_darkmode}]`
                      : `bg-[${pos_config?.category_button_color}] text-[${pos_config?.category_button_text_color}]`
                  }
                  hover:shadow-md hover:scale-105
                `}
                onClick={() => {
                  dispatch(SET_ITERATOR(i));
                  dispatch(SET_ARROWKEYLEVEL(0));
                  dispatch(SET_TOPLEVELCATEGORY(items.Name));
                  dispatch(SET_TO_INITIAL_ITEMS_STATE());
                }}
              >
                {/* <BiDrink className="text-2xl mb-2" /> */}
                <span className="font-[800] text-[18px]">{items.Name}</span>
              </button>
            )
        )}
      </div>
      <div className="mt-6">
        {item.topLevelCategory === "" ? (
          <Items
            data={orderMenu.value[0].MenuItemsList.map((obj: any) => ({
              _id: obj._id,
              ID: obj.ID,
              Name: obj.Name,
              Discount: obj.Discount,
              DiscountedPrice: obj.DiscountedPrice,
              MenuSizesList: obj.MenuSizesList,
            }))}
            category={orderMenu.value[0].Name}
          />
        ) : (
          orderMenu.value.map(
            (items: any, i: number) =>
              items.Name === item.topLevelCategory && (
                <Items
                  key={i}
                  data={items.MenuItemsList.map((obj: any) => ({
                    _id: obj._id,
                    ID: obj.ID,
                    Name: obj.Name,
                    Discount: obj.Discount,
                    DiscountedPrice: obj.DiscountedPrice,
                    MenuSizesList: obj.MenuSizesList,
                  }))}
                  category={items.Name}
                />
              )
          )
        )}
      </div>
      <Modal
        open={rightBarHeader.promptButton}
        onClose={() => dispatch(SET_PROMPTBUTTON(false))}
        className="flex items-center justify-center"
      >
        <div className="bg-white p-8 rounded-lg shadow-xl w-96 max-w-full">
          <h2 className="text-2xl font-bold mb-4 text-center">Search by Item Id</h2>
          <input
            value={itemID === null ? undefined : itemID}
            onChange={onChangeFunction}
            onKeyDown={handleKeyDown}
            placeholder="Enter Item Id #...."
            className="w-full px-4 py-2 border-2 border-red-400 rounded-full focus:outline-none focus:ring-2 focus:ring-red-300"
            type="text"
            autoFocus
          />
        </div>
      </Modal>
    </div>
  );
};

export default Category;
