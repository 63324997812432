import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";
import { IProductRecipe, Recipe } from "../Interfaces/IProductRecipe";
import { IOrderConsumptionW } from "../Interfaces/IOrderConsumptionW";

export interface OrderConsumptionType {
  product: IProductRecipe | null;
  orderConsumption: IOrderConsumptionW;
}

const initialState: OrderConsumptionType = {
  product: null,
  orderConsumption: {
    orderID: "",
    products: [],
  },
};

const orderConsumptionSlice = createSlice({
  name: "OrderConsumption",
  initialState,
  reducers: {
    SET_ORDER_CONSUMPTION: (
      state,
      action: PayloadAction<IOrderConsumptionW>
    ) => {
      state.orderConsumption = action.payload;
      console.log("order consumption set: ", state.orderConsumption);
    },
    SET_PRODUCT_CONSUMPTION_DETAILS: (
      state,
      action: PayloadAction<IProductRecipe>
    ) => {
      state.product = action.payload;
      console.log("PRODUCT RECIPE ADDED", state.product);
      state.product.recipe.map((item: Recipe) => {
        // Create a key that combines the product and consumedProduct
        const key = state.product?.product.label + item.recipeProduct.label;
        console.log("Product Recipe key = ", key);
        const existingItem = state.orderConsumption?.products.find(
          (product) => product.product + product.consumedProduct === key
        );

        if (existingItem) {
          // If an item with the same key exists, increase its quantity
          existingItem.quantity += item.quantity;
        } else {
          console.log("Product recipe in else");
          // If no matching item exists, push a new item
          state.orderConsumption.products.push({
            product: state.product?.product.label || "",
            consumedProduct: item.recipeProduct.label,
            quantity: item.quantity,
            unit: item.units.label,
          });
        }
      });
      console.log("Product Order Consumed: ", current(state.orderConsumption));
      state.product = null;
    },
    DELETE_PRODUCT_CONSUMPTION_DETAIL: (
      state,
      action: PayloadAction<string>
    ) => {
      const stringToMatch = action.payload;

      // Use the filter method to create a new array with objects that don't match the string
      state.orderConsumption.products = state.orderConsumption.products.filter(
        (item) => item.product !== stringToMatch
      );
      console.log(
        "Product Order Consumed after delete: ",
        current(state.orderConsumption)
      );
    },
    INITIALIZE_PRODUCT_CONSUMPTION_DETAIL: (state) => {
      state.product = null;
      state.orderConsumption = {
        orderID: "",
        products: [],
      };
      console.log("INITITALIZED PRODUCT ORDER CONSUMPTION", state.product);
    },
  },
});

export const {
  SET_PRODUCT_CONSUMPTION_DETAILS,
  DELETE_PRODUCT_CONSUMPTION_DETAIL,
  INITIALIZE_PRODUCT_CONSUMPTION_DETAIL,
  SET_ORDER_CONSUMPTION,
} = orderConsumptionSlice.actions;
export default orderConsumptionSlice.reducer;
