import { stat } from "fs";
import React, { useEffect, useRef, useState } from "react";
import { HiArrowLongRight } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import {
  HOLD_ORDER,
  INITIALIZE_ORDER_DATA,
  INITIALIZE_TABLE_AREA,
  PLACE_ORDER,
  SET_AMOUNT_NOT_REACHED_PROMPT,
  SET_CASH_AMOUNT,
  SET_CASH_PAYMENT_POPUP,
  SET_CHECKOUT_POPUP,
  SET_CURRENT_TABLE,
  SET_IS_ORDER_READY,
  SET_MANUAL_DISCOUNT,
  SET_ORDER_DETAILS,
  SET_ORDER_JSON,
  SET_RETURN_SALE,
  SET_SELECTED_ORDER,
  SET_VOUCHER,
  SET_VOUCHER_DISCOUNT,
  SUSPEND_ORDER,
} from "../../Features/cart";
import axios from "axios";
import PrintInvoice from "./PrintInvoice";
import { RootState } from "../..";
import rightBarHeader, {
  SET_ISCHECKOUT,
  SET_PRINTERPOPUP,
  SET_SELECTEDHEADER,
  SET_VISUAL_HEADER,
} from "../../Features/rightBarHeader";
import { Modal } from "@mui/material";
import { useIsFirstRender } from "usehooks-ts";
import CashPaymentPopup from "../Payment/CashPaymentPopup";
import { SET_CUSTOMER_DATA } from "../../Features/customer";
import { BaseService, createNewBaseService } from "../../assets/BaseService";
import { Root2 } from "../../Interfaces/IOrderData";
import { OrderDetails, Orderdaum } from "../../Interfaces/IOrderDetails";
import { INITIALIZE_PRODUCT_CONSUMPTION_DETAIL } from "../../Features/OrderConsumption";
import PromoBar from "./PromoBar";
import PaymentOptions from "./PaymentOptions";
import CreditPopup from "../Payment/CreditPopup";
import CardPopup from "../Payment/CardPopup";
import JointPopup from "../Payment/JointPopup";
import { SET_FLAVOURS_AND_TOPPINGS_OPEN } from "../../Features/ItemsStates";

const CheckoutBar = () => {
  const [openHold, setOpenHold] = useState(false);
  const [openSuspend, setOpenSuspend] = useState(false);
  const [emptyCartError, setEmptyCartError] = useState(false);
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const cart = useSelector((state: RootState) => state.cart);
  const leftHeader = useSelector((state: RootState) => state.leftHeader);
  const [noTableError, setNoTableError] = useState(false);
  const [noCustomerInformationError, setNoCustomerInformationError] =
    useState<boolean>(false);
  const orderConsumption = useSelector(
    (state: RootState) => state.orderConsumption
  );
  const price = useSelector((state: RootState) => state.cart.cartTotal);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const printButton = useSelector(
    (state: RootState) => state.header.printerPopUp
  );
  const isFirst = useIsFirstRender();
  const rightBarHeader = useSelector((state: RootState) => state.header);
  const dispatch = useDispatch();
  const itemStates = useSelector((state: RootState) => state.itemStates);
  const db = useSelector((state: RootState) => state.database.db);
  const slug = useSelector((state: RootState) => state.leftHeader.slug);
  const [returnSalesPopup, setReturnSalesPopup] = useState(false);
  const port = useSelector((state: RootState) => state.header.port);
  const customer = useSelector((state: RootState) => state.customer);
  const token = localStorage.getItem("access_token");
  const payment = useSelector((state: RootState) => state.cart.payment);
  const NewBaseService = createNewBaseService(pos_config);
  const color = () => {
    if (
      // cart.selectedOrder &&
      // (cart.ordersPlaced.currentOrder[cart.selectedOrder - 1].isSuspended ||
      //   cart.ordersPlaced.currentOrder[cart.selectedOrder - 1].isDispatched ||
      //   cart.ordersPlaced.currentOrder[cart.selectedOrder - 1].isReturned)
      cart.selectedOrderCode &&
      (cart.selectedOrderStatus === "DISPATCHED" ||
        cart.selectedOrderStatus === "RETURNED" ||
        cart.selectedOrderStatus === "SUSPENDED")
    ) {
      return "bg-[#878787] text-white";
    } else {
      return !darkMode
        ? `bg-[var(--primary-button-color)] text-[var(--primary-button-text-color)]`
        : `bg-[var(--primary-button-color-darkmode)] text-[var(--primary-button-text-color-darkmode)]`;
    }
  };

  useEffect(() => {
    if (!isFirst) {
      // checkoutFunction();
      const order: OrderDetails = createOrderJson("dispatched", "Served");
      dispatch(SET_ORDER_JSON(order));
      dispatch(SET_CHECKOUT_POPUP(true));
    }
  }, [itemStates.checkoutTrigger]);

  useEffect(() => {
    if (!isFirst) {
      holdFunction();
    }
  }, [itemStates.holdTrigger]);

  useEffect(() => {
    if (!isFirst) {
      dispatch(SET_ISCHECKOUT(false));
      dispatch(SET_PRINTERPOPUP(true));
    }
  }, [itemStates.printTrigger]);

  //------------read data from port logic start
  const [isWaiting, setIsWaiting] = useState(true);
  const isWaitingRef = useRef(isWaiting);
  const [isSuccessfulCardTransaction, setIsSuccessfulCardTransaction] =
    useState(false);
  const isSuccessfulCardTransactionRef = useRef(isSuccessfulCardTransaction);
  const [unsuccessfulCardTransaction, setUnsuccessfulCardTransaction] =
    useState<boolean>(false);
  const [endMarker, setEndMarker] = useState(false);
  const endMarkerRef = useRef(endMarker);
  useEffect(() => {
    endMarkerRef.current = endMarker;
  }, [endMarker]);
  const [holdKeyListener, setHoldKeyListener] = useState<
    ((e: KeyboardEvent) => void) | null
  >(null);
  const [suspendKeyListener, setSuspendKeyListener] = useState<
    ((e: KeyboardEvent) => void) | null
  >(null);
  const [checkoutModalOpen, setCheckoutModalOpen] = useState<boolean>(false);

  useEffect(() => {
    isWaitingRef.current = isWaiting;
    // console.log("is waiting effect", isWaiting, isWaitingRef.current);
  }, [isWaiting]);

  useEffect(() => {
    isSuccessfulCardTransactionRef.current = isSuccessfulCardTransaction;
    // console.log(
    //   "is waiting effect",
    //   isSuccessfulCardTransaction,
    //   isSuccessfulCardTransactionRef.current
    // );
  }, [isSuccessfulCardTransaction]);

  useEffect(() => {
    dispatch(SET_FLAVOURS_AND_TOPPINGS_OPEN(true));
    return () => {
      dispatch(SET_FLAVOURS_AND_TOPPINGS_OPEN(false));
    };
  }, []);

  useEffect(() => {
    let reader: any;
    const readData = async () => {
      endMarkerRef.current = endMarker;
      let portdata = ""; // Initialize portdata

      if (port) {
        reader = port.readable.getReader();
        //const reader = rightBarHeader.reader;
        const decoder = new TextDecoder("utf-8");
        // console.log("Port Function Connected");

        while (true) {
          //console.log(endMarkerRef.current);
          try {
            const { value, done } = await reader.read();
            if (done) {
              // console.log("breaked");
              break;
            }
            const decodedValue = decoder.decode(value);
            // console.log(decodedValue);

            if (endMarkerRef.current) {
              portdata += decodedValue; // Append data to portdata
              // console.log("appending to port data");
            }
            if (decodedValue.includes("END") && endMarkerRef.current) {
              // console.log("processing port data", portdata);
              setEndMarker(false);
              processPortData(portdata); // Process the accumulated data
              portdata = ""; // Reset portdata for the next set of data
              setIsWaiting(false);
            }
          } catch (error) {
            console.error("Error reading from serial port:", error);
            break;
          }
        }
      }
    };

    const processPortData = (portdata: any) => {
      // Remove the header and footer lines from the string
      const dataSubstring = portdata.substring(
        portdata.indexOf("\n") + 1,
        portdata.lastIndexOf("\n")
      );

      // Split the substring into individual lines
      const lines = dataSubstring.split("\n");

      // Create an empty object
      const jsonData: Record<string, string> = {};

      // Process each line and create key-value pairs in the object
      lines.forEach((line: any) => {
        const [key, value] = line.split("=");
        jsonData[key] = value;
      });

      // Convert the object to JSON
      const jsonString = JSON.stringify(jsonData, null, 2);

      // console.log(jsonString);
      const finalJSON = JSON.parse(jsonString);
      for (const key in finalJSON) {
        if (Object.prototype.hasOwnProperty.call(finalJSON, key)) {
          finalJSON[key] = finalJSON[key].replace(/\r/g, "");
        }
      }
      // console.log("finalJSON", finalJSON);
      const responseValue = finalJSON["RESPONSECODE"];
      // console.log("Response Value:", responseValue);
      if (responseValue.includes("000")) {
        // console.log("includes 000");
        setIsSuccessfulCardTransaction(true);
        // setUnsuccessfulCardTransaction(false);
      } else {
        setIsSuccessfulCardTransaction(false);
        // setUnsuccessfulCardTransaction(true);
      }
    };

    readData();
    const disconnect = async () => {
      // if (reader) {
      //   console.log("reader disconnect");
      //   await reader.cancel();
      //   await reader.releaseLock();
      // }
    };
    return () => {
      // console.log("checkout dismount");
      disconnect();
    };
  }, [port]);

  const handleHoldKeys = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      const order: OrderDetails = createOrderJson("hold", "Pending");
      dispatch(SET_ORDER_JSON(order));
      if (cart.selectedOrderCode) {
        NewBaseService.post(
          `/dynamic/update`,
          {
            collection: "orders",
            filter: {
              id: cart.selectedOrderCode,
            },
            data: order,
          },
          {}
        ).then((res) => {
          console.log("Order API response on Update", res);
          const orderGet: Root2 = res.data.data;
          orderConsumptionUpdation(orderGet.code);
        });
      } else {
        NewBaseService.post(
          `/dynamic/create`,
          {
            collection: "orders",
            data: order,
          },
          {}
        ).then((res) => {
          // console.log("Order API response on hold", res);
          const orderGet: Root2 = res.data.data;
          orderConsumptionUpdation(orderGet.code);
        });
      }
      setOpenHold(false);
      dispatch(INITIALIZE_ORDER_DATA());
      dispatch(SET_SELECTED_ORDER(null));
      dispatch(INITIALIZE_PRODUCT_CONSUMPTION_DETAIL());
      leftHeader.isDineIn && dispatch(SET_SELECTEDHEADER("Dine-in"));
      dispatch(SET_VOUCHER(""));
      dispatch(SET_MANUAL_DISCOUNT(0));
      dispatch(SET_VOUCHER_DISCOUNT(0));
    } else if (e.key === "Escape") {
      setOpenHold(false);
      dispatch(SET_SELECTEDHEADER("Pickup"));
    }
  };

  const handleSuspendKeys = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      const order: OrderDetails = createOrderJson("suspended", "Suspended");
      dispatch(SET_ORDER_JSON(order));
      if (cart.selectedOrderCode) {
        NewBaseService.post(
          `/dynamic/update`,
          {
            collection: "orders",
            filter: {
              id: cart.selectedOrderCode,
            },
            data: order,
          },
          {}
        ).then((res) => {
          // console.log("Order API response on Update", res);
        });
      }
      dispatch(INITIALIZE_ORDER_DATA());
      dispatch(SET_SELECTED_ORDER(null));
      dispatch(INITIALIZE_PRODUCT_CONSUMPTION_DETAIL());
      setOpenSuspend(false);
      leftHeader.isDineIn && dispatch(SET_SELECTEDHEADER("Dine-in"));
      dispatch(SET_VOUCHER(""));
      dispatch(SET_MANUAL_DISCOUNT(0));
      dispatch(SET_VOUCHER_DISCOUNT(0));
    } else if (e.key === "Escape") {
      setOpenSuspend(false);
      dispatch(SET_SELECTEDHEADER("Pickup"));
    }
  };

  useEffect(() => {
    if (openHold) {
      const listener = (e: KeyboardEvent) => handleHoldKeys(e);
      window.addEventListener("keydown", listener);
      setHoldKeyListener(() => listener);
    } else if (holdKeyListener) {
      window.removeEventListener("keydown", holdKeyListener);
      setHoldKeyListener(null);
    }
  }, [openHold]);

  useEffect(() => {
    if (openSuspend) {
      const listener = (e: KeyboardEvent) => handleSuspendKeys(e);
      window.addEventListener("keydown", listener);
      setSuspendKeyListener(() => listener);
    } else if (suspendKeyListener) {
      window.removeEventListener("keydown", suspendKeyListener);
      setSuspendKeyListener(null);
    }
  }, [openSuspend]);

  const waitForValue = async () => {
    return new Promise<void>((resolve) => {
      const checkValue = () => {
        if (isWaitingRef.current) {
          setTimeout(checkValue, 100); // Retry after 100 milliseconds
        } else {
          resolve();
        }
      };

      checkValue();
    });
  };
  const CardPaymentFunc1 = async () => {
    var textData = price.toString().concat("00");
    var selection = "0200";
    var num = textData.padStart(12, "0");
    var data_send = selection + num;
    if (port) {
      try {
        setEndMarker(true);
        // console.log("endMarkerRef.current value", endMarkerRef.current);
        const encoder = new TextEncoder();
        const dataArrayBuffer = encoder.encode(data_send);

        const writer = port.writable.getWriter();
        // console.log("writer", writer);
        await writer.write(dataArrayBuffer);
        writer.releaseLock();
        // console.log("Data sent:", data_send, "data array bufer", writer);

        // while (isWaitingRef.current) {
        //   console.log("is Waiting", isWaitingRef.current);
        // }
        await waitForValue();
        setIsWaiting(true);
        return isSuccessfulCardTransactionRef.current;
      } catch (error) {
        console.error("Error sending data:", error);
        return false;
      }
    } else {
      // console.log("Serial port not connected");
      return false;
    }
  };
  //-----------------read data stops

  const holdFunction = async () => {
    if (cart.order.length !== 0) {
      if (cart.currentTable === 0 && leftHeader.selectedHeader === "Dine-in") {
        setNoTableError(true);
      } else {
        if (
          !(
            cart.selectedOrderCode &&
            (cart.selectedOrderStatus === "DISPATCHED" ||
              cart.selectedOrderStatus === "RETURNED" ||
              cart.selectedOrderStatus === "SUSPENDED")
          )
        ) {
          setOpenHold(true);
        }
      }
    } else {
      setEmptyCartError(true);
    }
  };

  const checkoutFunction = async () => {
    const order: OrderDetails = createOrderJson("dispatched", "Served");
    dispatch(SET_ORDER_JSON(order));
    if (
      pos_config.is_customer_information_mandatory ||
      leftHeader.selectedHeader === "Delivery"
    ) {
      console.log("customer data->>>>", customer.data);
      if (customer.data === undefined) {
        setNoCustomerInformationError(true);
        return;
      }
    }
    if (cart.selectedOrder) {
      if (cart.ordersPlaced.currentOrder[cart.selectedOrder - 1].isDispatched) {
        setReturnSalesPopup(true);
      }
    }
    if (cart.order.length !== 0) {
      if (cart.currentTable === 0 && leftHeader.selectedHeader === "Dine-in") {
        setNoTableError(true);
      } else {
        if (
          !(
            cart.selectedOrderCode &&
            (cart.selectedOrderStatus === "DISPATCHED" ||
              cart.selectedOrderStatus === "RETURNED" ||
              cart.selectedOrderStatus === "SUSPENDED")
          )
        ) {
          let res: boolean;
          if (
            cart.payment.isOnlineBankPayment ||
            cart.payment.isCreditPayment
          ) {
            res = true;
          } else {
            if (cart.payment.isCardPayment) {
              res = await CardPaymentFunc1();
              console.log("ress", res);
              setIsSuccessfulCardTransaction(false);
              res
                ? setUnsuccessfulCardTransaction(false)
                : setUnsuccessfulCardTransaction(true);
            } else {
              dispatch(SET_CASH_AMOUNT());
              dispatch(SET_CASH_PAYMENT_POPUP(true));
              res = false;
            }
          }

          console.log("RES VALUE ->>> ", res);

          dispatch(SET_IS_ORDER_READY(cart.isOrderReady + 1));

          dispatch(
            PLACE_ORDER({
              orderType: leftHeader.selectedHeader,
              isDineIn: leftHeader.isDineIn,
            })
          );
          if (res) {
            dispatch(SET_ISCHECKOUT(true));
            dispatch(SET_PRINTERPOPUP(true));
            if (navigator.onLine) {
              console.log("order-details", cart.orderDetails);
              await axios
                .post(
                  "https://consoleservice.cloubuzz.com/Services.aspx?Method=ProcessOrder&Phone=0321222222222&Slug=broadway-pizza",
                  cart.orderDetails,
                  {
                    headers: {
                      slug: slug,
                    },
                  }
                )
                .then((res) => {
                  console.log(res);
                });
            } else {
              // @ts-ignore
              db.put({
                _id: new Date().toISOString(),
                order: cart.orderDetails,
              });
              // @ts-ignore
              db.allDocs({ include_docs: true }).then((res: any) =>
                console.log(res)
              );
            }
            dispatch(SET_SELECTED_ORDER(null));
            dispatch(SET_SELECTEDHEADER("Pickup"));
          }
        }
      }
    } else {
      setEmptyCartError(true);
    }
  };

  const orderConsumptionUpdation = async (orderCode: string) => {
    await BaseService.post(`/dynamic/get`, {
      collection: "order-consumption",
      filter: {
        orderID: orderCode,
      },
    }).then(async (res) => {
      // console.log("order consumption res check", res.data);
      if (res.data.data.length === 0) {
        // console.log("order consumption create scenes");
        await BaseService.post(`/dynamic/create`, {
          collection: "order-consumption",
          data: {
            orderID: orderCode,
            products: orderConsumption.orderConsumption.products,
          },
        });
      } else {
        // console.log("order consumption update scenes");
        await BaseService.patch(`/dynamic/update`, {
          collection: "order-consumption",
          filter: {
            orderID: orderCode,
          },
          data: {
            products: orderConsumption.orderConsumption.products,
          },
        });
      }
    });
  };

  const orderPlacingFunction = async () => {
    if (
      cart.payment.isOnlineBankPayment === true ||
      cart.payment.isCreditPayment
    ) {
      dispatch(SET_ISCHECKOUT(true));
      dispatch(SET_PRINTERPOPUP(true));
      if (navigator.onLine) {
        if (cart.selectedOrderCode) {
          console.log("Cart Order JSON", cart.orderJson);
          await NewBaseService.post(
            `/dynamic/update`,
            {
              collection: "orders",
              filter: {
                id: cart.selectedOrderCode,
              },
              data: cart.orderJson,
            },
            {}
          ).then(async (res) => {
            const orderGet: Root2 = res.data.data;
            orderConsumptionUpdation(orderGet.code);
          });
        } else {
          await NewBaseService.post(
            `/dynamic/create`,
            {
              collection: "orders",
              data: cart.orderJson,
            },
            {}
          ).then((res) => {
            const orderGet: Root2 = res.data.data;
            orderConsumptionUpdation(orderGet.code);
          });
        }
      }
      dispatch(SET_SELECTED_ORDER(null));
      dispatch(SET_CUSTOMER_DATA(undefined));
      pos_config && pos_config.customer_tab_as_default_tab
        ? dispatch(SET_SELECTEDHEADER("Delivery"))
        : dispatch(SET_SELECTEDHEADER("Dine-in"));
      dispatch(SET_VISUAL_HEADER("Dine-in"));
    }
  };

  useEffect(() => {
    if (!isFirst) {
      orderPlacingFunction();
    }
  }, [cart.isOrderReady]);

  const createOrderJson = (status: string, kdsStatus: string): OrderDetails => {
    return {
      branch: cart.loginData.branchName,
      code: "",
      platform: "POS",
      name: cart.name,
      status: status,
      orderType: leftHeader.selectedHeader,
      area: "Test area",
      tax: cart.taxAmount,
      voucher: cart.voucher,
      discountamount: cart.discount,
      orderamount: cart.cartTotal,
      paymenttype: cart.paymentMethod,
      paymentRemarks: cart.creditorName || "",
      totalamount: cart.discountedAndTaxedAmount,
      taxamount: cart.taxAmount,
      deliverycharges: 0,
      kdsStatus: kdsStatus,
      customerFullName: customer.data ? customer.data.Name : null,
      customerPhone: customer.data ? customer.data.Mobile : null,
      customerAddress: customer.data ? customer.data.Address : null,
      customerCity: customer.data ? customer.data.City : null,
      customerLatitude: null,
      customerLongitude: null,
      customerOtherAddresses: customer.data
        ? customer.data.otherAddresses
        : null,
      orderdata: cart.order.map((obj: any) => ({
        productName: obj.itemName,
        refCode: obj.itemID,
        categoryName: obj.categoryName,
        itemStatus: obj.itemStatus || "Pending",
        discountGiven: 0,
        minimumDelivery: obj.MinimumDelivery,
        itemImage:
          "https://admin.dunkinpizza.com.pk/Images/ProductImages/13-inch-thumbnails.jpg",
        options: obj.addOns.map((temp: any) => ({
          OptionID: temp.optionID,
          OptionName: temp.name,
          OptionGroupName: temp.objGroupName,
          Price: temp.price,
          Quantity: temp.quantity,
        })),
        quantity: obj.quantity,
        price: obj.total,
        totalProductPrice: obj.orderTotal,
        SizeID: obj.sizeID,
      })),
      Remarks: "",
      category: "",
    };
  };

  const cashPaymentCheckoutFunction = async () => {
    if (payment.cashPaid - payment.cashAmount >= 0) {
      if (navigator.onLine) {
        console.log("order-details", cart.orderJson);
        dispatch(SET_ISCHECKOUT(true));
        dispatch(SET_PRINTERPOPUP(true));

        if (cart.selectedOrderCode) {
          console.log("Cart Order Json in Cash Payment", cart.orderJson);
          await NewBaseService.post(
            `/dynamic/update`,
            {
              collection: "orders",
              filter: {
                id: cart.selectedOrderCode,
              },
              data: cart.orderJson,
            },
            {}
          ).then((res) => {
            console.log("Order API response on Update", res);
            const orderGet: Root2 = res.data.data;
            orderConsumptionUpdation(orderGet.code);
          });
        } else {
          await NewBaseService.post(
            `/dynamic/create`,
            {
              collection: "orders",
              data: cart.orderJson,
            },
            {}
          ).then((res) => {
            console.log("Order API response after creating order request", res);
            const orderGet: Root2 = res.data.data;
            orderConsumptionUpdation(orderGet.code);
          });
        }
      } else {
        // // @ts-ignore
        // db.put({
        //   _id: new Date().toISOString(),
        //   order: cart.orderDetails,
        // });
        // // @ts-ignore
        // db.allDocs({ include_docs: true }).then((res: any) =>
        //   console.log(res)
        // );
      }

      dispatch(SET_CASH_PAYMENT_POPUP(false));
      dispatch(SET_CHECKOUT_POPUP(false));
      pos_config && pos_config.customer_tab_as_default_tab
        ? dispatch(SET_SELECTEDHEADER("Delivery"))
        : dispatch(SET_SELECTEDHEADER("Dine-in"));
      dispatch(SET_VISUAL_HEADER("Dine-in"));
    } else {
      console.log("WRONG PAYMENT ");
      dispatch(SET_AMOUNT_NOT_REACHED_PROMPT(true));
    }
  };

  return (
    <div className="h-[30px] lg:h-[35px] xl:h-[45px] flex flex-col gap-4 mt-2">
      <div className="flex flex-row gap-2">
        <div
          className={`
        ${cart.selectedOrder &&
              cart.ordersPlaced.currentOrder[cart.selectedOrder - 1].isSuspended
              ? "bg-[#878787] text-white" //bg-[#878787] text-white
              : !darkMode
                ? "bg-[#E8E8E8] text-[#878787] hover:text-white hover:bg-[#111827]"
                : "bg-[#111827] text-[#878787] hover:text-black hover:bg-[#E8E8E8] border-[#2b2c37]"
            }
        cursor-pointer border-[1px] font-[500] flex justify-center items-center
        px-[10px] text-[10px] rounded-[8px]
        lg:px-[15px] lg:text-[12px] 
        xl:px-[30px] xl:text-[16px]
        `}
          onClick={() => {
            const order: OrderDetails = createOrderJson("dispatched", "Served");
            dispatch(SET_ORDER_JSON(order));
            dispatch(SET_CHECKOUT_POPUP(true));
          }}
        >
          <div>
            {cart.selectedOrderCode
              ? cart.selectedOrderStatus === "DISPATCHED"
                ? "Return"
                : "Checkout"
              : "Checkout"}
          </div>
          <div className="px-[10px] text-[20px]">
            <HiArrowLongRight />
          </div>
        </div>
        <div
          className="font-[700] text-[#FF0000]
        text-[14px]
          lg:text-[24px]
          xl:text-[28px]
          text-center
        "
        >
          Rs.{cart.discountedAndTaxedAmount}
        </div>
      </div>
      <div className="flex flex-row gap-2">
        <div
          className={`
          ${darkMode
              ? `hover:bg-[var(--primary-button-hover-color-darkmode)] hover:text-[var(--primary-button-hover-text-color-darkmode)] border-[#2b2c37] bg-[var(--primary-button-color-darkmode)] text-[var(--primary-button-text-color-darkmode)]`
              : `hover:bg-[var(--primary-button-hover-color)] hover:text-[var(--primary-button-hover-text-color)] border-[#E8E8E8] bg-[var(--primary-button-color)] text-[var(--primary-button-text-color)]`
            }
         cursor-pointer border-[1px] font-[500] flex justify-center items-center 
        px-[30px] text-[18px] rounded-[8px] py-[4px]
        `}
          onClick={holdFunction}
        >
          Save
        </div>
        <div
          className={`
          ${darkMode
              ? `hover:bg-[var(--primary-button-hover-color-darkmode)] hover:text-[var(--primary-button-hover-text-color-darkmode)] border-[#2b2c37]`
              : `hover:bg-[var(--primary-button-hover-color)] hover:text-[var(--primary-button-hover-text-color)] border-[#E8E8E8]`
            }
        cursor-pointer border-[1px] font-[500] flex justify-center items-center bg-red-600 text-white
        px-[30px] text-[18px] rounded-[8px] py-[4px] 
         `}
          onClick={async () => {
            // cart.selectedOrder &&
            // (cart.ordersPlaced.currentOrder[cart.selectedOrder - 1].isSuspended ||
            //   cart.ordersPlaced.currentOrder[cart.selectedOrder - 1].isDispatched)
            //   ? setOpenSuspend(false)
            //   : setOpenSuspend(true);
            if (cart.order.length !== 0) {
              if (
                cart.currentTable === 0 &&
                leftHeader.selectedHeader === "Dine-in"
              ) {
                setNoTableError(true);
              } else {
                if (
                  !(
                    cart.selectedOrderCode &&
                    (cart.selectedOrderStatus === "DISPATCHED" ||
                      cart.selectedOrderStatus === "RETURNED" ||
                      cart.selectedOrderStatus === "SUSPENDED")
                  )
                ) {
                  setOpenSuspend(true);
                }
              }
            } else {
              setEmptyCartError(true);
            }
          }}
        >
          Suspend
        </div>
        <div
          onClick={() => {
            dispatch(SET_ISCHECKOUT(false));
            dispatch(SET_PRINTERPOPUP(true));
          }}
          className={`
          ${!darkMode
              ? `bg-[var(--primary-button-color)] text-[var(--primary-button-text-color)]`
              : `bg-[var(--primary-button-color-darkmode)] text-[var(--primary-button-text-color-darkmode)]`
            }
          ${darkMode
              ? `hover:bg-[var(--primary-button-hover-color-darkmode)] hover:text-[var(--primary-button-hover-text-color-darkmode)] border-[#2b2c37]`
              : `hover:bg-[var(--primary-button-hover-color)] hover:text-[var(--primary-button-hover-text-color)] border-[#E8E8E8]`
            }
          cursor-pointer border-[1px] font-[500] flex justify-center items-center 
          px-[30px] text-[18px] rounded-[8px] py-[4px]
        `}
        >
          Print
        </div>
      </div>

      {printButton && (
        <div
          id="invoice"
          className="fixed top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2 w-full bg-white z-10 h-[100%] overflow-scroll"
        >
          <PrintInvoice />
        </div>
      )}

      <Modal
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          minWidth: 300,
          border: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        disableAutoFocus={true}
        open={payment.checkoutPopup}
        onClose={() => dispatch(SET_CHECKOUT_POPUP(false))}
      >
        <div className={`bg-[var(--popup-color${darkMode ? "-darkmode" : ""})] p-2 md:p-8 rounded-lg flex flex-col h-[80%] w-[400px] md:w-[800px]`}>
          <div className="flex-grow">
            <h2 className={`text-[var(--popup-text-color${darkMode ? "-darkmode" : ""})] text-[2.5rem] md:text-[3.5rem] font-bold py-4 text-center`}>
              Rs.{cart.discountedAndTaxedAmount}
            </h2>
            <div className="mb-1">
              <div className="">
                <PaymentOptions />
              </div>
              <div className="mt-2">
                {payment.creditPopup && payment.isCreditPayment && (
                  <CreditPopup />
                )}
                {payment.cardPopup && payment.isCardPayment && <CardPopup />}
                {payment.jointPopup && payment.isJointPayment && <JointPopup />}
                {payment.cashPaymentPopup && <CashPaymentPopup />}
              </div>
            </div>
          </div>
          <div className="mt-auto pt-4 border-t flex flex-col sm:flex-row gap-4 items-center justify-between">
            <PromoBar />
            <button
              onClick={payment.cashPaymentPopup ? cashPaymentCheckoutFunction : checkoutFunction}
              className={`cursor-pointer w-full sm:w-auto px-4 py-2 rounded-[62px] bg-[var(--popup-secondary-button-color${darkMode ? "-darkmode" : ""})] text-[var(--popup-secondary-button-text-color${darkMode ? "-darkmode" : ""})] text-[14px] sm:text-[16px] font-[700]`}
            >
              Confirm Checkout
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          p: 3,
          minWidth: 300,
          border: 0,
        }}
        disableAutoFocus={true}
        open={openHold}
        onClose={() => setOpenHold(false)}
      >
        <div
          className={`bg-[var(--popup-color${darkMode ? "-darkmode" : ""})] z-50 p-6 rounded-xl flex flex-col items-center justify-between border-none w-full max-w-md mx-auto select-none`}
        >
          <div
            className={`text-[var(--popup-text-color${darkMode ? "-darkmode" : ""})] text-lg sm:text-xl font-bold text-center`}
          >
            Are you sure? You want to hold the order
          </div>
          <div className="flex flex-col sm:flex-row justify-between space-y-4 sm:space-y-0 sm:space-x-4 mt-6 w-full">
            <button
              className={`cursor-pointer w-full h-12 rounded-full flex items-center justify-center bg-[${!darkMode
                ? pos_config?.popup_primary_button_color
                : pos_config?.popup_primary_button_color_darkmode
                }] text-[${!darkMode
                  ? pos_config?.popup_primary_button_text_color
                  : pos_config?.popup_primary_button_text_color_darkmode
                }] text-base sm:text-lg font-bold hover:bg-opacity-90 transition-all`}
              onClick={async () => {
                const order: OrderDetails = createOrderJson("hold", "Pending");
                dispatch(SET_ORDER_JSON(order));

                if (cart.selectedOrderCode) {
                  await NewBaseService.post(
                    `/dynamic/update`,
                    {
                      collection: "orders",
                      filter: {
                        id: cart.selectedOrderCode,
                      },
                      data: order,
                    },
                    {}
                  ).then((res) => {
                    const orderGet: Root2 = res.data.data;
                    orderConsumptionUpdation(orderGet.code);
                  });
                } else {
                  await NewBaseService.post(
                    `/dynamic/create`,
                    {
                      collection: "orders",
                      data: order,
                    },
                    {}
                  ).then((res) => {
                    const orderGet: Root2 = res.data.data;
                    orderConsumptionUpdation(orderGet.code);
                  });
                }

                setOpenHold(false);
                dispatch(INITIALIZE_ORDER_DATA());
                dispatch(SET_SELECTED_ORDER(null));
                dispatch(INITIALIZE_PRODUCT_CONSUMPTION_DETAIL());
                leftHeader.isDineIn && dispatch(SET_SELECTEDHEADER("Dine-in"));
                dispatch(SET_VOUCHER(""));
                dispatch(SET_MANUAL_DISCOUNT(0));
                dispatch(SET_VOUCHER_DISCOUNT(0));
              }}
            >
              Yes
            </button>
            <button
              className={`cursor-pointer w-full h-12 rounded-full flex items-center justify-center bg-[${!darkMode
                ? pos_config?.popup_secondary_button_color
                : pos_config?.popup_secondary_button_color_darkmode
                }] text-[${!darkMode
                  ? pos_config?.popup_secondary_button_text_color
                  : pos_config?.popup_secondary_button_text_color_darkmode
                }] text-base sm:text-lg font-bold hover:bg-opacity-90 transition-all`}
              onClick={() => {
                setOpenHold(false);
                leftHeader.isDineIn
                  ? dispatch(SET_SELECTEDHEADER("Dine-in"))
                  : dispatch(SET_SELECTEDHEADER("Pickup"));
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          p: 3,
          minWidth: 300,
          border: 0,
        }}
        disableAutoFocus={true}
        open={openSuspend}
        onClose={() => setOpenSuspend(false)}
      >
        <div
          className={`bg-[${!darkMode
            ? pos_config?.popup_color
            : pos_config?.popup_color_darkmode
            }] z-50 p-6 rounded-xl flex flex-col items-center justify-between border-none w-full max-w-md mx-auto select-none`}
        >
          <div
            className={`text-[${!darkMode
              ? pos_config?.popup_text_color
              : pos_config?.popup_text_color_darkmode
              }] text-lg sm:text-xl font-bold text-center`}
          >
            Are you sure? You want to suspend the order
          </div>
          <div className="flex flex-col sm:flex-row justify-between space-y-4 sm:space-y-0 sm:space-x-4 mt-6 w-full">
            <button
              className={`cursor-pointer w-full h-12 rounded-full flex items-center justify-center bg-[${!darkMode
                ? pos_config?.popup_primary_button_color
                : pos_config?.popup_primary_button_color_darkmode
                }] text-[${!darkMode
                  ? pos_config?.popup_primary_button_text_color
                  : pos_config?.popup_primary_button_text_color_darkmode
                }] text-base sm:text-lg font-bold hover:bg-opacity-90 transition-all`}
              onClick={async () => {
                const order: OrderDetails = createOrderJson("suspended", "Suspended");
                dispatch(SET_ORDER_JSON(order));

                if (cart.selectedOrderCode) {
                  await NewBaseService.post(
                    `/dynamic/update`,
                    {
                      collection: "orders",
                      filter: {
                        id: cart.selectedOrderCode,
                      },
                      data: order,
                    },
                    {}
                  ).then((res) => { });
                }

                dispatch(INITIALIZE_ORDER_DATA());
                dispatch(SET_SELECTED_ORDER(null));
                dispatch(INITIALIZE_PRODUCT_CONSUMPTION_DETAIL());
                setOpenSuspend(false);
                leftHeader.isDineIn && dispatch(SET_SELECTEDHEADER("Dine-in"));
                dispatch(SET_VOUCHER(""));
                dispatch(SET_MANUAL_DISCOUNT(0));
                dispatch(SET_VOUCHER_DISCOUNT(0));
              }}
            >
              Yes
            </button>
            <button
              className={`cursor-pointer w-full h-12 rounded-full flex items-center justify-center bg-[${!darkMode
                ? pos_config?.popup_secondary_button_color
                : pos_config?.popup_secondary_button_color_darkmode
                }] text-[${!darkMode
                  ? pos_config?.popup_secondary_button_text_color
                  : pos_config?.popup_secondary_button_text_color_darkmode
                }] text-base sm:text-lg font-bold hover:bg-opacity-90 transition-all`}
              onClick={() => {
                setOpenSuspend(false);
                leftHeader.isDineIn
                  ? dispatch(SET_SELECTEDHEADER("Dine-in"))
                  : dispatch(SET_SELECTEDHEADER("Pickup"));
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          minWidth: 300,
          border: 0,
        }}
        disableAutoFocus={true}
        open={emptyCartError}
        onClose={() => setEmptyCartError(false)}
      >
        <div
          className={`bg-[${!darkMode
            ? pos_config?.popup_color
            : pos_config?.popup_color_darkmode
            }] p-[5vw] rounded-[12px] flex-col items-center justify-between border-none w-[90%] max-w-[450px] mx-auto select-none`}
        >
          <div
            className={`text-[${!darkMode
              ? pos_config?.popup_text_color
              : pos_config?.popup_text_color_darkmode
              }] text-[5vw] sm:text-[24px] font-[700] text-center`}
          >
            No Item Selected
          </div>
          <div className="flex flex-row justify-around pt-[5vw]">
            <div
              onClick={() => {
                setEmptyCartError(false);
              }}
              className={`cursor-pointer w-[40vw] sm:w-[165px] h-[12vw] sm:h-[46px] rounded-[62px] flex items-center justify-center bg-[${!darkMode
                ? pos_config?.popup_secondary_button_color
                : pos_config?.popup_secondary_button_color_darkmode
                }] text-[${!darkMode
                  ? pos_config?.popup_secondary_button_text_color
                  : pos_config?.popup_secondary_button_text_color_darkmode
                }] text-[4vw] sm:text-[16px] font-[700]`}
            >
              Cancel
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          minWidth: 300,
          border: 0,
        }}
        disableAutoFocus={true}
        open={noTableError}
        onClose={() => setNoTableError(false)}
      >
        <div
          className={`bg-[${!darkMode
            ? pos_config?.popup_color
            : pos_config?.popup_color_darkmode
            }] p-[5vw] rounded-[12px] flex-col items-center justify-between border-none w-[90%] max-w-[450px] mx-auto select-none`}
        >
          <div
            className={`text-[${!darkMode
              ? pos_config?.popup_text_color
              : pos_config?.popup_text_color_darkmode
              }] text-[5vw] sm:text-[24px] font-[700] text-center`}
          >
            No Table Selected
          </div>
          <div className="flex flex-row justify-around pt-[5vw]">
            <div
              onClick={() => {
                setNoTableError(false);
              }}
              className={`cursor-pointer w-[40vw] sm:w-[165px] h-[12vw] sm:h-[46px] rounded-[62px] flex items-center justify-center bg-[${!darkMode
                ? pos_config?.popup_secondary_button_color
                : pos_config?.popup_secondary_button_color_darkmode
                }] text-[${!darkMode
                  ? pos_config?.popup_secondary_button_text_color
                  : pos_config?.popup_secondary_button_text_color_darkmode
                }] text-[4vw] sm:text-[16px] font-[700]`}
            >
              Cancel
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          minWidth: 300,
          border: 0,
        }}
        disableAutoFocus={true}
        open={noCustomerInformationError}
        onClose={() => setNoCustomerInformationError(false)}
      >
        <div
          className={`bg-[${!darkMode
            ? pos_config?.popup_color
            : pos_config?.popup_color_darkmode
            }] p-[5vw] rounded-[12px] flex-col items-center justify-between border-none w-[90%] max-w-[450px] mx-auto select-none`}
        >
          <div
            className={`text-[${!darkMode
              ? pos_config?.popup_text_color
              : pos_config?.popup_text_color_darkmode
              }] text-[5vw] sm:text-[24px] font-[700] text-center`}
          >
            Customer Information not found!
          </div>
          <div className="flex flex-row justify-around pt-[5vw]">
            <div
              onClick={() => {
                setNoCustomerInformationError(false);
              }}
              className={`cursor-pointer w-[40vw] sm:w-[165px] h-[12vw] sm:h-[46px] rounded-[62px] flex items-center justify-center bg-[${!darkMode
                ? pos_config?.popup_secondary_button_color
                : pos_config?.popup_secondary_button_color_darkmode
                }] text-[${!darkMode
                  ? pos_config?.popup_secondary_button_text_color
                  : pos_config?.popup_secondary_button_text_color_darkmode
                }] text-[4vw] sm:text-[16px] font-[700]`}
            >
              Cancel
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          minWidth: 300,
          border: 0,
        }}
        disableAutoFocus={true}
        open={returnSalesPopup}
        onClose={() => setReturnSalesPopup(false)}
      >
        <div
          className={`bg-[${!darkMode
            ? pos_config?.popup_color
            : pos_config?.popup_color_darkmode
            }] p-[5vw] rounded-[12px] flex-col items-center justify-between border-none w-[90%] max-w-[450px] mx-auto select-none`}
        >
          <div className="text-[#000000] text-[5vw] sm:text-[24px] font-[700] text-center flex flex-col sm:flex-row items-center justify-around">
            <div
              onClick={() => {
                dispatch(SET_RETURN_SALE());
                dispatch(SET_SELECTED_ORDER(null));
                dispatch(INITIALIZE_PRODUCT_CONSUMPTION_DETAIL());
                leftHeader.isDineIn && dispatch(SET_CURRENT_TABLE(0));
                leftHeader.isDineIn
                  ? dispatch(SET_SELECTEDHEADER("Dine-in"))
                  : dispatch(SET_SELECTEDHEADER("Pickup"));
                dispatch(INITIALIZE_ORDER_DATA());
                setReturnSalesPopup(false);
              }}
              className={`cursor-pointer w-[40vw] sm:w-[165px] h-[12vw] sm:h-[46px] rounded-[62px] flex items-center justify-center bg-[${!darkMode
                ? pos_config?.popup_primary_button_color
                : pos_config?.popup_primary_button_color_darkmode
                }] text-[${!darkMode
                  ? pos_config?.popup_primary_button_text_color
                  : pos_config?.popup_primary_button_text_color_darkmode
                }] text-[4vw] sm:text-[16px] font-[700] mb-4 sm:mb-0`}
            >
              With Inventory
            </div>
            <div
              onClick={() => {
                dispatch(SET_RETURN_SALE());
                dispatch(SET_SELECTED_ORDER(null));
                dispatch(INITIALIZE_PRODUCT_CONSUMPTION_DETAIL());
                leftHeader.isDineIn && dispatch(SET_CURRENT_TABLE(0));
                leftHeader.isDineIn
                  ? dispatch(SET_SELECTEDHEADER("Dine-in"))
                  : dispatch(SET_SELECTEDHEADER("Pickup"));
                dispatch(INITIALIZE_ORDER_DATA());
                setReturnSalesPopup(false);
              }}
              className={`cursor-pointer w-[40vw] sm:w-[165px] h-[12vw] sm:h-[46px] rounded-[62px] flex items-center justify-center bg-[${!darkMode
                ? pos_config?.popup_primary_button_color
                : pos_config?.popup_primary_button_color_darkmode
                }] text-[${!darkMode
                  ? pos_config?.popup_primary_button_text_color
                  : pos_config?.popup_primary_button_text_color_darkmode
                }] text-[4vw] sm:text-[16px] font-[700] mb-4 sm:mb-0`}
            >
              Without Inventory
            </div>
          </div>
          <div className="flex flex-row justify-around pt-[5vh]">
            <div
              onClick={() => {
                setReturnSalesPopup(false);
              }}
              className={`cursor-pointer w-[40vw] sm:w-[165px] h-[12vw] sm:h-[46px] rounded-[62px] flex items-center justify-center bg-[${!darkMode
                ? pos_config?.popup_secondary_button_color
                : pos_config?.popup_secondary_button_color_darkmode
                }] text-[${!darkMode
                  ? pos_config?.popup_secondary_button_text_color
                  : pos_config?.popup_secondary_button_text_color_darkmode
                }] text-[4vw] sm:text-[16px] font-[700]`}
            >
              Cancel
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          minWidth: 300,
          border: 0,
        }}
        disableAutoFocus={true}
        open={noCustomerInformationError}
        onClose={() => setNoCustomerInformationError(false)}
      >
        <div
          className={`bg-[${!darkMode
            ? pos_config?.popup_color
            : pos_config?.popup_color_darkmode
            }] p-[5vw] rounded-[12px] flex-col items-center justify-between border-none w-[90%] max-w-[450px] mx-auto select-none`}
        >
          <div
            className={`text-[${!darkMode
              ? pos_config?.popup_text_color
              : pos_config?.popup_text_color_darkmode
              }] text-[6vw] sm:text-[24px] font-[700] text-center`}
          >
            Invalid Customer Information
          </div>
          <div className="flex flex-row justify-around pt-[5vh]">
            <div
              onClick={() => {
                setNoCustomerInformationError(false);
              }}
              className={`cursor-pointer w-[40vw] sm:w-[165px] h-[12vw] sm:h-[46px] rounded-[62px] flex items-center justify-center bg-[${!darkMode
                ? pos_config?.popup_secondary_button_color
                : pos_config?.popup_secondary_button_color_darkmode
                }] text-[${!darkMode
                  ? pos_config?.popup_secondary_button_text_color
                  : pos_config?.popup_secondary_button_text_color_darkmode
                }] text-[4vw] sm:text-[16px] font-[700]`}
            >
              Cancel
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          minWidth: 300,
          border: 0,
        }}
        disableAutoFocus={true}
        open={unsuccessfulCardTransaction}
        onClose={() => setUnsuccessfulCardTransaction(false)}
      >
        <div className="bg-[#FFFFFF] p-[8vw] mt-[20vh] rounded-[12px] flex-col items-center justify-between border-none w-[90%] max-w-[450px] mx-auto select-none">
          <div className="text-[#000000] text-[6vw] sm:text-[24px] font-[700] text-center">
            Card Payment Was Unsuccessful
          </div>
          <div className="flex flex-row justify-around pt-[5vh]">
            <div
              onClick={() => {
                setUnsuccessfulCardTransaction(false);
              }}
              className="cursor-pointer w-[40vw] sm:w-[165px] h-[12vw] sm:h-[46px] rounded-[62px] flex items-center justify-center bg-[#EF233C] text-[#FFFFFF] text-[4vw] sm:text-[16px] font-[700]"
            >
              OK
            </div>
          </div>
        </div>
      </Modal>
      {/* <CashPaymentPopup /> */}
    </div>
  );
};
export default CheckoutBar;
