import React from "react";
import OrderHeader from "./OrderHeader";
import OrderBody from "./OrderBody";

const OrderPage = () => {
  return (
    <div className="">
      <OrderHeader />
      <OrderBody />
    </div>
  );
};

export default OrderPage;
