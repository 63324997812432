import React, { useEffect, useRef, useState } from "react";
import { Modal, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../..";
import { SET_CUSTOMER_DATA } from "../../../Features/customer";
import { SET_SELECTEDHEADER } from "../../../Features/rightBarHeader";
import { SET_SHOW_CUSTOMER_POPUP } from "../../../Features/cart";
import { SET_FLAVOURS_AND_TOPPINGS_OPEN } from "../../../Features/ItemsStates";

const CustomerPopup = () => {
  const dispatch = useDispatch();
  const showCustomerPopup = useSelector(
    (state: RootState) => state.cart.showCustomerPopup
  );
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const customerData = useSelector((state: RootState) => state.customer);

  const [customerName, setCustomerName] = useState<string>(
    customerData.data?.Name || ""
  );
  const [customerPhone, setCustomerPhone] = useState<string>(
    customerData.data?.Mobile || ""
  );
  const [customerEmail, setCustomerEmail] = useState<string>(
    customerData.data?.Email || ""
  );

  const nameInputRef = useRef<HTMLInputElement>(null);

  const handleCustomerSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(
      SET_CUSTOMER_DATA({
        Name: customerName,
        Mobile: customerPhone || "",
        Email: customerEmail || "",
        ID: "",
        IsActive: "",
        Address: "",
        City: "",
        MemberPoints: "",
        customerRating: 0,
        otherAddresses: [],
        orders: [],
        complains: [],
        Created: "",
        updatedAt: "",
        Orders: "",
        __v: 0,
      })
    );
    dispatch(SET_SHOW_CUSTOMER_POPUP(false));
    dispatch(SET_SELECTEDHEADER("Pickup"));
  };

  useEffect(() => {
    if (showCustomerPopup) {
      // Use a timeout to delay focusing
      const timer = setTimeout(() => {
        nameInputRef.current?.focus();
      }, 100); // Adjust the delay if necessary
      return () => clearTimeout(timer); // Clean up the timeout if the component unmounts or the popup closes
    }
  }, [showCustomerPopup]);

  useEffect(() => {
    dispatch(SET_FLAVOURS_AND_TOPPINGS_OPEN(true));
    return () => {
      dispatch(SET_FLAVOURS_AND_TOPPINGS_OPEN(false));
    };
  }, [dispatch]);

  const handleKeyDown = async (e: any) => {
    if (e.key === " ") {
      e.preventDefault();
      setCustomerName((prevValue) => prevValue + " ");
      return;
    }
  };

  return (
    <Modal
      open={showCustomerPopup}
      onClose={() => dispatch(SET_SHOW_CUSTOMER_POPUP(false))}
    >
      <div
        className={`bg-[${
          !darkMode ? pos_config?.popup_color : pos_config?.popup_color_darkmode
        }] p-[8vw] sm:p-[6vw] md:p-[4vw] lg:p-[32px] mt-[10vh] rounded-[12px] flex-col items-center justify-between border-none w-[90vw] sm:w-[80vw] md:w-[60vw] lg:w-[450px] mx-auto select-none`}
      >
        <div
          className={`text-[${
            !darkMode
              ? pos_config?.popup_text_color
              : pos_config?.popup_text_color_darkmode
          }] text-[5vw] sm:text-[4vw] md:text-[3vw] lg:text-[24px] font-[700] text-center mb-[5vw] sm:mb-[4vw] md:mb-[3vw] lg:mb-[20px]`}
        >
          Enter Customer Details
        </div>
        <form onSubmit={handleCustomerSubmit} className="space-y-4">
          <TextField
            fullWidth
            label="Customer Name"
            value={customerName}
            onKeyDown={handleKeyDown}
            onChange={(e) => setCustomerName(e.target.value)}
            inputRef={nameInputRef}
            InputLabelProps={{
              sx: darkMode
                ? { color: `${pos_config?.popup_text_color_darkmode}` }
                : { color: `${pos_config?.popup_text_color}` },
            }}
            InputProps={{
              sx: darkMode
                ? { color: `${pos_config?.popup_text_color_darkmode}` }
                : { color: `${pos_config?.popup_text_color}` },
            }}
            required
          />
          <TextField
            fullWidth
            label="Phone Number"
            value={customerPhone}
            onChange={(e) => setCustomerPhone(e.target.value)}
            InputLabelProps={{
              sx: darkMode
                ? { color: `${pos_config?.popup_text_color_darkmode}` }
                : { color: `${pos_config?.popup_text_color}` },
            }}
            InputProps={{
              sx: darkMode
                ? { color: `${pos_config?.popup_text_color_darkmode}` }
                : { color: `${pos_config?.popup_text_color}` },
            }}
          />
          <TextField
            fullWidth
            label="Email"
            value={customerEmail}
            onChange={(e) => setCustomerEmail(e.target.value)}
            InputLabelProps={{
              sx: darkMode
                ? { color: `${pos_config?.popup_text_color_darkmode}` }
                : { color: `${pos_config?.popup_text_color}` },
            }}
            InputProps={{
              sx: darkMode
                ? { color: `${pos_config?.popup_text_color_darkmode}` }
                : { color: `${pos_config?.popup_text_color}` },
            }}
          />
          <div className="flex justify-end pt-[5vw] sm:pt-[4vw] md:pt-[3vw] lg:pt-[20px]">
            <button
              type="submit"
              className={`cursor-pointer w-full sm:w-[40vw] md:w-[35vw] lg:w-[165px] h-[12vw] sm:h-[10vw] md:h-[8vw] lg:h-[46px] rounded-[62px] flex items-center justify-center bg-[${
                !darkMode
                  ? pos_config?.popup_secondary_button_color
                  : pos_config?.popup_secondary_button_color_darkmode
              }] text-[${
                !darkMode
                  ? pos_config?.popup_secondary_button_text_color
                  : pos_config?.popup_secondary_button_text_color_darkmode
              }] text-[4vw] sm:text-[3.5vw] md:text-[3vw] lg:text-[16px] font-[700]`}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CustomerPopup;
