import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../..";
import { Option, Orderdaum, Root, Root2 } from "../../../Interfaces/IOrderData";
import Modal from "@mui/material/Modal";
import { debounce } from "lodash";
import { createNewBaseService } from "../../../assets/BaseService";
import {
  SET_CART_ORDER,
  SET_CHECKOUT_POPUP,
  SET_ORDER_JSON,
  SET_SELECTED_ORDER,
} from "../../../Features/cart";
import { AddOn, CartOrder, Value } from "../../../Interfaces/ICartOrder";
import { SET_CUSTOMER_DATA } from "../../../Features/customer";
import { OrderDetails } from "../../../Interfaces/IOrderDetails";

const OrdersList = () => {
  const dispatch = useDispatch();

  const cart = useSelector((state: RootState) => state.cart);
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const branchName = useSelector(
    (state: RootState) => state.cart.loginData.branchName
  );
  const NewBaseService = createNewBaseService(pos_config);
  const leftHeader = useSelector((state: RootState) => state.leftHeader);
  const customer = useSelector((state: RootState) => state.customer);

  const [orders, setOrders] = useState<Root>([]);
  const [searchedOrders, setSearchedOrders] = useState<Root>([]);
  const [openOrderDetailsModal, setOpenOrderDetailsModal] = useState(false);
  const [selectedOrderDetails, setSelectedOrderDetails] =
    useState<Root2 | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [payClicked, setPayClicked] = useState<boolean>(false);

  const ITEMS_PER_PAGE = 20;

  const fetchOrders = useCallback(async () => {
    setIsLoading(true);
    const filter: { branch: string; POSStatus?: string } = {
      branch: branchName,
    };
    if (cart.filteredOrder !== "None") {
      filter.POSStatus = cart.filteredOrder.toLowerCase();
    }

    try {
      const res = await NewBaseService.post(
        "/dynamic/query/get-all-orders-with-details",
        {
          collection: "orders",
          filter: filter,
          skip: (page - 1) * ITEMS_PER_PAGE,
          limit: ITEMS_PER_PAGE,
        }
      );
      const newOrders = res.data.data;
      setOrders((prevOrders) => [...prevOrders, ...newOrders]);
      setSearchedOrders((prevOrders) => [...prevOrders, ...newOrders]);
      setHasMore(newOrders.length === ITEMS_PER_PAGE);
    } catch (err) {
      console.error("Orders get error", err);
    } finally {
      setIsLoading(false);
    }
  }, [branchName, cart.filteredOrder, page]);

  const convertOrderDetailsToCartOrder = (orderDetails: Root2): CartOrder => {
    if (!orderDetails.orderdata || !Array.isArray(orderDetails.orderdata)) {
      return [];
    }
    return orderDetails.orderdata.map((orderItem: Orderdaum) => {
      const value: Value[] =
        orderItem.options?.map((option: Option) => ({
          name: option.OptionName,
          properties: [
            {
              ID: option.OptionID,
              Name: option.OptionGroupName,
              Price: parseFloat(option.Price),
              OriginalPrice: parseFloat(option.Price),
              Discount: 0,
              IsActive: true,
            },
          ],
          price: parseFloat(option.Price),
          isMultiple: false,
        })) || [];

      const addOns: AddOn[] =
        orderItem.options?.map((option: Option) => ({
          name: option.OptionName,
          price: parseFloat(option.Price),
          quantity: parseInt(option.Quantity),
          objGroupName: option.OptionGroupName,
          optionID: option.OptionID,
        })) || [];

      return {
        itemName: orderItem.ItemName,
        value,
        total: parseFloat(orderItem.Price),
        itemPrice: parseFloat(orderItem.Price),
        addOns,
        batchNumber: orderItem.batchNumber,
        quantity: parseInt(orderItem.Quantity),
        categoryName: orderItem.Category,
        MinimumDelivery: orderItem.minimumDelivery,
        sizeID: orderItem.SizeID,
        itemID: orderItem.ItemID,
        orderTotal: parseFloat(orderItem.Price),
        index: 0,
        itemStatus: orderItem.Status,
        isComingFromAPI: true,
      };
    });
  };

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const handleFeedback = (item: any) => {
    console.log(item);
  };

  const createOrderJson = (status: string,kdsStatus:string): OrderDetails => {
    return {
      branch: cart.loginData.branchName,
      code: "",
      platform: "POS",
      name: cart.name,
      status: status,
      orderType: leftHeader.selectedHeader,
      area: "Test area",
      tax: cart.taxAmount,
      voucher: cart.voucher,
      discountamount: cart.discount,
      orderamount: cart.cartTotal,
      paymenttype: cart.paymentMethod,
      paymentRemarks: cart.creditorName || "",
      totalamount: cart.discountedAndTaxedAmount,
      taxamount: cart.taxAmount,
      deliverycharges: 0,
      kdsStatus: "Pending",
      customerFullName: customer.data ? customer.data.Name : null,
      customerPhone: customer.data ? customer.data.Mobile : null,
      customerAddress: customer.data ? customer.data.Address : null,
      customerCity: customer.data ? customer.data.City : null,
      customerLatitude: null,
      customerLongitude: null,
      customerOtherAddresses: customer.data
        ? customer.data.otherAddresses
        : null,
      orderdata: cart.order.map((obj: any) => ({
        productName: obj.itemName,
        refCode: obj.itemID,
        categoryName: obj.categoryName,
        itemStatus: obj.itemStatus || "Pending",
        discountGiven: 0,
        minimumDelivery: obj.MinimumDelivery,
        itemImage:
          "https://admin.dunkinpizza.com.pk/Images/ProductImages/13-inch-thumbnails.jpg",
        options: obj.addOns.map((temp: any) => ({
          OptionID: temp.optionID,
          OptionName: temp.name,
          OptionGroupName: temp.objGroupName,
          Price: temp.price,
          Quantity: temp.quantity,
        })),
        quantity: obj.quantity,
        price: obj.total,
        totalProductPrice: obj.orderTotal,
        SizeID: obj.sizeID,
      })),
      Remarks: "",
      category: "",
    };
  };

  const handlePay = (item: any) => {
    if (item && item.POSStatus === "hold") {
      dispatch(SET_SELECTED_ORDER(item));
      dispatch(
        SET_CUSTOMER_DATA({
          Name: item.CustomerName,
          Mobile: item.CustomerMobile || "",
          Email: item.Email || "",
          ID: item.CustomerID,
          IsActive: "",
          Address: "",
          City: item.City,
          MemberPoints: "",
          customerRating: 0,
          otherAddresses: [],
          orders: [],
          complains: [],
          Created: "",
          updatedAt: "",
          Orders: "",
          __v: 0,
        })
      );
      const convertedOrder = convertOrderDetailsToCartOrder(item);
      dispatch(SET_CART_ORDER(convertedOrder));
      setPayClicked(true);
    }
  };

  useEffect(() => {
    if (payClicked) {
      const order: OrderDetails = createOrderJson("dispatched","Served");
      dispatch(SET_ORDER_JSON(order));
      console.log("Data from the Redux:", cart.orderJson);
      dispatch(SET_CHECKOUT_POPUP(true));
      setPayClicked(false); // Reset payClicked to prevent reopening the popup
    }
  }, [payClicked]);

  const debouncedSearch = useMemo(
    () =>
      debounce((searchTerm: string) => {
        setSearchedOrders(
          orders.filter((item: Root2) =>
            item.code.toString().includes(searchTerm)
          )
        );
      }, 300),
    [orders]
  );

  useEffect(() => {
    debouncedSearch(cart.searchedOrder);
    return () => debouncedSearch.cancel();
  }, [cart.searchedOrder, debouncedSearch]);

  const handleLoadMore = () => {
    if (!isLoading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const OrderItem = React.memo(({ item }: { item: Root2 }) => (
    <div
      className={`${
        darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-800"
      } 
                  border ${darkMode ? "border-gray-700" : "border-gray-200"} 
                  rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 p-4`}
    >
      <div className="flex justify-between items-center mb-3">
        <h3 className={`font-bold text-lg`}>Order ID: #{item.code}</h3>
        <div className="flex items-center space-x-2">
          <span
            className={`px-3 py-1 rounded-full text-xs font-semibold
                          ${
                            item.POSStatus === "dispatched"
                              ? "bg-green-200 text-green-800"
                              : item.POSStatus === "hold"
                              ? "bg-yellow-200 text-yellow-800"
                              : "bg-red-200 text-red-800"
                          }`}
          >
            {item.POSStatus.charAt(0).toUpperCase() + item.POSStatus.slice(1)}
          </span>
          <span className="px-3 py-1 rounded-full text-xs font-semibold bg-blue-200 text-blue-800">
            {item.OrderType}
          </span>
        </div>
      </div>
      <div className={`h-24 overflow-y-auto mb-3 text-sm`}>
        {item.orderdata &&
          item.orderdata.map((obj, i) => (
            <div key={i} className="flex justify-between mb-1">
              <span>{obj.ItemName}</span>
              <span>{obj.Quantity}x</span>
            </div>
          ))}
      </div>
      <div className="flex justify-between items-center mb-3">
        <span className="font-semibold">Total Bill:</span>
        <span className="text-red-500 font-bold">Rs. {item.OrderAmount}</span>
      </div>
      <div className="flex justify-between space-x-2">
        <button
          className={`flex-1 py-2 rounded-md text-center transition-colors duration-300
          ${
            darkMode
              ? `bg-[var(--order-bar-view-details-color-darkmode)] text-[var(--order-bar-view-details-text-color-darkmode)]`
              : `bg-[var(--order-bar-view-details-color)] text-[var(--order-bar-view-details-text-color)]`
          }`}
          onClick={() => {
            setSelectedOrderDetails(item);
            setOpenOrderDetailsModal(true);
          }}
        >
          View
        </button>
        <button
          className={`flex-1 py-2 rounded-md text-center transition-colors duration-300 ${
            item.POSStatus === "hold"
              ? "bg-green-500 text-white"
              : "bg-gray-300 text-gray-500 cursor-not-allowed"
          }`}
          onClick={() => handlePay(item)}
          disabled={item.POSStatus !== "hold"}
        >
          Pay
        </button>
        <button
          className={`flex-1 py-2 rounded-md text-center transition-colors duration-300 bg-blue-500 text-white`}
          onClick={() => handleFeedback(item)}
        >
          Feedback
        </button>
      </div>
    </div>
  ));

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
      {searchedOrders.map((item: Root2) => (
        <OrderItem key={item.ID} item={item} />
      ))}
      {isLoading && (
        <div className={`col-span-full flex justify-center items-center h-64`}>
          <div
            className={`inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] ${
              darkMode ? "text-white" : "text-black"
            }`}
          >
            <span
              className={`!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)] ${
                darkMode ? "text-white dark:text-black" : "text-black"
              }`}
            >
              Loading...
            </span>
          </div>
        </div>
      )}
      {!isLoading && hasMore && (
        <button
          onClick={handleLoadMore}
          className="col-span-full mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Load More
        </button>
      )}
      <OrderDetailsModal
        open={openOrderDetailsModal}
        onClose={() => setOpenOrderDetailsModal(false)}
        orderDetails={selectedOrderDetails}
      />
    </div>
  );
};

const OrderDetailsModal = React.memo(
  ({
    open,
    onClose,
    orderDetails,
  }: {
    open: boolean;
    onClose: () => void;
    orderDetails: Root2 | null;
  }) => {
    const darkMode = useSelector(
      (state: RootState) => state.leftHeader.darkMode
    );

    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="order-details-modal"
        aria-describedby="order-details-modal-description"
      >
        <div
          className={`${
            darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-800"
          } 
                       md:p-6 p-8 rounded-lg shadow-xl max-w-md mx-4 md:mx-auto mt-20 max-h-[80vh] overflow-y-auto`}
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">Order Details</h2>
            <button
              onClick={onClose}
              className={`px-3 py-1 rounded ${
                darkMode
                  ? "bg-gray-700 text-white"
                  : "bg-gray-200 text-gray-800"
              } hover:opacity-80`}
            >
              Close
            </button>
          </div>
          {orderDetails && (
            <>
              <div className="grid grid-cols-2 gap-2 mb-4">
                <DetailItem label="Order ID" value={`#${orderDetails.code}`} />
                <DetailItem label="Order Type" value={orderDetails.OrderType} />
                <DetailItem label="Status" value={orderDetails.POSStatus} />
                <DetailItem
                  label="Payment Type"
                  value={orderDetails.PaymentType}
                />
                <DetailItem
                  label="Tax Amount"
                  value={`Rs. ${orderDetails.TaxAmount}`}
                />
                <DetailItem
                  label="Total Amount"
                  value={`Rs. ${orderDetails.OrderAmount}`}
                />
                <DetailItem
                  label="Customer Name"
                  value={orderDetails.CustomerName}
                />
                <DetailItem
                  label="Customer Phone"
                  value={orderDetails.CustomerMobile}
                />
              </div>
              <h3 className="text-xl font-semibold mb-2">Ordered Items</h3>
              <div className="space-y-2">
                {orderDetails.orderdata &&
                  orderDetails.orderdata.map((item, index) => (
                    <div
                      key={index}
                      className={`p-2 rounded ${
                        darkMode ? "bg-gray-700" : "bg-gray-100"
                      }`}
                    >
                      <div className="flex justify-between font-medium">
                        <span>{item.ItemName}</span>
                        <span>
                          {item.Quantity} x Rs. {item.Price}
                        </span>
                      </div>
                      {item.options && item.options.length > 0 && (
                        <div className="mt-1 pl-4 text-sm">
                          <span className="font-semibold">Options:</span>
                          {item.options.map((option, optionIndex) => (
                            <div
                              key={optionIndex}
                              className="flex justify-between"
                            >
                              <span>{option.OptionName}</span>
                              <span>Rs. {option.Price}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
      </Modal>
    );
  }
);

const DetailItem = React.memo(
  ({ label, value }: { label: string; value: string }) => (
    <div>
      <span className="font-medium">{label}:</span> {value}
    </div>
  )
);

export default OrdersList;
