import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import { BaseService } from "../../assets/BaseService";
import {
  SET_CARD_POPUP,
  SET_CASH_AMOUNT,
  SET_CASH_PAYMENT_POPUP,
  SET_CREDITORS,
  SET_PAYMENT_METHOD,
} from "../../Features/cart";


const PaymentOptions = () => {
  const paymentOptions = ["Cash", "Online", "Credit", "Joint"];
  const cart = useSelector((state: RootState) => state.cart);
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const dispatch = useDispatch();
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>("Cash");

  useEffect(() => {
    BaseService.post(
      "/dynamic/get",
      {
        collection: "taxonomy",
        filter: {
          slug: "creditors",
        },
      },
      {}
    ).then((res) => {
      console.log(
        "creditors",
        res.data.data[0]?.keyValuePair?.map((val: any) => val.value)
      );
      dispatch(
        SET_CREDITORS(
          res.data.data[0]?.keyValuePair?.map((val: any) => val.value)
        )
      );
    });
  }, []);

  useEffect(() => {
    dispatch(SET_PAYMENT_METHOD(selectedPaymentMethod));
    if (selectedPaymentMethod === "Cash") {
      dispatch(SET_CASH_AMOUNT());
      dispatch(SET_CASH_PAYMENT_POPUP(true));
    } else {
      dispatch(SET_CASH_PAYMENT_POPUP(false))
    }
  }, [selectedPaymentMethod])

  return (
    <div className="h-[30px] lg:h-[35px] xl:h-[45px] flex gap-2">
      {paymentOptions.map((item, i) => (
        <div
          key={i}
          className={`${cart.paymentMethod === `${item}`
            ? !darkMode
              ? `bg-[${pos_config?.primary_button_hover_color}] text-[${pos_config?.primary_button_hover_text_color}]`
              : `bg-[${pos_config?.primary_button_hover_color_darkmode}] text-[${pos_config?.primary_button_hover_text_color_darkmode}]`
            : !darkMode
              ? `bg-[${pos_config?.primary_button_color}] text-[${pos_config?.primary_button_text_color}]`
              : `bg-[${pos_config?.primary_button_color_darkmode}] text-[${pos_config?.primary_button_text_color_darkmode}]`
            }
           ${darkMode
              ? `hover:bg-[${pos_config.primary_button_hover_color_darkmode}] hover:text-[${pos_config?.primary_button_hover_text_color_darkmode}] border-[#2b2c37]`
              : `hover:bg-[${pos_config.primary_button_hover_color}] hover:text-[${pos_config?.primary_button_hover_text_color}] border-[#E8E8E8]`
            } 
          cursor-pointer border-[1px] font-[500] flex justify-center items-center
        px-[10px] text-[10px] rounded-[20px]
        lg:px-[15px] lg:text-[12px] lg:rounded-[40px]  
        xl:px-[20px] xl:text-[16px] xl:rounded-[68px]
        `}
          onClick={() => {
            if (
              !(
                cart.selectedOrderStatus === "DISPATCHED" ||
                cart.selectedOrderStatus === "SUSPENDED" ||
                cart.selectedOrderStatus === "RETURNED"
              )
            ) {
              setSelectedPaymentMethod(item);
            }
          }}
        >
          {item}
        </div>
      ))}

    </div>
  );
};


export default PaymentOptions;
