//@ts-nocheck
import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";

interface Header {
  darkMode: boolean;
  selectedHeader: string;
  isDineIn: boolean;
  drawer: boolean;
  slug: string;
  pos_config: PosConfig;
  token: string;
  response_id: string;
  openingClosingLog: boolean;
  shortcuts_config: {
    add_to_cart_popup: string;
    checkout: string;
    print: string;
    find_item_by_id: string;
    select_items_in_popup: string;
    hold_order: string;
    table: string;
  };
  search_field_text: string;
  isLeftBarOnRight: "left" | "right";
}

const initialState: Header = {
  selectedHeader: "",
  isDineIn: true,
  darkMode: false,
  drawer: false,
  slug: "broadway",
  pos_config: {},
  openingClosingLog: false,
  token: "",
  response_id: "",
  shortcuts_config: {},
  search_field_text: "",
  isLeftBarOnRight: "left",
};

interface PosConfig {
  id: string;
  code: string;
  is_active: boolean;
  is_deleted: boolean;
  is_reserved: boolean;
  header_color: string;
  background_color: string;
  cartposition: "left" | "right";
  baseurl: string;
  logo_img: LogoImg[];
  dark_header_color: string;
  logout_button_color: string;
  logout_button_text: string;
  size_price_text_color_darkmode: string;
  size_price_text_color: string;
  primary_button_color: string;
  primary_button_hover_color: string;
  primary_button_text_color: string;
  primary_button_hover_text_color: string;
  primary_button_color_darkmode: string;
  primary_button_hover_color_darkmode: string;
  primary_button_text_color_darkmode: string;
  primary_button_hover_text_color_darkmode: string;
  category_button_color: string;
  order_bar_button_text_color;
  category_button_hover_color: string;
  category_button_color_darkmode: string;
  category_button_hover_color_darkmode: string;
  category_button_text_color: string;
  category_button_text_color_darkmode: string;
  category_button_hover_text_color: string;
  category_button_hover_text_color_darkmode: string;
  item_button_color: string;
  item_button_hover_color: string;
  item_button_color_darkmode: string;
  item_button_hover_color_darkmode: string;
  item_button_text_color: string;
  item_button_text_color_darkmode: string;
  item_button_hover_text_color: string;
  item_button_hover_text_color_darkmode: string;
  size_button_color: string;
  size_button_hover_color: string;
  size_button_color_darkmode: string;
  size_button_hover_color_darkmode: string;
  size_button_text_color: string;
  size_button_text_color_darkmode: string;
  size_button_hover_text_color: string;
  size_button_hover_text_color_darkmode: string;
  table_button_color: string;
  table_button_color_darkmode: string;
  table_button_hover_color: string;
  table_button_hover_color_darkmode: string;
  table_button_text_color: string;
  table_button_text_color_darkmode: string;
  table_button_hover_text_color: string;
  table_button_hover_text_color_darkmode: string;
  order_bar_color: string;
  order_bar_color_darkmode: string;
  order_bar_text_color: string;
  order_bar_text_color_darkmode: string;
  order_bar_menu_text_color: string;
  order_bar_menu_text_color_darkmode: string;
  order_bar_status_color: string;
  order_bar_status_color_darkmode: string;
  order_bar_view_details_color: string;
  order_bar_view_details_color_darkmode: string;
  order_bar_view_details_text_color: string;
  order_bar_view_details_text_color_darkmode: string;
  deals_bar_text_color: string;
  deals_bar_text_color_darkmode: string;
  deals_bar_quanitityxprice_color: string;
  deals_bar_quanitityxprice_color_darkmode: string;
  deals_details_color: string;
  deals_details_color_darkmode: string;
  popup_color: string;
  popup_color_darkmode: string;
  popup_text_color: string;
  popup_text_color_darkmode: string;
  popup_primary_button_color: string;
  popup_primary_button_color_darkmode: string;
  popup_primary_button_text_color: string;
  popup_primary_button_text_color_darkmode: string;
  popup_secondary_button_color: string;
  popup_secondary_button_color_darkmode: string;
  popup_secondary_button_text_color: string;
  popup_secondary_button_text_color_darkmode: string;
  items_price_text_color: string;
  items_price_text_color_darkmode: string;
  sizes_price_text_color: string;
  sizes_price_text_color_darkmode: string;
  addons_button_color: string;
  addons_button_hover_color: string;
  addons_button_color_darkmode: string;
  addons_button_hover_color_darkmode: string;
  addons_button_text_color: string;
  addons_button_text_color_darkmode: string;
  addons_button_hover_text_color: string;
  addons_button_hover_text_color_darkmode: string;
  addons_button_price_text_color: string;
  addons_button_price_text_color_darkmode: string;
  remote_code_id_length: number;
  is_customer_information_mandatory: boolean;
  is_restaurant: string;
  product_ref_code_length: number;
  batch_number_length: number;
  background: string;
  created_at: string;
  updated_at: string;
  v: number;
  enable_open_till_close_till: boolean;
  enable_reservation_module: boolean;
  enable_kitchen_display_module: boolean;
  enable_barcode_printing_module: boolean;
  customer_tab_as_default_tab: boolean;
}

interface LogoImg {
  url: string;
  webkitRelativePath: string;
  lastModified: number;
  name: string;
  size: number;
  type: string;
}

const leftBarHeaderSlice = createSlice({
  name: "leftBarHeader",
  initialState,
  reducers: {
    SET_SELECTED_LEFT_HEADER: (state, action: PayloadAction<string>) => {
      state.selectedHeader = action.payload;
      if (action.payload !== "Dine-in") {
        state.isDineIn = false;
      } else {
        state.isDineIn = true;
      }
    },
    SET_DARK_MODE: (state, action: PayloadAction<string>) => {
      if (action.payload === "Light Mode") {
        state.darkMode = false;
      } else {
        state.darkMode = true;
      }
    },
    SET_LEFT_BAR_POSITION: (state, action: PayloadAction<"left" | "right">) => {
      state.isLeftBarOnRight = action.payload;
    },
    SET_SEARCH_FIELD_TEXT: (state, action: PayloadAction<string>) => {
      state.search_field_text = action.payload;
    },
    SET_DRAWER: (state, action: PayloadAction<boolean>) => {
      state.drawer = action.payload;
    },
    SET_POS_CONFIG: (state, action: PayloadAction<object>) => {
      state.pos_config = action.payload;
    },
    SET_SLUG: (state, action: PayloadAction<string>) => {
      state.slug = action.payload;
    },
    SET_OPENING_CLOSING_LOG: (state, action: PayloadAction<boolean>) => {
      state.openingClosingLog = action.payload;
    },
    SET_TOKEN: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    SET_RESPONSE_ID: (state, action: PayloadAction<string>) => {
      state.response_id = action.payload;
    },
    SET_SHORTCUTS_CONFIG: (state, action: PayloadAction<object>) => {
      state.shortcuts_config = action.payload;
    },
  },
});

export const {
  SET_SELECTED_LEFT_HEADER,
  SET_DARK_MODE,
  SET_SLUG,
  SET_OPENING_CLOSING_LOG,
  SET_DRAWER,
  SET_SEARCH_FIELD_TEXT,
  SET_POS_CONFIG,
  SET_TOKEN,
  SET_RESPONSE_ID,
  SET_SHORTCUTS_CONFIG,
  SET_LEFT_BAR_POSITION,
} = leftBarHeaderSlice.actions;
export default leftBarHeaderSlice.reducer;
