import React from "react";
import DealsBar from "./DealsBar";
import FilterDiv from "./FilterDiv";
import LeftBarHeader from "./LeftBarHeader";
import SearchDiv from "./SearchDiv";
import { useSelector } from "react-redux";
import { RootState } from "../..";
import Footer from "../Footer/Footer";

const LeftBar = () => {
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);

  return (
    <div className="flex w-full h-full flex-col select-none">
      <div
        className={`${
          darkMode ? "bg-[#111827] text-white" : "bg-white text-black"
        } w-[380px] lg:mt-[20px] h-fit lg:w-[345px]  xl:w-[397px]
      `}
      >
        <SearchDiv />
        <FilterDiv />
      </div>
      <div className="flex w-full h-[calc(100%-300px)] lg:h-[calc(100%-300px)] ">
        <DealsBar />
      </div>
      <div className="w-full m-auto">
        <Footer />
      </div>
    </div>
  );
};

export default LeftBar;
