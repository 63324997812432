import React from "react";
import RightBarHeader from "./RightBarHeader";
import Items from "./Items";
import Category from "./Category";
import Size from "./Size";
import { useSelector } from "react-redux";
import { RootState } from "../..";
import Customers from "./Customers/Customers";
import OrderPage from "./Orders/OrderPage";
import TableaArea from "./TableArea/TableaArea";

const RightBar = () => {
  const header = useSelector((state: RootState) => state.header);
  const leftBarHeader = useSelector((state: RootState) => state.leftHeader);

  return (
    <div className="flex flex-col h-[100%] w-full ">
      <div className="flex-shrink-0 h-[75px] md:h-[80px]">
        <RightBarHeader />
      </div>
      <div className="flex-grow overflow-y-auto
      ">
        {header.selectedHeader === "Delivery" && <Customers />}
        {header.selectedHeader === "Pickup" && <Category />}
        {header.selectedHeader === "Orders" && <OrderPage />}
        {header.selectedHeader === "Dine-in" && leftBarHeader.isDineIn && (
          <TableaArea />
        )}
      </div>
    </div>
  );
};

export default RightBar;
