import { useSelector } from "react-redux";
import { RootState } from ".";
import { useEffect } from "react";

const StyleManager: React.FC = () => {
    const pos_config = useSelector((state: RootState) => state.leftHeader.pos_config);

    useEffect(() => {
        document.documentElement.style.setProperty('--table-button-color', pos_config?.table_button_color || '#ffffff');
        document.documentElement.style.setProperty('--table-button-color-darkmode', pos_config?.table_button_color_darkmode || '#2b2c37');
        document.documentElement.style.setProperty('--table-button-text-color', pos_config?.table_button_text_color || '#000000');
        document.documentElement.style.setProperty('--table-button-text-color-darkmode', pos_config?.table_button_text_color_darkmode || '#ffffff');
        document.documentElement.style.setProperty('--order-bar-view-details-color', pos_config?.order_bar_view_details_color || '#ffffff');
        document.documentElement.style.setProperty('--order-bar-view-details-color-darkmode', pos_config?.order_bar_view_details_color_darkmode || '#2b2c37');
        document.documentElement.style.setProperty('--order-bar-view-details-text-color', pos_config?.order_bar_view_details_text_color || '#000000');
        document.documentElement.style.setProperty('--order-bar-view-details-text-color-darkmode', pos_config?.order_bar_view_details_text_color_darkmode || '#ffffff');
        document.documentElement.style.setProperty('--primary-button-color', pos_config?.primary_button_color || '#ffffff');
        document.documentElement.style.setProperty('--primary-button-color-darkmode', pos_config?.primary_button_color_darkmode || '#2b2c37');
        document.documentElement.style.setProperty('--primary-button-text-color', pos_config?.primary_button_text_color || '#000000');
        document.documentElement.style.setProperty('--primary-button-text-color-darkmode', pos_config?.primary_button_text_color_darkmode || '#ffffff');
        document.documentElement.style.setProperty('--primary-button-hover-color', pos_config?.primary_button_hover_color || '#e0e0e0');
        document.documentElement.style.setProperty('--primary-button-hover-color-darkmode', pos_config?.primary_button_hover_color_darkmode || '#3a3b47');
        document.documentElement.style.setProperty('--primary-button-hover-text-color', pos_config?.primary_button_hover_text_color || '#000000');
        document.documentElement.style.setProperty('--primary-button-hover-text-color-darkmode', pos_config?.primary_button_hover_text_color_darkmode || '#ffffff');
        document.documentElement.style.setProperty('--popup-color', pos_config?.popup_color || '#ffffff');
        document.documentElement.style.setProperty('--popup-color-darkmode', pos_config?.popup_color_darkmode || '#2b2c37');
        document.documentElement.style.setProperty('--popup-text-color', pos_config?.popup_text_color || '#000000');
        document.documentElement.style.setProperty('--popup-text-color-darkmode', pos_config?.popup_text_color_darkmode || '#ffffff');
        document.documentElement.style.setProperty('--popup-secondary-button-color', pos_config?.popup_secondary_button_color || '#e0e0e0');
        document.documentElement.style.setProperty('--popup-secondary-button-color-darkmode', pos_config?.popup_secondary_button_color_darkmode || '#3a3b47');
        document.documentElement.style.setProperty('--popup-secondary-button-text-color', pos_config?.popup_secondary_button_text_color || '#000000');
        document.documentElement.style.setProperty('--popup-secondary-button-text-color-darkmode', pos_config?.popup_secondary_button_text_color_darkmode || '#ffffff');
    }, [pos_config])
    return null;
}

export default StyleManager;