import React, { useEffect, useState } from "react";
import Phone from "../assets/RightBar/Phone.png";
import Avatar from "../assets/RightBar/Avatar.png";
import Star from "../src/assets/RightBar/Star.png";
import Book from "../assets/RightBar/Book.png";
import { HiOutlineMail } from "react-icons/hi";
import { GrMapLocation } from "react-icons/gr";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { BsArrowRightShort } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "..";
import axios from "axios";
import { ExpenseObject } from "../Interfaces/IExpenseObject";
import { Attachment } from "../Interfaces/IAttachmentObject";
import AWS from "aws-sdk";
import { SET_OBJECT_URL } from "../Features/s3";
import { useIsFirstRender } from "usehooks-ts";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { BaseService } from "../assets/BaseService";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddExpensePage = () => {
  const [open, setOpen] = useState(false);
  const [expenseAmount, setExpenseAmount] = useState<string>("");
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [receiptNo, setReceiptNo] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [expense, setExpense] = useState<Array<ExpenseObject>>([]);
  const [blob, setBlob] = useState<string | null>(null);
  const [expenseApiTrigger, setExpenseApiTrigger] = useState<number>(0);
  const [attachment, setAttachment] = useState<Attachment | undefined>(
    undefined
  );
  const [isLoadingExpenses, setIsLoadingExpenses] = useState<boolean>(false);
  const [isLoadingApi, setLoadingApi] = useState<boolean>(false);
  const objectURL = useSelector((state: RootState) => state.s3.objectURL);
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const slug = useSelector((state: RootState) => state.leftHeader.slug);
  const customer = useSelector((state: RootState) => state.customer);
  const token = localStorage.getItem("access_token");
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const userId = useSelector(
    (state: RootState) => state.leftHeader.response_id
  );
  const salesReport = useSelector((state: RootState) => state.salesReport);
  const isFirst = useIsFirstRender();

  const dispatch = useDispatch();

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleFocus1 = () => {
    document
      .getElementById("parent1")
      ?.classList.add(
        "border",
        "border-yellow-400",
        "caret-yellow-500",
        "caret-w-4"
      );
  };
  const handleBlur1 = () => {
    document
      .getElementById("parent1")
      ?.classList.remove(
        "border",
        "border-yellow-400",
        "caret-yellow-500",
        "caret-w-4"
      );
  };

  const handleFocus2 = () => {
    document
      .getElementById("parent2")
      ?.classList.add(
        "border",
        "border-yellow-400",
        "caret-yellow-500",
        "caret-w-4"
      );
  };
  const handleBlur2 = () => {
    document
      .getElementById("parent2")
      ?.classList.remove(
        "border",
        "border-yellow-400",
        "caret-yellow-500",
        "caret-w-4"
      );
  };

  const handleFocus3 = () => {
    document
      .getElementById("parent3")
      ?.classList.add(
        "border",
        "border-yellow-400",
        "caret-yellow-500",
        "caret-w-4"
      );
  };
  const handleBlur3 = () => {
    document
      .getElementById("parent3")
      ?.classList.remove(
        "border",
        "border-yellow-400",
        "caret-yellow-500",
        "caret-w-4"
      );
  };

  const handleFocus4 = () => {
    document
      .getElementById("parent4")
      ?.classList.add(
        "border",
        "border-yellow-400",
        "caret-yellow-500",
        "caret-w-4"
      );
  };
  const handleBlur4 = () => {
    document
      .getElementById("parent4")
      ?.classList.remove(
        "border",
        "border-yellow-400",
        "caret-yellow-500",
        "caret-w-4"
      );
  };

  const handleFocus5 = () => {
    document
      .getElementById("parent5")
      ?.classList.add(
        "border",
        "border-yellow-400",
        "caret-yellow-500",
        "caret-w-4"
      );
  };
  const handleBlur5 = () => {
    document
      .getElementById("parent5")
      ?.classList.remove(
        "border",
        "border-yellow-400",
        "caret-yellow-500",
        "caret-w-4"
      );
  };

  const handleFocus6 = () => {
    document
      .getElementById("parent6")
      ?.classList.add(
        "border",
        "border-yellow-400",
        "caret-yellow-500",
        "caret-w-4"
      );
  };
  const handleBlur6 = () => {
    document
      .getElementById("parent6")
      ?.classList.remove(
        "border",
        "border-yellow-400",
        "caret-yellow-500",
        "caret-w-4"
      );
  };

  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_REGIN,
  });

  interface IUploadedFile {
    url: string;
    webkitRelativePath: string;
    lastModified: number;
    name: string;
    size: number;
    type: string;
  }

  interface UploadFileParams {
    file: File;
    pathKey: string;
  }

  const convertFileToObj = (file: File, url: string): IUploadedFile => ({
    url,
    webkitRelativePath: file.webkitRelativePath,
    lastModified: file.lastModified,
    name: file.name,
    size: file.size,
    type: file.type,
  });

  const uploadFile = async ({ file, pathKey }: UploadFileParams) => {
    console.log(`Uploading file`, file, pathKey);
    const params = {
      Bucket: "megnus-cdn",
      Key: `${pathKey}`,
      Body: file,
      ContentType: file.type,
      ACL: "public-read",
    };

    await s3.upload(params, function (err: any, data: any) {
      if (err) {
        console.log("err", err);
      } else {
        console.log("File uploaded successfully:", data);
        const modifiedFile = convertFileToObj(file, data.Location);
        dispatch(SET_OBJECT_URL(data.Location));
        // const newValues = [...values, modifiedFile];
        // if (setValue) {
        //   setValue(newValues);
        // }
      }
    });
  };

  const createUID = (len: number = 10) => {
    const buf: string[] = [],
      chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
      charlen = chars.length,
      length = len || 32;

    for (let i = 0; i < length; i++) {
      buf[i] = chars.charAt(Math.floor(Math.random() * charlen));
    }
    return buf.join("");
  };

  const addUniqueCodeToFilename = (filename: string, uniqueCode: string) => {
    // Split the filename into name and extension
    const splitFilename = filename.split(".");

    // Add unique code to the filename
    const newFilename = `${splitFilename[0]}-${uniqueCode}.${splitFilename[1]}`;

    return newFilename;
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setBlob(url);

      setLoadingApi(true);

      await uploadFile({
        file,
        pathKey: `${slug}/expense-form/${addUniqueCodeToFilename(
          file.name,
          createUID(4)
        )}`,
      });

      setAttachment({
        url: "",
        lastModified: file.lastModified,
        lastModifiedDate: new Date().toString(),
        type: file.type,
        size: file.size,
        name: file.name,
        webkitRelativePath: "",
      });
    }
  };

  useEffect(() => {
    if (!isFirst && attachment) {
      // @ts-ignore
      setAttachment({
        ...attachment,
        url: objectURL,
      });
      setLoadingApi(false);
    }
  }, [objectURL]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoadingExpenses(true);
    setLoadingApi(true);
    await BaseService.post(
      "/dynamic/create",
      {
        collection: "expenses",
        data: {
          expenseDate: "2023-08-16T11:04:09.381Z",
          amount: expenseAmount.length > 0 ? parseInt(expenseAmount) : 0,
          description: description,
          paymentMethod: paymentMethod,
          receiptNumber: receiptNo,
          attachment: [
            {
              url: attachment && attachment.url,
              lastModified: attachment && attachment.lastModified,
              webkitRelativePath: attachment && attachment.webkitRelativePath,
              lastModifiedDate: attachment && attachment.lastModifiedDate,
              name: attachment && attachment.name,
              size: attachment && attachment.size,
              type: attachment && attachment.type,
            },
          ],
        },
      },
      {}
    ).then((res) => {
      console.log(res.data.data);
      setExpenseApiTrigger(expenseApiTrigger + 1);
      setExpenseAmount("");
      setDescription("");
      setPaymentMethod("");
      setReceiptNo("");
      setStatus("");
      setAttachment(undefined);
      setIsLoadingExpenses(false);
      setLoadingApi(false);
      handleClick();
    });
  };

  const getExpenses = async () => {
    setIsLoadingExpenses(true);
    await BaseService.post(
      "/dynamic/get",
      {
        collection: "expenses",
        filter: {
          createdBy: userId,
          createdAt: {
            $gte: salesReport.openingDate
              ? salesReport.openingDate.toISOString()
              : new Date().toISOString(),
            $lte: new Date().toISOString(),
          },
        },
      },
      {}
    ).then((res) => {
      console.log(res.data.data);
      setExpense(res.data.data);
      setIsLoadingExpenses(false);
    });
  };

  useEffect(() => {
    getExpenses();
  }, [expenseApiTrigger]);

  console.log(attachment);

  return (
    <div className="h-[100%] m-[5px] flex items-start justify-start">
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Expense Added!
        </Alert>
      </Snackbar>
      <div className="h-full overflow-y-scroll min-w-[600px] w-[600px] border-slate-400 border-r-[1px] flex items-start justify-start flex-col gap-4">
        {isLoadingExpenses ? (
          <div className="w-full mt-[20px] flex items-center justify-center">
            <CircularProgress />
          </div>
        ) : (
          expense &&
          expense.map((val: ExpenseObject, key: number) => (
            <div
              className={`w-[575px] h-auto bg-[${pos_config.item_button_color}] flex items-start justify-start flex-col gap-2 p-5 drop-shadow-md border-[${pos_config.items_price_text_color}] border-[5px] border-double`}
            >
              <div className="w-full font-bold">
                Expense:
                <span
                  className={`font-normal ml-2 text-[15px] text-[${pos_config.items_price_text_color}]`}
                >
                  # {key + 1}
                </span>
              </div>
              <div className="w-full font-bold">
                Amount:{" "}
                <span
                  className={`font-normal ml-2 text-[15px] text-[${pos_config.items_price_text_color}]`}
                >
                  {val.amount}
                </span>
              </div>
              <div className="w-full font-bold">
                Payment Method:{" "}
                <span
                  className={`font-normal ml-2 text-[15px] text-[${pos_config.items_price_text_color}]`}
                >
                  {val.paymentMethod}
                </span>
              </div>
              <div className="w-full font-bold">
                Receipt No:{" "}
                <span
                  className={`font-normal ml-2 text-[15px] text-[${pos_config.items_price_text_color}]`}
                >
                  {val.receiptNumber}
                </span>
              </div>
              <div className="w-full font-bold">
                Status:{" "}
                <span
                  className={`font-normal ml-2 text-[15px] text-[${pos_config.items_price_text_color}]`}
                >
                  {val.status}
                </span>
              </div>
              <div className="w-full font-bold">
                Description:{" "}
                <span
                  className={`font-normal ml-2 text-[15px] text-[${pos_config.items_price_text_color}]`}
                >
                  {val.description}
                </span>
              </div>
              {val.attachment &&
                val.attachment.length > 0 &&
                val.attachment[0].name && (
                  <div className="w-full font-bold">
                    Attachment:{" "}
                    <a
                      className={`font-normal ml-2 text-[15px] underline underline-offset-2 text-[${pos_config.items_price_text_color}]`}
                      href={val.attachment[0].url}
                      target="_blank"
                    >
                      {val.attachment[0].name}
                    </a>
                  </div>
                )}
            </div>
          ))
        )}
      </div>
      <div className="w-full">
        <div className="flex items-center justify-start gap-2 px-[20px] py-[30px]">
          <h1
            className={`${
              darkMode ? "text-white" : "text-black"
            } font-[600] text-[20px]`}
          >
            Add New Expense
          </h1>
          <button className="text-red-600 font-[600] text-[14px] hover:bg-red-600 hover:text-white px-[12px] py-[4px] rounded-full hover:scale-105 ease-in-out duration-100">
            Add new +
          </button>
        </div>
        <form
          action=""
          className="flex items-center justify-center flex-col w-full gap-[16px]"
          onSubmit={handleSubmit}
        >
          <div className="flex items-center justify-around px-[20px] w-full">
            <div
              id="parent1"
              className="w-full flex items-center justify-start h-[48px] rounded-full bg-white px-[20px] mr-[20px] border-[1px]"
            >
              <input
                onFocus={handleFocus1}
                onBlur={handleBlur1}
                onChange={(e) => setReceiptNo(e.target.value)}
                className="w-full h-full ml-[20px] outline-none font-[14px]"
                placeholder="Enter Receipt No."
                value={receiptNo}
                type="text"
                required
              />
            </div>
            <div
              id="parent2"
              className=" w-full flex items-center justify-start h-[48px] rounded-full bg-white px-[20px] border-[1px]"
            >
              <input
                onChange={(e) => {
                  setExpenseAmount(e.target.value);
                }}
                onFocus={handleFocus2}
                onBlur={handleBlur2}
                className="w-full h-full ml-[20px] outline-none font-[14px]"
                placeholder="Enter Amount"
                type="tel"
                value={expenseAmount}
                required
              />
            </div>
          </div>

          <div className="flex items-center justify-around px-[20px] w-full">
            {/* <div
              id="parent3"
              className="w-full flex items-center justify-start h-[48px] rounded-full bg-white px-[20px] mr-[20px] border-[1px]"
            >
              <input
                onFocus={handleFocus3}
                onBlur={handleBlur3}
                onChange={(e) => setStatus(e.target.value)}
                className="w-full h-full ml-[20px] outline-none font-[14px]"
                placeholder="Enter Status"
                type="text"
                value={status}
                required
              />
            </div> */}
            <div
              id="parent4"
              className=" w-full flex items-center justify-start h-[48px] rounded-full bg-white px-[20px] mr-[20px] border-[1px]"
            >
              <input
                onChange={(e) => setPaymentMethod(e.target.value)}
                onFocus={handleFocus4}
                onBlur={handleBlur4}
                className="w-full h-full ml-[20px] outline-none font-[14px]"
                placeholder="Enter Payment Method"
                type="paymentMethod"
                value={paymentMethod}
                required
              />
            </div>
            <div
              id="parent5"
              className="w-full flex items-center justify-start h-[48px] rounded-full bg-white px-[20px]  border-[1px]"
            >
              <input
                onFocus={handleFocus5}
                onBlur={handleBlur5}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full h-full ml-[20px] outline-none font-[14px]"
                placeholder="Enter Description.."
                type="text"
                value={description}
                required
              />
            </div>
          </div>
          <div className="flex items-center justify-around px-[20px] w-full">
            <div
              id="parent6"
              className="w-full flex items-center justify-center h-[48px] rounded-full bg-white px-[20px] border-[1px]"
            >
              <input
                onFocus={handleFocus6}
                onBlur={handleBlur6}
                // @ts-ignore
                onChange={handleFileChange}
                className="w-full h-full ml-[20px] outline-none font-[14px] self-center items-center py-2"
                placeholder="Add attachment..."
                type="file"
                required
              />
            </div>
          </div>

          {blob &&
            attachment &&
            (attachment.type.startsWith("image/") ||
              attachment.type.startsWith("application/pdf")) && (
              <div
                className={`w-full px-[20px] mt-[10px] flex items-center justify-start flex-col ${
                  attachment &&
                  attachment.type.startsWith("image/") &&
                  "h-[300px]"
                } ${
                  attachment &&
                  attachment.type.startsWith("application/pdf") &&
                  "h-[800px]"
                }`}
              >
                <h2 className="font-bold border-b border-double border-black mb-[5px]">
                  Preview
                </h2>
                {attachment && attachment.type.startsWith("image/") && (
                  <div className="w-full h-[300px] overflow-hidden flex items-center justify-center">
                    <img
                      src={blob}
                      alt="Selected"
                      className="h-full max-w-full max-h-full"
                    />
                  </div>
                )}
                {attachment &&
                  attachment.type.startsWith("application/pdf") && (
                    <object
                      className="max-w-full max-h-full"
                      data={blob}
                      type="application/pdf"
                      width="100%"
                      height="800"
                    >
                      <p>
                        It appears you don't have a PDF plugin for this browser.
                        You can still{" "}
                        <a href={blob}>click here to download the PDF file.</a>
                      </p>
                    </object>
                  )}
              </div>
            )}

          <button
            type="submit"
            className="text-[14px] font-[600] bg-[#F7B744] hover:bg-yellow-400 hover:scale-105 ease-in-out duration-100 h-[48px] px-[80px] flex items-center justify-center rounded-full"
          >
            {isLoadingApi ? <CircularProgress /> : "Add +"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddExpensePage;
