import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ADD_OR_REMOVE_ADDONS, UPDATE_EDITED_ADDON } from "../../Features/cart";
import { AiFillCheckCircle } from "react-icons/ai";
import { RootState } from "../..";
import { SET_FLAVOURS_AND_TOPPINGS_OPEN } from "../../Features/ItemsStates";

const FlavoursAndToppings = (props: any) => {
  const [checkedItem, setCheckedItem] = useState<number>(0);

  const itemsStates = useSelector((state: RootState) => state.itemStates);
  const dispatch = useDispatch();
  const cart = useSelector((state: any) => state.cart);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);

  window.addEventListener("keydown", function (e) {
    if (
      e.keyCode == 32 ||
      (e.shiftKey && e.keyCode == 9) ||
      e.keyCode == 37 ||
      e.keyCode == 38 ||
      e.keyCode == 39 ||
      e.keyCode == 40
    ) {
      e.preventDefault();
    }
  });

  useEffect(() => {
    if (!cart.isEditingAddon) {
      dispatch(
        ADD_OR_REMOVE_ADDONS({
          // @ts-ignore
          name: itemsStates.flavor[itemsStates.popUpSection]?.Name,
          // @ts-ignore
          item: itemsStates.flavor[itemsStates.popUpSection]?.OptionsList[
            itemsStates.popUpIterator
          ],
        })
      );
    } else {
      dispatch(
        UPDATE_EDITED_ADDON({
          // @ts-ignore
          name: itemsStates.flavor[itemsStates.popUpSection]?.Name,
          // @ts-ignore
          item: itemsStates.flavor[itemsStates.popUpSection]?.OptionsList[
            itemsStates.popUpIterator
          ],
          // @ts-ignore
          isMultiple: itemsStates.flavor[itemsStates.popUpSection]?.IsMultiple,
        })
      );
    }
  }, [itemsStates.popUpTrigger]);

  // useEffect(() => {
  //   setCheckedItem(itemsStates.popUpIterator);
  // }, [itemsStates.popUpIterator]);

  useEffect(() => {
    dispatch(SET_FLAVOURS_AND_TOPPINGS_OPEN(true));
    return () => {
      dispatch(SET_FLAVOURS_AND_TOPPINGS_OPEN(false));
    };
  }, []);

  return (
    <div className="p-2">
      {itemsStates?.flavor?.map((item: any, i: any) => {
        return (
          <div key={i} id={`flavor-list-${i}`} className="mb-4">
            <div className="font-bold text-lg text-[#111827] mb-2">
              {item.Name}
            </div>

            <div
              className={`grid gap-3
              grid-cols-2
            sm:grid-cols-3
            md:grid-cols-4
            lg:grid-cols-5
            xl:grid-cols-6
            ${
              i === itemsStates.popUpSection ? "bg-blue-200 p-2 rounded-md" : ""
            }
            `}
            >
              {item.OptionsList.map((obj: any, index: any) => (
                <div
                  id={`itemcard-${i}${index}`}
                  key={index}
                  className={`
                  ${
                    cart.item?.value[i]?.properties.includes(obj)
                      ? !darkMode
                        ? `bg-[${pos_config?.addons_button_hover_color}] text-[${pos_config?.addons_button_hover_text_color}] border-2 border-[${pos_config?.addons_button_hover_text_color}]`
                        : `bg-[${pos_config?.addons_button_hover_color_darkmode}] text-[${pos_config?.addons_button_hover_text_color_darkmode}] border-2 border-[${pos_config?.addons_button_hover_text_color_darkmode}]`
                      : !darkMode
                      ? `bg-[${pos_config?.addons_button_color}] text-[${pos_config?.addons_button_text_color}]`
                      : `bg-[${pos_config?.addons_button_color_darkmode}] text-[${pos_config?.addons_button_text_color_darkmode}]`
                  }
                  ${
                    i === itemsStates.popUpSection &&
                    index === itemsStates.popUpIterator
                      ? "border-2 border-black"
                      : "border-2 border-white"
                  }
                  ${
                    darkMode
                      ? `hover:bg-[${pos_config.addons_button_hover_color_darkmode}] hover:text-[${pos_config?.addons_button_hover_text_color_darkmode}] border-[#2b2c37]`
                      : `hover:bg-[${pos_config.addons_button_hover_color}] hover:text-[${pos_config?.addons_button_hover_text_color}] border-[#E8E8E8]`
                  }
                  p-4 flex flex-col rounded-md relative cursor-pointer select-none`}
                  onClick={() => {
                    if (cart.isEditingAddon) {
                      dispatch(
                        UPDATE_EDITED_ADDON({
                          name: item.Name,
                          item: obj,
                          isMultiple: item.IsMultiple,
                        })
                      );
                    } else {
                      dispatch(
                        ADD_OR_REMOVE_ADDONS({ name: item.Name, item: obj })
                      );
                    }
                  }}
                >
                  <div
                    className={`${
                      !cart.item?.value[i]?.properties.includes(obj) && "hidden"
                    } absolute top-1 right-1`}
                  >
                    <AiFillCheckCircle />
                  </div>
                  <div className="text-sm font-bold">{obj.Name}</div>
                  <div
                    className={`text-[11px] font-[700] text-[text-[${
                      darkMode
                        ? pos_config?.addons_button_price_text_color_darkmode
                        : pos_config?.addons_button_price_text_color
                    }]]`}
                  >
                    Rs {obj.Price}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FlavoursAndToppings;
