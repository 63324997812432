//@ts-nocheck
import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";

interface Customer {
  data: Data | undefined;
  isReady: boolean;
  isLoading: boolean;
}

export interface Data {
  // _id: string;
  ID: string;
  // code: string;
  // isActive: boolean;
  IsActive: string;
  // isDeleted: boolean;
  // isReserved: boolean;
  // name: string;
  Name: string;
  // phoneNo: string;
  Mobile: string;
  // email: string;
  Email: string;
  // address: string;
  Address: string;
  City: string;
  // points: number;
  MemberPoints: string;
  // ordersCount: number;
  customerRating: number;
  Orders: string;

  otherAddresses: { Address: string, NearestLandmark: string, Area: string, City: string, Type: string }[];
  orders: any[];
  complains: any[];
  // createdAt: string;
  Created: string;
  updatedAt: string;
  __v: number;
}



const initialState: Customer = {
  data: undefined,
  isReady: false,
  isLoading: false,
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    SET_CUSTOMER_DATA: (state, action: PayloadAction<Data | undefined>) => {
      state.data = action.payload;
    },
    SET_IS_READY: (state, action: PayloadAction<boolean>) => {
      state.isReady = action.payload;
    },
    SET_IS_LOADING: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { SET_CUSTOMER_DATA, SET_IS_READY, SET_IS_LOADING } =
  customerSlice.actions;
export default customerSlice.reducer;