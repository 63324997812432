import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "..";
import { createNewBaseService } from "../assets/BaseService";
import { OrderDetails } from "../Interfaces/IOrderDetails";
import order from "../Features/order";
import { FaPrint } from "react-icons/fa";
import { SET_ORDER_DETAILS } from "../Features/cart";
import { SET_PRINTERPOPUP } from "../Features/rightBarHeader";

const useTimer = (startTimeString: string) => {
  const [elapsedTime, setElapsedTime] = useState("0:00");
  const [elapsedMinutes, setElapsedMinutes] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const start = new Date(startTimeString).getTime();
      const now = new Date().getTime();
      const difference = Math.max(0, now - start);

      const minutes = Math.floor(difference / 60000);
      const seconds = Math.floor((difference % 60000) / 1000);

      setElapsedTime(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
      setElapsedMinutes(minutes);
    }, 1000);

    return () => clearInterval(interval);
  }, [startTimeString]);

  return { elapsedTime, elapsedMinutes };
};

const getColorByElapsedTime = (elapsedMinutes: number) => {
  if (elapsedMinutes <= 15) return "green-500";
  if (elapsedMinutes <= 30) return "orange-500";
  return "red-500";
};

const OrderCard = ({
  item,
  darkMode,
  pos_config,
  refreshOrders,
  isSelected,
  selectedItemIndex,
  isNavigatingItems,
  setSelectedItemIndex,
  setIsNavigatingItems,
}: any) => {
  const { elapsedTime, elapsedMinutes } = useTimer(item.Created);
  const [checkedItems, setCheckedItems] = useState<Set<string>>(new Set());
  const dispatch = useDispatch();
  const [orderStatus, setOrderStatus] = useState<string>("notStarted");
  const branch = useSelector(
    (state: RootState) => state.cart.loginData.branchName
  );

  const NewBaseService = createNewBaseService(pos_config);

  const handlePrint = () => {
    dispatch(SET_ORDER_DETAILS(item));
    dispatch(SET_PRINTERPOPUP(true));
  };

  useEffect(() => {
    const initialCheckedItems = new Set<string>(
      item.orderdata
        .filter((obj: any) => obj.Status === "Ready to Serve")
        .map((obj: any) => obj.ItemName)
    );
    setCheckedItems(initialCheckedItems);
    setOrderStatus(item.Status === "Preparing" ? "started" : "notStarted");
  }, [item.orderdata, item.Status]);

  const createOrderJson = (status: string): OrderDetails => {
    return {
      branch: branch,
      code: item.ID,
      platform: "POS",
      name: item.TableName,
      status: item.POSStatus,
      kdsStatus: status,
      orderType: item.OrderType,
      area: "Test area",
      tax: item?.TaxAmount,
      voucher: item?.VoucherValue,
      discountamount: item?.Discount,
      orderamount: item?.OrderAmount,
      paymenttype: item?.PaymentType,
      paymentRemarks: "",
      totalamount: item?.SubTotal,
      taxamount: item?.TaxAmount,
      deliverycharges: 0,
      customerFullName: item.CustomerName || "",
      customerPhone: item.CustomerMobile || "",
      customerAddress: item.CustomerAddress || "",
      customerCity: item?.City,
      customerLatitude: null,
      customerLongitude: null,
      customerOtherAddresses: null,
      orderdata: item?.orderdata.map((obj: any) => ({
        productName: obj?.ItemName,
        refCode: obj?.ItemID,
        categoryName: obj?.Category,
        discountGiven: 0,
        itemStatus: "Ready to Serve",
        minimumDelivery: obj?.MinimumDelivery,
        itemImage:
          "https://admin.dunkinpizza.com.pk/Images/ProductImages/13-inch-thumbnails.jpg",
        options:
          obj.options?.map((temp: any) => ({
            OptionID: temp?.OptionID,
            OptionName: temp?.OptionName,
            OptionGroupName: temp?.OptionGroupName,
            Price: temp?.Price,
            Quantity: temp?.Quantity,
          })) || [],
        quantity: obj?.Quantity,
        price: obj?.Price,
        totalProductPrice: obj?.orderTotal,
        SizeID: obj?.SizeID,
        Status: obj?.Status,
      })),
      Remarks: "",
      category: "",
    };
  };

  const headerColor = getColorByElapsedTime(elapsedMinutes);

  const handleItemClick = async (itemName: string, OptionID: string) => {
    const newCheckedItems = new Set(checkedItems);
    const isChecked = newCheckedItems.has(itemName);

    setCheckedItems(newCheckedItems);
    if (isChecked) {
      newCheckedItems.delete(itemName);
      const data = {
        collection: "orderdetail_pos",
        filter: { id: OptionID },
        data: { Status: "Pending" },
      };
      try {
        await NewBaseService.post("/dynamic/update", data);
      } catch (error) {
        console.error("Error Updating the Item Status", error);
      }
    } else {
      newCheckedItems.add(itemName);
      const data = {
        collection: "orderdetail_pos",
        filter: { id: OptionID },
        data: { Status: "Ready to Serve" },
      };
      try {
        await NewBaseService.post("/dynamic/update", data);
      } catch (error) {
        console.error("Error Updating the Item Status", error);
      }
    }

    if (!isChecked && orderStatus === "notStarted") {
      setOrderStatus("started");
    }
  };

  const handleStartDone = async () => {
    const newStatus =
      orderStatus === "notStarted" ? "Preparing" : "Ready To Serve";
    const order: OrderDetails = createOrderJson(newStatus);

    try {
      await NewBaseService.post(`/dynamic/update`, {
        collection: "orders",
        filter: { id: item.ID },
        data: order,
      });
      setOrderStatus(orderStatus === "notStarted" ? "started" : "done");
      if (newStatus === "Ready To Serve") {
        refreshOrders();
        setIsNavigatingItems(false);
        setSelectedItemIndex(-1);
      }
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  useEffect(() => {
    const allItemsChecked = item.orderdata.every((obj: any) =>
      checkedItems.has(obj.ItemName)
    );
    if (allItemsChecked && orderStatus === "started") {
      handleStartDone();
    }
  }, [checkedItems, orderStatus]);

  const handleItemNavigation = (e: React.KeyboardEvent) => {
    if (!isNavigatingItems) return;

    switch (e.key) {
      case "ArrowUp":
        setSelectedItemIndex((prev: any) => Math.max(0, prev - 1));
        break;
      case "ArrowDown":
        setSelectedItemIndex((prev: any) =>
          Math.min(item.orderdata.length - 1, prev + 1)
        );
        break;
      case "Enter":
        if (selectedItemIndex !== -1) {
          const selectedItem = item.orderdata[selectedItemIndex];
          handleItemClick(selectedItem.ItemName, selectedItem.ID);
        }
        break;
      case "Escape":
        setIsNavigatingItems(false);
        setSelectedItemIndex(-1);
        break;
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (isSelected && isNavigatingItems) {
        handleItemNavigation(e as any);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [isSelected, isNavigatingItems, selectedItemIndex, item.orderdata.length]);

  return (
    <div
      className={`
          border-[1px] rounded-[16px] shadow-xl transition-all duration-300 hover:shadow-2xl flex flex-col
          ${darkMode ? "bg-gray-800 border-[#111827]" : "bg-white border-none"}
          ${isSelected ? "ring-2 ring-blue-500 bg-blue-100" : ""}
          `}
    >
      <div
        className={`w-full p-[10px] text-center text-[16px] font-bold rounded-t-[16px] bg-${headerColor} text-white flex justify-between items-center`}
      >
        <span>
          {item?.OrderType}{" "}
          {item?.OrderType === "Dine-in" ? `@${item.TableName}` : ""}
        </span>
        <button
          className="text-white hover:text-gray-200 transition-colors duration-300 p-1 rounded-full hover:bg-white hover:bg-opacity-20"
          onClick={handlePrint}
        >
          <FaPrint size={18} />
        </button>
      </div>
      <div className=" flex-grow flex flex-col mt-1">
        <div className="pt-[7px] flex-grow">
          <div
            className={`${
              darkMode
                ? `text-[${pos_config?.order_bar_text_color_darkmode}]`
                : `text-[${pos_config?.order_bar_text_color}]`
            } font-bold text-[16px] mb-1 p-2 justify-between flex items-center`}
          >
            <span>#{item?.ID}</span>
            <span
              className={`text-${headerColor} px-2 py-1 rounded-md font-mono text-lg`}
            >
              {elapsedTime}
            </span>
          </div>
          <div
            className={`flex flex-col gap-[16px] items-between pt-[5px] justify-start flex-grow overflow-auto text-[${
              darkMode
                ? `${pos_config?.order_bar_menu_text_color}`
                : `${pos_config?.order_bar_menu_text_color_darkmode}`
            }] font-[400] text-[14px] leading-[18px] p-4`}
          >
            {item?.orderdata.map((obj: any, i: number) => (
              <div
                key={i}
                className={`flex flex-col p-3 rounded-md ${
                  checkedItems.has(obj.ItemName)
                    ? isSelected && i === selectedItemIndex
                      ? "bg-blue-300 border-blue-300"
                      : "bg-green-200 border-green-300"
                    : isSelected && i === selectedItemIndex
                    ? "bg-blue-300 border-blue-300"
                    : darkMode
                    ? "bg-gray-700 border-gray-600"
                    : "bg-gray-100 border-gray-300"
                } border transition-colors duration-300 `}
                onClick={() => handleItemClick(obj.ItemName, obj.ID)}
              >
                <div className="flex justify-between items-center cursor-pointer">
                  <div className="flex items-center flex-grow">
                    <input
                      type="checkbox"
                      className="mr-4 w-5 h-5"
                      checked={checkedItems.has(obj.ItemName)}
                      onChange={() => {}}
                    />
                    <div
                      className={`${
                        checkedItems.has(obj.ItemName) ? "line-through" : ""
                      } text-[15px] font-bold ${
                        darkMode ? "text-white" : "text-black"
                      } flex-grow`}
                    >
                      {obj?.ItemName}
                    </div>
                  </div>
                  <div className="pr-[10px] text-[15px] font-semibold">
                    {obj?.Quantity}x
                  </div>
                </div>
                {obj.options && obj.options.length > 0 && (
                  <div className="ml-9 mt-2">
                    {obj.options.map((option: any, optionIndex: number) => (
                      <div
                        key={optionIndex}
                        className="flex items-center text-[13px] text-gray-500 cursor-pointer"
                      >
                        - {option.OptionName}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-center items-center mt-2 mb-1 px-4">
          <button
            className={`
    ${orderStatus === "notStarted" ? `bg-${headerColor}` : "bg-green-500"}
    text-white font-bold text-[16px] rounded-[16px] py-3 px-3 w-full
    transition-all duration-300 transform hover:scale-105 hover:shadow-lg
    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${headerColor}
  `}
            onClick={handleStartDone}
          >
            {orderStatus === "notStarted" ? "Start" : "Done"}
          </button>
        </div>
      </div>
    </div>
  );
};

const KDSPage = () => {
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const branchName = useSelector(
    (state: RootState) => state.cart.loginData.branchName
  );
  const [orders, setOrders] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [newOrders, setNewOrders] = useState<any[]>([]);
  const [showNewOrderPopup, setShowNewOrderPopup] = useState<boolean>(false);
  const [showUpdatedOrdersNotification, setShowUpdatedOrdersNotification] =
    useState<boolean>(false);
  const [updatedOrders, setUpdatedOrders] = useState<any[]>([]);
  const [isClosing, setIsClosing] = useState<boolean>(false);
  const [isClosingUpdateNotification, setIsClosingUpdateNotification] =
    useState<boolean>(false);
  const NewBaseService = createNewBaseService(pos_config);

  const [selectedOrderIndex, setSelectedOrderIndex] = useState<number>(-1);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(-1);
  const [isNavigatingItems, setIsNavigatingItems] = useState<boolean>(false);

  const fetchOrders = async () => {
    setIsLoading(true);
    try {
      const res = await NewBaseService.get(
        "/dynamic/query/get-all-orders-with-details?Status=hold",
        {
          params: {
            collection: "orders",
            filter: JSON.stringify({
              branch: branchName,
            }),
          },
        }
      );
      const sortedOrders = res.data.data.sort(
        (a: any, b: any) =>
          new Date(a.Created).getTime() - new Date(b.Created).getTime()
      );
      const kdsStatusPendingOrders = sortedOrders.filter(
        (orders: any) => orders.KDSStatus === "Pending"
      );
      setOrders(kdsStatusPendingOrders);
    } catch (err) {
      console.error("Orders get error", err);
      setOrders([]);
    } finally {
      setIsLoading(false);
    }
  };

  const checkForNewAndUpdatedOrders = async () => {
    try {
      const res = await NewBaseService.get(
        "/dynamic/query/get-all-orders-with-details?Status=hold",
        {
          params: {
            collection: "orders",
            filter: JSON.stringify({
              branch: branchName,
            }),
          },
        }
      );
      const latestOrders = res.data.data;

      const latestOrderswithKDSStatusPending = latestOrders.filter(
        (orders: any) => orders.KDSStatus === "Pending"
      );

      const newOrders = latestOrderswithKDSStatusPending.filter(
        (newOrder: any) =>
          !orders.some((existingOrder: any) => existingOrder.ID === newOrder.ID)
      );

      const updatedOrders = latestOrderswithKDSStatusPending.filter(
        (latestOrder: any) => {
          const existingOrder = orders.find(
            (order: any) => order.ID === latestOrder.ID
          );
          return (
            existingOrder &&
            JSON.stringify(existingOrder) !== JSON.stringify(latestOrder)
          );
        }
      );

      if (newOrders.length > 0) {
        setNewOrders(newOrders);
        setShowNewOrderPopup(true);
      }

      if (updatedOrders.length > 0) {
        setOrders((prevOrders) =>
          prevOrders.map((order) => {
            const updatedOrder = updatedOrders.find(
              (updated: any) => updated.ID === order.ID
            );
            return updatedOrder || order;
          })
        );
        setUpdatedOrders(updatedOrders);
        setShowUpdatedOrdersNotification(true);
      }
    } catch (err) {
      console.error("Error checking for new and updated orders", err);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleAcceptOrders = () => {
    setShowNewOrderPopup(false);
    fetchOrders();
  };

  useEffect(() => {
    if (showNewOrderPopup) {
      const timer = setTimeout(() => {
        setIsClosing(true);
      }, 2000);

      const closeTimer = setTimeout(() => {
        setShowNewOrderPopup(false);
        setIsClosing(false);
        fetchOrders();
      }, 2300); // 2000ms + 300ms for animation

      return () => {
        clearTimeout(timer);
        clearTimeout(closeTimer);
      };
    }
  }, [showNewOrderPopup]);

  useEffect(() => {
    const interval = setInterval(checkForNewAndUpdatedOrders, 30000);
    return () => clearInterval(interval);
  }, [orders]);

  const filteredOrders = orders.filter(
    (order) =>
      order.ID.toString().includes(searchTerm) ||
      order.OrderType.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (isNavigatingItems) {
        // handleItemNavigation(e);
      } else {
        handleOrderNavigation(e);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [
    selectedOrderIndex,
    selectedItemIndex,
    isNavigatingItems,
    filteredOrders,
  ]);

  const handleOrderNavigation = (e: KeyboardEvent) => {
    switch (e.key) {
      case "ArrowLeft":
        setSelectedOrderIndex((prev) => Math.max(0, prev - 1));
        break;
      case "ArrowRight":
        setSelectedOrderIndex((prev) =>
          Math.min(filteredOrders.length - 1, prev + 1)
        );
        break;
      case "Enter":
        if (selectedOrderIndex !== -1) {
          setIsNavigatingItems(true);
          setSelectedItemIndex(0);
        }
        break;
      case "Escape":
        setSelectedOrderIndex(0);
        break;
    }
  };

  // const handleItemNavigation = (e: KeyboardEvent) => {
  //   const currentOrder = filteredOrders[selectedOrderIndex];
  //   switch (e.key) {
  //     case "ArrowUp":
  //       setSelectedItemIndex(prev => Math.max(0, prev - 1));
  //       break;
  //     case "ArrowDown":
  //       setSelectedItemIndex(prev => Math.min(currentOrder.orderdata.length - 1, prev + 1))
  //       break;
  //     case "Enter":
  //       const selectedItem = currentOrder.orderdata[selectedItemIndex];
  //       // handleItemClick
  //       break;
  //     case "Escape":
  //       setIsNavigatingItems(false);
  //       setSelectedItemIndex(-1);
  //       break;
  //   }
  // }

  const NewOrdersPopup = () => (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-opacity duration-300 ${
        isClosing ? "opacity-0" : "opacity-100"
      }`}
    >
      <div
        className={`bg-white p-8 rounded-lg shadow-2xl max-w-3xl w-full max-h-[80vh] overflow-y-auto relative transition-transform duration-300 ${
          isClosing ? "transform scale-95" : "transform scale-100"
        }`}
      >
        <h2 className="text-3xl font-bold mb-6 text-center text-blue-600">
          New Orders Available
        </h2>
        {newOrders.map((order, index) => (
          <div
            key={index}
            className="mb-6 p-6 border-2 border-blue-200 rounded-lg shadow-md hover:shadow-lg transition-shadow"
          >
            <h3 className="font-bold text-lg mb-2">Order #{order.ID}</h3>
            <p>
              <strong>Table:</strong> {order.TableName}
            </p>
            <p>
              <strong>Type:</strong> {order.OrderType}
            </p>
            <p>
              <strong>Total:</strong> Rs. {order.OrderAmount}
            </p>
            <h4 className="font-bold mt-2">Items:</h4>
            <ul className="list-none pl-5 space-y-2">
              {order.orderdata.map((item: any, i: number) => (
                <li key={i} className="flex items-center">
                  <span className="mr-2 text-blue-500">•</span>
                  <span className="font-medium">{item.Quantity}x</span>
                  <span className="ml-2">{item.ItemName}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );

  useEffect(() => {
    if (showUpdatedOrdersNotification) {
      const timer = setTimeout(() => {
        setIsClosingUpdateNotification(true);
      }, 2000);

      const closeTimer = setTimeout(() => {
        setShowUpdatedOrdersNotification(false);
        setIsClosingUpdateNotification(false);
        fetchOrders();
      }, 2300); // 2000ms + 300ms for animation

      return () => {
        clearTimeout(timer);
        clearTimeout(closeTimer);
      };
    }
  }, [showUpdatedOrdersNotification]);

  const UpdatedOrdersNotification = () => (
    <div
      className={`fixed bottom-4 right-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 rounded shadow-lg transition-all duration-300 ${
        isClosingUpdateNotification
          ? "opacity-0 transform translate-x-full"
          : "opacity-100 transform translate-x-0"
      }`}
    >
      <h3 className="font-bold">Orders Updated</h3>
      <p>{updatedOrders.length} order(s) have been updated.</p>
    </div>
  );

  return (
    <div className="h-[100%] m-[5px] overflow-y-auto">
      {showNewOrderPopup && <NewOrdersPopup />}
      {showUpdatedOrdersNotification && <UpdatedOrdersNotification />}
      <div
        className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8 lg:gap-4`}
      >
        {isLoading
          ? Array(10)
              .fill(0)
              .map((_, index) => (
                <div
                  key={index}
                  className="animate-pulse bg-gray-200 h-64 rounded-[16px]"
                ></div>
              ))
          : filteredOrders.map((item: any, i: number) => (
              <OrderCard
                key={i}
                item={item}
                darkMode={darkMode}
                pos_config={pos_config}
                refreshOrders={fetchOrders}
                isSelected={i === selectedOrderIndex}
                selectedItemIndex={isNavigatingItems ? selectedItemIndex : -1}
                isNavigatingItems={isNavigatingItems}
                setSelectedItemIndex={setSelectedItemIndex}
                setIsNavigatingItems={setIsNavigatingItems}
              />
            ))}
      </div>
    </div>
  );
};

export default KDSPage;
