import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_ISCHECKOUT, SET_ROUTE } from "../../Features/rightBarHeader";
import { INITIALIZE_ORDER_DATA, SET_CURRENT_TABLE } from "../../Features/cart";
import "./print.css";
import { RootState } from "../..";
import SalesReport from "./SalesReport";
import { SET_SALESREPORTPOPUP } from "../../Features/salesReport";

function PrintSalesReport() {
  const dispatch = useDispatch();
  const rightBarHeader = useSelector((state: RootState) => state.header);

  const handlePrint = async () => {
    window.print();
    dispatch(SET_SALESREPORTPOPUP(false));
    if (rightBarHeader.isCheckout) dispatch(INITIALIZE_ORDER_DATA());
    if (rightBarHeader.selectedHeader === "Dine-in") {
      dispatch(SET_CURRENT_TABLE(0));
    }
    dispatch(SET_ISCHECKOUT(false));
    dispatch(SET_ROUTE(""));
  };

  useEffect(() => {
    handlePrint();
  }, []);

  return (
    <div className="h-auto overflow-hidden max-w-[400px] w-[400px]">
      <SalesReport />
    </div>
  );
}

export default PrintSalesReport;
