import React, { useEffect, useState } from "react";
// @ts-ignore
import useMousetrap from "react-hook-mousetrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import cart, {
  ADD_MENU_ITEM_TO_CURRENT_ORDER,
  handleOpen,
  PLACE_ORDER,
  SET_CURRENT_TABLE,
  SET_SELECTED_ORDER,
  UPDATE_MENU_ITEM_TO_CURRENT_ORDER,
} from "../../Features/cart";
import {
  SET_POPUPSECTION,
  SET_POPUPITERATOR,
  SET_TRIGGER,
  SET_TRIGGER2,
  SET_ARROWKEYLEVEL,
  SET_POPUPTRIGGER,
  SET_ITERATOR2,
  SET_ITERATOR3,
  SET_ITERATOR,
  SET_COMINGFROM,
  SET_TO_INITIAL_ITEMS_STATE,
  SET_TOPLEVELCATEGORY,
  SET_CHECKOUTTRIGGER,
  SET_PRINTTRIGGER,
  SET_HOLDTRIGGER,
} from "../../Features/ItemsStates";
import {
  SET_ADD_DISCOUNT_POPUP_PAGE,
  SET_BARCODEPRINTPAGEPOPUP,
  SET_ISCHECKOUT,
  SET_PRINTERPOPUP,
  SET_PROMPTBUTTON,
  SET_SELECTEDHEADER,
  SET_VISUAL_HEADER,
} from "../../Features/rightBarHeader";
import { useMediaQuery } from "usehooks-ts";

const OrderShortcut = () => {
  const matches = useMediaQuery("(min-width: 1280px)");

  const dispatch = useDispatch();

  const [cardsColumnLength, setCardColumnLength] = useState<number>(7);
  const [itemsColumnLength, setItemsColumnLength] = useState<number>(6);

  const orderMenu = useSelector((state: RootState) => state.orderMenu);
  const cart = useSelector((state: RootState) => state.cart);
  const item = useSelector((state: RootState) => state.itemStates);
  const itemsStates = useSelector((state: RootState) => state.itemStates);
  const shortcuts_config = useSelector(
    (state: RootState) => state.leftHeader.shortcuts_config
  );

  useEffect(() => {
    if (matches) {
      setCardColumnLength(7);
      setItemsColumnLength(6);
    } else {
      setCardColumnLength(5);
      setItemsColumnLength(5);
    }
  }, [matches]);

  useEffect(() => {
    if (orderMenu.value[item.iterator]) {
      dispatch(SET_TOPLEVELCATEGORY(orderMenu.value[item.iterator].Name));
    }
  }, [item.iterator]);

  useMousetrap(
    shortcuts_config.add_to_cart_popup !== undefined
      ? shortcuts_config.add_to_cart_popup
      : "shift+a",
    () => {
      if (cart.open) {
        if (
          !(
            cart.selectedOrderStatus === "DISPATCHED" ||
            cart.selectedOrderStatus === "SUSPENDED" ||
            cart.selectedOrderStatus === "RETURNED"
          )
        ) {
          dispatch(SET_POPUPSECTION(0));
          dispatch(SET_POPUPITERATOR(0));
          dispatch(ADD_MENU_ITEM_TO_CURRENT_ORDER());
          dispatch(handleOpen(false));
          dispatch(SET_TO_INITIAL_ITEMS_STATE());
          dispatch(SET_ARROWKEYLEVEL(0));
          dispatch(SET_ITERATOR(0));
          dispatch(SET_TOPLEVELCATEGORY(""));
          // if (itemsStates.arrowKeyLevel === 2) {
          //   dispatch(SET_ARROWKEYLEVEL(1));
          // } else if (itemsStates.arrowKeyLevel === 3) {
          //   dispatch(SET_ARROWKEYLEVEL(1));
          // }
        }
      }
    }
  );

  useMousetrap(
    shortcuts_config.print !== undefined ? shortcuts_config.print : "shift+p",
    () => {
      dispatch(SET_PRINTTRIGGER(itemsStates.printTrigger + 1));
    }
  );

  useMousetrap(
    shortcuts_config.checkout !== undefined
      ? shortcuts_config.checkout
      : "shift+c",
    () => {
      dispatch(SET_CHECKOUTTRIGGER(itemsStates.checkoutTrigger + 1));
    }
  );

  useMousetrap(
    shortcuts_config.hold_order !== undefined
      ? shortcuts_config.hold_order
      : "shift+h",
    () => {
      dispatch(SET_HOLDTRIGGER(itemsStates.holdTrigger + 1));
    }
  );

  useMousetrap(
    shortcuts_config.table !== undefined ? shortcuts_config.table : "shift+t",
    () => {
      dispatch(SET_SELECTEDHEADER("Dine-in"));
      dispatch(SET_VISUAL_HEADER("Dine-in"));
    }
  );

  useMousetrap("shift+tab", () => {
    if (cart.open) {
      if (item.popUpSection - 1 > -1) {
        document
          .getElementById(`flavor-list-${item.popUpSection - 1}`)
          ?.scrollIntoView({ behavior: "smooth" });
        dispatch(SET_POPUPSECTION(item.popUpSection - 1));
        dispatch(SET_POPUPITERATOR(0));
      }
    }
  });

  useMousetrap("enter", () => {
    if (cart.open) {
      if (item.popUpSection + 1 === item.flavor?.length) {
        if (
          !(
            cart.selectedOrderStatus === "DISPATCHED" ||
            cart.selectedOrderStatus === "SUSPENDED" ||
            cart.selectedOrderStatus === "RETURNED"
          )
        ) {
          dispatch(SET_POPUPSECTION(0));
          dispatch(SET_POPUPITERATOR(0));
          if (cart.isEditingAddon) {
            dispatch(UPDATE_MENU_ITEM_TO_CURRENT_ORDER());
          } else {
            dispatch(ADD_MENU_ITEM_TO_CURRENT_ORDER());
          }
          dispatch(handleOpen(false));
          dispatch(SET_TO_INITIAL_ITEMS_STATE());
          dispatch(SET_TO_INITIAL_ITEMS_STATE());
          dispatch(SET_ARROWKEYLEVEL(0));
          dispatch(SET_ITERATOR(0));
          dispatch(SET_TOPLEVELCATEGORY(""));
          // if (itemsStates.arrowKeyLevel === 2) {
          //   dispatch(SET_ARROWKEYLEVEL(1));
          // } else if (itemsStates.arrowKeyLevel === 3) {
          //   dispatch(SET_ARROWKEYLEVEL(1));
          // }
        }
      }
      // @ts-ignore
      if (item.popUpSection + 1 < item.flavor?.length) {
        document
          .getElementById(`flavor-list-${item.popUpSection + 1}`)
          ?.scrollIntoView({ behavior: "smooth" });
        dispatch(SET_POPUPSECTION(item.popUpSection + 1));
        dispatch(SET_POPUPITERATOR(0));
      }
    }
    if (!itemsStates.isFlavoursAndToppingsOpen && item.arrowKeyLevel < 3) {
      if (item.arrowKeyLevel === 0) {
        document
          .getElementById("items-div")
          ?.scrollIntoView({ behavior: "smooth" });
        dispatch(SET_ITERATOR2(0));
      } else if (item.arrowKeyLevel === 1) {
        setTimeout(() => {
          document
            .getElementById("sizes-div")
            ?.scrollIntoView({ behavior: "smooth" });
        }, 100);
        dispatch(SET_TRIGGER(item.trigger + 1));
      } else if (item.arrowKeyLevel === 2) {
        dispatch(SET_TRIGGER2(item.trigger2 + 1));
      }
      dispatch(SET_ARROWKEYLEVEL(item.arrowKeyLevel + 1));
    }
  });
  useMousetrap("space", () => {
    if (cart.open) {
      dispatch(SET_POPUPTRIGGER(item.popUpTrigger + 1));
    }
  });
  useMousetrap("esc", () => {
    if (item.arrowKeyLevel === 1) {
      document
        .getElementById("category-div")
        ?.scrollIntoView({ behavior: "smooth" });
      dispatch(SET_ITERATOR2(0));
    } else if (item.arrowKeyLevel === 2) {
      document
        .getElementById("items-div")
        ?.scrollIntoView({ behavior: "smooth" });
      dispatch(SET_ITERATOR3(0));
    }

    if (item.arrowKeyLevel > 0) {
      dispatch(SET_ARROWKEYLEVEL(item.arrowKeyLevel - 1));
    }
  });

  useMousetrap("right", () => {
    if (cart.open) {
      if (
        item.popUpIterator + 1 <
        // @ts-ignore
        item?.flavor[item.popUpSection]?.OptionsList?.length
      ) {
        dispatch(SET_POPUPITERATOR(item.popUpIterator + 1));
      }
    }
    if (!itemsStates.isFlavoursAndToppingsOpen) {
      if (item.arrowKeyLevel === 0) {
        if (item.iterator + 1 < orderMenu.value.length) {
          dispatch(SET_ITERATOR(item.iterator + 1));
        }
      } else if (item.arrowKeyLevel === 1) {
        if (
          item.iterator2 + 1 <
          orderMenu.value[item.iterator]?.MenuItemsList.length
        ) {
          dispatch(SET_ITERATOR2(item.iterator2 + 1));
        }
      } else if (item.arrowKeyLevel === 2) {
        if (item.iterator3 + 1 < item.data?.MenuSizesList?.length) {
          dispatch(SET_ITERATOR3(item.iterator3 + 1));
        }
      }
    }
  });
  useMousetrap("left", () => {
    if (cart.open) {
      if (item.popUpIterator - 1 > -1) {
        dispatch(SET_POPUPITERATOR(item.popUpIterator - 1));
      }
    }
    if (!itemsStates.isFlavoursAndToppingsOpen) {
      if (item.arrowKeyLevel === 0) {
        if (item.iterator - 1 > -1) {
          dispatch(SET_ITERATOR(item.iterator - 1));
        }
      } else if (item.arrowKeyLevel === 1) {
        if (item.iterator2 - 1 > -1) {
          dispatch(SET_ITERATOR2(item.iterator2 - 1));
        }
      } else if (item.arrowKeyLevel === 2) {
        if (item.iterator3 - 1 > -1) {
          dispatch(SET_ITERATOR3(item.iterator3 - 1));
        }
      }
    }
  });
  useMousetrap("up", () => {
    if (cart.open) {
      if (item.popUpIterator - itemsColumnLength > -1) {
        dispatch(SET_POPUPITERATOR(item.popUpIterator - itemsColumnLength));
      }
    }
    if (!itemsStates.isFlavoursAndToppingsOpen) {
      if (item.arrowKeyLevel === 0) {
        if (item.iterator - cardsColumnLength > -1) {
          dispatch(SET_ITERATOR(item.iterator - cardsColumnLength));
        }
      } else if (item.arrowKeyLevel === 1) {
        if (item.iterator2 - itemsColumnLength > -1) {
          dispatch(SET_ITERATOR2(item.iterator2 - itemsColumnLength));
        }
      } else if (item.arrowKeyLevel === 2) {
        if (item.iterator3 - itemsColumnLength > -1) {
          dispatch(SET_ITERATOR3(item.iterator3 - itemsColumnLength));
        }
      }
    }
  });
  useMousetrap("down", () => {
    if (cart.open) {
      if (
        item.popUpIterator + itemsColumnLength <
        // @ts-ignore
        item?.flavor[item.popUpSection]?.OptionsList?.length
      ) {
        dispatch(SET_POPUPITERATOR(item.popUpIterator + itemsColumnLength));
      }
    }
    if (!itemsStates.isFlavoursAndToppingsOpen) {
      if (item.arrowKeyLevel === 0) {
        if (item.iterator + cardsColumnLength < orderMenu.value?.length) {
          dispatch(SET_ITERATOR(item.iterator + cardsColumnLength));
        }
      } else if (item.arrowKeyLevel === 1) {
        if (
          item.iterator2 + itemsColumnLength <
          orderMenu.value[item.iterator]?.MenuItemsList?.length
        ) {
          dispatch(SET_ITERATOR2(item.iterator2 + itemsColumnLength));
        }
      } else if (item.arrowKeyLevel === 2) {
        if (
          item.iterator3 + itemsColumnLength <
          item.data?.MenuSizesList?.length
        ) {
          dispatch(SET_ITERATOR3(item.iterator3 + itemsColumnLength));
        }
      }
    }
  });

  useMousetrap("shift+w", () => {});

  useMousetrap("shift+d", () => {
    dispatch(SET_ADD_DISCOUNT_POPUP_PAGE(true));
  });

  useMousetrap(
    shortcuts_config.find_item_by_id !== undefined
      ? shortcuts_config.find_item_by_id
      : "shift+g",
    () => {
      if (!cart.open) {
        dispatch(SET_PROMPTBUTTON(true));
        dispatch(SET_COMINGFROM("Search"));
        dispatch(SET_TO_INITIAL_ITEMS_STATE());
      }
    }
  );

  useMousetrap("shift+b", () => {
    dispatch(SET_BARCODEPRINTPAGEPOPUP(true));
  });

  return <div></div>;
};

export default OrderShortcut;
