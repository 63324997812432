import React, { useState, useEffect } from 'react';
import QrReader from 'react-qr-scanner';

const QRScanner = (props) => {
  const [scannedResult, setScannedResult] = useState(null);

  useEffect(()=>{
    console.log("scanned result",scannedResult)
  },[scannedResult])

  const handleScan = (data) => {
    if (data) {
      setScannedResult(data);
    }
  };

  const handleError = (error) => {
    console.error('Error scanning QR code:', error);
  };

  return (
    <div>
      <QrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: '100%' }}
      />
      {scannedResult  &&  props.setQrCode(false) }
    </div>
  );
};

export default QRScanner;
