import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../..";

const CartPriceDetails = () => {
  const cart = useSelector((state: RootState) => state.cart);
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  return (
    <div
      className={`${
        darkMode
          ? `text-[${pos_config?.deals_details_color_darkmode}]`
          : `text-[${pos_config?.deals_details_color}]`
      }  flex flex-col justify-between p-[20px] w-full text-[14px] font-[600]`}
    >
      <div className={`flex justify-between`}>
        <div>Subtotal</div>
        <div>Rs. {cart.cartTotal} </div>
      </div>
      <div className={`flex justify-between`}>
        <div>GST</div>
        <div>{cart.taxAmount} </div>
      </div>
      <div className={`flex justify-between`}>
        <div>Discount</div>
        <div>Rs. {cart.discount}</div>
      </div>
      <div className={`flex justify-between`}>
        <div>Total</div>
        <div>Rs. {cart.discountedAndTaxedAmount} </div>
      </div>
    </div>
  );
};

export default CartPriceDetails;
