import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import { SET_SELECTEDHEADER } from "../../Features/rightBarHeader";
import { SET_SHOW_CUSTOMER_POPUP } from "../../Features/cart";

const FilterDiv = () => {
  const cart = useSelector((state: RootState) => state.cart);
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const leftBarHeader = useSelector((state: RootState) => state.leftHeader);
  const header = useSelector((state: RootState) => state.header);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const customer = useSelector((state: RootState) => state.customer);
  const dispatch = useDispatch();

  const handleCustomerClick = () => {
    if (leftBarHeader.selectedHeader === "Dine-in" || leftBarHeader.selectedHeader === "Pickup") {
      dispatch(SET_SHOW_CUSTOMER_POPUP(true))
      dispatch(SET_SELECTEDHEADER("Pickup"))
    }
    else {
      dispatch(SET_SELECTEDHEADER("Delivery"))
    }
  }

  const handleTableClick = () => {
    dispatch(SET_SELECTEDHEADER("Dine-in"))
  }

  return (
    <div
      className={`
    mr-[20px] ml-[20px] flex justify-between 
    h-[30px] lg:h-[35px] xl:h-[40px]
      `}
    >
      <div
        onClick={handleTableClick}
        className={`
        ${!darkMode
            ? `bg-[${pos_config?.primary_button_color}] text-[${pos_config?.primary_button_text_color}]`
            : `bg-[${pos_config?.primary_button_color_darkmode}] text-[${pos_config?.primary_button_text_color_darkmode}]`
          }
        ${darkMode
            ? `hover:bg-[${pos_config.primary_button_hover_color_darkmode}] hover:text-[${pos_config?.primary_button_hover_text_color_darkmode}] border-[#2b2c37]`
            : `hover:bg-[${pos_config.primary_button_hover_color}] hover:text-[${pos_config?.primary_button_hover_text_color}] border-[#E8E8E8]`
          }
        cursor-pointer border-[1px] font-[500] flex justify-center items-center
        xl:px-[30px] xl:rounded-[105px] xl:text-[14px]
        lg:px-[25px] lg:rounded-[90px] lg:text-[12px]
        px-[20px] rounded-[70px] text-[10px]
        `}
      >
        {leftBarHeader.selectedHeader === "Dine-in" &&
          `Table ${cart.currentTable === 0 ? "-" : cart.name}`}
        {leftBarHeader.selectedHeader === "Pickup" && `Pickup`}
        {leftBarHeader.selectedHeader === "Delivery" && `Delivery`}
      </div>
      <div
        onClick={handleCustomerClick}
        className={`${header.selectedHeader === "Delivery"
          ? !darkMode
            ? `bg-[${pos_config?.primary_button_hover_color}] text-[${pos_config?.primary_button_hover_text_color}]`
            : `bg-[${pos_config?.primary_button_hover_color_darkmode}] text-[${pos_config?.primary_button_hover_text_color_darkmode}]`
          : !darkMode
            ? `bg-[${pos_config?.primary_button_color}] text-[${pos_config?.primary_button_text_color}]`
            : `bg-[${pos_config?.primary_button_color_darkmode}] text-[${pos_config?.primary_button_text_color_darkmode}]`
          }
        ${darkMode
            ? `hover:bg-[${pos_config?.primary_button_hover_color_darkmode}] hover:text-[${pos_config?.primary_button_hover_text_color_darkmode}] border-[#2b2c37]`
            : `hover:bg-[${pos_config?.primary_button_hover_color}] hover:text-[${pos_config?.primary_button_hover_text_color}] border-[#E8E8E8]`
          }
        
        cursor-pointer border-[1px] font-[500] flex justify-center items-center
        xl:px-[30px] xl:rounded-[105px] xl:text-[14px]
        lg:px-[25px] lg:rounded-[90px] lg:text-[12px]
        px-[20px] rounded-[70px] text-[10px] truncate text-clip`}
      >
        {customer.data !== undefined ? customer.data.Name : "Customer"}

        {/* <div className="pl-[7px]">
          <IoIosArrowDropdown />
        </div> */}
      </div>
    </div>
  );
};

export default FilterDiv;
