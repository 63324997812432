import React, { useRef, useState } from "react";
import search from "../../../assets/RightBar/Search.png";
import filter from "../../../assets/RightBar/Filter.png";
import sort from "../../../assets/RightBar/Sort.png";
import { useDispatch, useSelector } from "react-redux";
import { SET_SEARCHED_ORDER } from "../../../Features/cart";
import SelectOrderFilter from "./SelectOrderFilter";
import { BiSearch } from "react-icons/bi";
import { RootState } from "../../..";

const OrderHeader = () => {
  const dispatch = useDispatch();
  const [searchField, setSearchField] = useState("");
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);

  const handleChange = (e: any) => {
    dispatch(SET_SEARCHED_ORDER(e.target.value));
  };

  const ref = useRef(null);

  return (
    <div
      className={`${
        darkMode ? "text-white" : "text-black"
      } w-full flex flex-col sm:flex-row items-center justify-between px-[20px] py-[10px]`}
    >
      <div
        id="container"
        className={`${
          darkMode ? "bg-[#111827] border-[#2b2c37] border-[1px]" : "bg-white"
        } border w-full flex items-center justify-start h-[56px] rounded-full px-[20px] mr-[20px] mb-4 sm:mb-0`}
      >
        <div className="w-[20xp] h-[20px] flex items-center justify-center">
          <BiSearch />
        </div>
        <input
          ref={ref}
          id="search"
          // onFocus={handleFocus}
          onChange={handleChange}
          // onBlur={handleBlur}
          className={`${
            darkMode ? "bg-[#111827]" : "bg-white"
          } w-full h-full ml-[20px] outline-none font-[14px]`}
          placeholder="Search by Orders ID"
          type="text"
        />
      </div>
      <div className="">
        <SelectOrderFilter />
      </div>
    </div>
  );
};

export default OrderHeader;
