import {
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import {
  SET_BARCODEPRINTPAGEPOPUP,
  SET_ROUTE,
} from "../../Features/rightBarHeader";

const BarcodePrintPagePopUp = () => {
  const dispatch = useDispatch();
  const rightBarHeader = useSelector((state: RootState) => state.header);

  return (
    <div>
      <Modal
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: "10px",
          minWidth: 400,
          border: 0,
        }}
        disableAutoFocus={true}
        open={rightBarHeader.bardcodePrintPagePopUp}
        onClose={() => dispatch(SET_BARCODEPRINTPAGEPOPUP(false))}
      >
        <div className="bg-[#FFFFFF] p-[20px] mt-[0px] rounded-[12px] flex-col items-center justify-between border-none w-[450px] mx-auto select-none">
          <div className="flex items-center my-auto justify-between">
            <div className="w-full h-full">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Print Variant
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Print Variant"
                  fullWidth
                >
                  <MenuItem key={"0"} value={20}>
                    20 per Page
                  </MenuItem>
                  <MenuItem key={"1"} value={40}>
                    40 per Page
                  </MenuItem>
                  <MenuItem key={"2"} value={50}>
                    50 per Page
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="flex flex-row justify-around mt-[20px]">
            <div
              onClick={() => {
                dispatch(SET_BARCODEPRINTPAGEPOPUP(false));
                dispatch(SET_ROUTE("/BarcodePrintPage"));
              }}
              className="cursor-pointer w-[165px] h-[46px] rounded-[62px] flex items-center justify-center bg-[#EF233C] text-[#FFFFFF] text-[16px] font-[700]"
            >
              Preview
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BarcodePrintPagePopUp;
