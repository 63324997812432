// @ts-nocheck
import React, { useEffect, useState } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import Size from "./Size";
import axios from "axios";
import { Modal } from "@mui/material";
import PopUp from "./PopUp";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_MENU_ITEM_TO_CURRENT_ORDER,
  handleOpen,
  INITIALIZE_MENU_ITEM,
  SET_ISEDITING_ADDON,
} from "../../Features/cart";
import { RootState } from "../..";
import IItemData from "../../Interfaces/IItemData";
// @ts-ignore
import useMousetrap from "react-hook-mousetrap";
// @ts-ignore
import { useIsFirstRender } from "usehooks-ts";
import {
  INCREMENT_COUNTER,
  SET_COMINGFROM,
  SET_ITEMS_STATES_DATA,
  SET_ITERATOR2,
  SET_ARROWKEYLEVEL,
  SET_TO_INITIAL_ITEMS_STATE,
} from "../../Features/ItemsStates";
import { BaseService } from "../../assets/BaseService";
import { SET_PRODUCT_CONSUMPTION_DETAILS } from "../../Features/OrderConsumption";

const Items = (props: { category: string; data: Array<IItemData> }) => {
  const dispatch = useDispatch();
  const isFirst = useIsFirstRender();

  const [checkedItem, setCheckedItem] = useState<number>(0);
  const [itemPrice, setItemPrice] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [wait, setWait] = useState(false);
  const [data2, setData2] = useState(null);
  const [isFromSearchHotkey, setIsFromSearchHotkey] = useState(false);

  const cart = useSelector((state: RootState) => state.cart);
  const rightBarHeader = useSelector((state: RootState) => state.header);
  const leftBarHeader = useSelector((state: RootState) => state.leftHeader);
  const itemsStates = useSelector((state: RootState) => state.itemStates);
  const menu = useSelector((state: RootState) => state.orderMenu.value);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);

  const handleClick = (item: any, i: number) => {
    // console.log("remoteCode", item._id);
    if (
      !(
        cart.selectedOrderStatus === "DISPATCHED" ||
        cart.selectedOrderStatus === "SUSPENDED" ||
        cart.selectedOrderStatus === "RETURNED"
      )
    ) {
      dispatch(SET_COMINGFROM("Click"));
      dispatch(SET_TO_INITIAL_ITEMS_STATE());
      dispatch(SET_ITERATOR2(i));
      dispatch(SET_ISEDITING_ADDON(false));
      setIsFromSearchHotkey(false);
      setData2(null);
      if (props.data[i].MenuSizesList === null) {
        dispatch(
          INITIALIZE_MENU_ITEM({
            itemName: item.Name,
            price: item.DiscountedPrice,
            category: props.category,
            quantity: 1,
            MinimumDelivery: props.data[i].MinimumDelivery || 0,
            sizeID: 0,
            //@ts-ignore
            itemID: item.ID,
            _id: item._id,
            objArray: [],
          })
        );
        dispatch(ADD_MENU_ITEM_TO_CURRENT_ORDER());

        BaseService.post(
          "/dynamic/get",
          {
            collection: "product-recipe",
            filter: {
              "product.label": item.Name,
            },
          },
          {}
        ).then((res) => {
          // console.log("Product Recipe", res.data);
          if (res.data.data[0]) {
            // console.log("Product Recipe in if");
            dispatch(SET_PRODUCT_CONSUMPTION_DETAILS(res.data.data[0]));
          }
        });

        dispatch(SET_ARROWKEYLEVEL(1));
      } else {
        setIsLoading(true);
        setWait(false);

        // console.log("Data from Items: ",props.data[i])

        dispatch(
          SET_ITEMS_STATES_DATA({
            name: item.Name,
            quantity: 1,
            data: props.data[i],
            size: props.data[i].MenuSizesList
              ? props.data[i].MenuSizesList?.length
              : 0,
            flavor: props.data[i].MenuSizesList[0].FlavourAndToppingsList,
            itemPrice: item.DiscountedPrice,
            itemID: item.ID,
            MinimumDelivery: props.data[i].MinimumDelivery,
            sizeID: props.data[i].MenuSizesList[0].ID,
            category: props.category,
          })
        );
        dispatch(SET_ISEDITING_ADDON(false))
        setIsLoading(false);
        setCheckedItem(i);

        if (props.data[i].MenuSizesList) {
          if (props.data[i].MenuSizesList?.length === 1)
            dispatch(handleOpen(true));
        }
        setWait(true);
      }
    }
  };

  useEffect(() => {
    if (!isFirst) {
      if (itemsStates.arrowKeyLevel > 0) {
        handleClick(props.data[itemsStates.iterator2], itemsStates.iterator2);
      }
    }
  }, [itemsStates.trigger]);

  useEffect(() => {
    if (itemsStates.arrowKeyLevel === 1) {
      setCheckedItem(itemsStates.iterator2);
    }
  }, [itemsStates.iterator2]);

  const func = async () => {
    if (
      !(
        cart.selectedOrderStatus === "DISPATCHED" ||
        cart.selectedOrderStatus === "SUSPENDED" ||
        cart.selectedOrderStatus === "RETURNED"
      )
    ) {
      menu.map((val: any) => {
        val.MenuItemsList.map((innerVal: any) => {
          if (innerVal.MenuSizesList === null) {
            if (innerVal.ID === rightBarHeader.itemID) {
              // console.log(
              //   "remoteCode",
              //   rightBarHeader.itemID,
              //   rightBarHeader.batchNumber,
              //   innerVal._id
              // );

              dispatch(
                INITIALIZE_MENU_ITEM({
                  itemName: innerVal.Name,
                  price: innerVal.DiscountedPrice,
                  quantity: 1,
                  category: props.category,
                  MinimumDelivery: innerVal.MinimumDelivery || 0,
                  sizeID: 0,
                  //@ts-ignore
                  itemID: innerVal.ID,
                  _id: innerVal._id,
                  batchNumber: rightBarHeader.batchNumber,
                  objArray: [],
                })
              );
              dispatch(ADD_MENU_ITEM_TO_CURRENT_ORDER());
            }
          } else if (innerVal.MenuSizesList.length === 1) {
            if (innerVal.ID === rightBarHeader.itemID) {
              // console.log(innerVal)
              dispatch(
                SET_ITEMS_STATES_DATA({
                  name: innerVal.Name,
                  data: innerVal,
                  size: innerVal.MenuSizesList?.length,
                  flavor: innerVal.MenuSizesList[0]?.FlavourAndToppingsList,
                  itemPrice: innerVal.MenuSizesList[0]?.DiscountedPrice,
                  itemID: innerVal.ID,
                  batchNumber: rightBarHeader.batchNumber,
                  MinimumDelivery: innerVal.MinimumDelivery,
                  sizeID: innerVal.MenuSizesList[0]?.ID,
                  category: props.category,
                })
              );
              dispatch(handleOpen(true));
              dispatch(SET_ISEDITING_ADDON(false))

            }
          } else {
            innerVal.MenuSizesList.map((veryInside: any) => {
              if (
                veryInside.ID.toString() === rightBarHeader.itemID?.toString()
              ) {
                dispatch(
                  SET_ITEMS_STATES_DATA({
                    name: innerVal.Name,
                    data: innerVal,
                    size: innerVal.MenuSizesList?.length,
                    flavor: veryInside?.FlavourAndToppingsList,
                    itemPrice: veryInside?.DiscountedPrice,
                    itemID: rightBarHeader.itemID,
                    MinimumDelivery: innerVal.MinimumDelivery,
                    sizeID: veryInside.ID,
                    category: props.category,
                  })
                );

                dispatch(INCREMENT_COUNTER());
                
              }
            });
          }
        });
      });
    }

    setCheckedItem(null);
    setItemPrice(itemPrice);
  };

  useEffect(() => {
    if (!isFirst) {
      func();
    }
  }, [rightBarHeader.sendRequest]);

  return (
    <div id="items-div" className="p-4">
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4">
        {props.data.map((item: IItemData, i: number) => (
          <div
            key={i}
            className={`
              relative p-4 rounded-lg flex flex-col justify-between
              transition-all duration-300 ease-in-out cursor-pointer
              ${
                checkedItem === i
                  ? darkMode
                    ? `bg-[${pos_config?.item_button_hover_color_darkmode}] text-[${pos_config?.item_button_hover_text_color_darkmode}] shadow-lg`
                    : `bg-[${pos_config?.item_button_hover_color}] text-[${pos_config?.item_button_hover_text_color}] shadow-lg`
                  : darkMode
                  ? `bg-[${pos_config?.item_button_color_darkmode}] text-[${pos_config?.item_button_text_color_darkmode}]`
                  : `bg-[${pos_config?.item_button_color}] text-[${pos_config?.item_button_text_color}]`
              }
              hover:shadow-md hover:scale-105
            `}
            onClick={() => handleClick(item, i)}
          >
            {checkedItem === i && (
              <AiFillCheckCircle className="absolute top-2 right-2 text-lg" />
            )}
            <div className="font-medium text-sm mb-2 line-clamp-2">{item.Name}</div>
            <div className="text-xs font-bold mt-auto">
              {item.DiscountedPrice !== 0 &&
                (item.Discount === 0 ? (
                  <div>Rs {item.DiscountedPrice}</div>
                ) : (
                  <div className="flex flex-col">
                    <span className="line-through text-opacity-70">
                      Rs {item.Discount + item.DiscountedPrice}
                    </span>
                    <span>Rs {item.DiscountedPrice}</span>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
      <div className="mt-4">
        {!isLoading && itemsStates.size > 1 ? (
          <Size />
        ) : (
          cart.open &&
          !isLoading &&
          itemsStates.data &&
          itemsStates.data?.MenuSizesList[0]?.FlavourAndToppingsList?.length > 0 && (
            <PopUp />
          )
        )}
      </div>
    </div>
  );
};

export default Items;
