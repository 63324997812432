import React, { useEffect, useRef, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { BsArrowDownUp } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../..";
import {
  SET_CUSTOMER_DATA,
  SET_IS_LOADING,
  SET_IS_READY,
} from "../../../Features/customer";
import { createNewBaseService } from "../../../assets/BaseService";

interface SearchbarProps {
  numberSetter: (phoneNumber: string) => void;
}

const SearchBar: React.FC<SearchbarProps> = ({ numberSetter }) => {
  const ref = useRef(null);
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const token = localStorage.getItem("access_token");
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const [inputFieldText, setInputFieldText] = useState<string>("");
  const dispatch = useDispatch();
  const NewBaseService = createNewBaseService(pos_config)

  useEffect(() => {
    if (document.location.search.includes("customer")) {
      const number = document.location.search.substring(10, 21);
      setInputFieldText(number);
    }
  }, [document.location.search]);

  const handleFocus = () => {
    document
      .getElementById("container")
      ?.classList.add("border", "caret-yellow-500", "caret-w-4");
  };
  const handleBlur = () => {
    document
      .getElementById("container")
      ?.classList.remove("caret-yellow-500", "caret-w-4");
  };
  const handleInputChange = (e: any) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length <= 11) setInputFieldText(onlyNums);
  };
  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (event.key === "Enter") {
      // Handle the Enter key press here
      numberSetter(inputFieldText);
    }
  };
  const getCustomers = async (inputValue: string) => {
    dispatch(SET_IS_LOADING(true));
    await NewBaseService.get(
      `/dynamic/query/get-customer-with-orders-by-phone-number?phoneNo=${inputValue}`,
      { params: { collection: "customers" } }
    ).then((res) => {
      let data = res.data.data[0];
      dispatch(SET_CUSTOMER_DATA(data));
      dispatch(SET_IS_READY(true));
    });
    dispatch(SET_IS_LOADING(false));
  };

  useEffect(() => {
    if (inputFieldText && inputFieldText.length === 11) {
      dispatch(SET_CUSTOMER_DATA(undefined));
      getCustomers(inputFieldText);
      numberSetter(inputFieldText);
    }
  }, [inputFieldText]);

  return (
    <div
      className={`${darkMode ? "text-white" : "text-black"
        } w-full h-fit flex items-center justify-between px-[20px]`}
    >
      <div
        id="container"
        className={`${darkMode ? "border-[#2b2c37]" : "border-[#E8E8E8]"} border-[1px]  w-full flex items-center justify-start h-[56px] rounded-full  px-[20px] mr-[20px]`}
      >
        <div
          className={`
        ${darkMode ? "text-white" : "text-black"}
        w-[20xp] h-[20px] flex items-center justify-center`}
        >
          <BiSearch />
        </div>
        <input
          ref={ref}
          id="search"
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          value={inputFieldText}
          autoFocus
          className={`
          ${darkMode ? "bg-[#111827] text-white" : "bg-white text-black"}
          w-full h-full ml-[20px] outline-none font-[14px] rounded-md`}
          placeholder="Enter Customer Phone Number"
          type="text"
        />
      </div>
      <div
        onClick={() => {
          dispatch(SET_CUSTOMER_DATA(undefined));
        }}
        className={`flex items-center justify-center h-[48px] rounded-full border px-[16px] border-[${pos_config.primary_button_hover_color}] bg-[${pos_config.primary_button_hover_color}] cursor-pointer text-white gap-2 hover:scale-110 transition duration-200 ease-in-out`}
      >
        <div className="font-bold text-[18px] text-inherit">Clear</div>
        <div className="w-full h-[48px] flex items-center justify-center text-inherit">
          <MdDeleteForever size={25} />
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
