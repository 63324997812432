import { useEffect, useRef, useState } from "react";
import {
  RiDeleteBin7Line,
  RiAddFill,
  RiSubtractFill,
  RiInformationFill,
  RiArrowUpSFill,
} from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  REMOVE_ITEM_FROM_ORDER,
  SET_EDITING_ADDON,
  SET_ISEDITING_ADDON,
  UPDATE_ITEM_QUANTITY,
  handleOpen,
} from "../../Features/cart";
import IDealsList from "../../Interfaces/IDealsList";
import { RootState } from "../..";
import { Modal } from "@mui/material";
import { DELETE_PRODUCT_CONSUMPTION_DETAIL } from "../../Features/OrderConsumption";
import {
  SET_FLAVOURS_AND_TOPPINGS_OPEN,
  SET_ITEMS_STATES_DATA,
  SET_POPUPITERATOR,
  SET_UPDATEDADDONITEMINDEX,
} from "../../Features/ItemsStates";
import { FaBan } from "react-icons/fa";

const DealsList = (props: { item: IDealsList; index: number }) => {
  const [selected, setSelected] = useState(true);
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const dispatch = useDispatch();
  const cart = useSelector((state: RootState) => state.cart);
  const [openSizePopup, setOpenSizePopup] = useState(false);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const [quantity, setQuantity] = useState(props.item.quantity);
  const prevQuantityRef = useRef<number>(props.item.quantity);
  const orderMenu = useSelector((state: RootState) => state.orderMenu);

  useEffect(() => {
    prevQuantityRef.current = quantity;
  }, [quantity]);

  const handleAddonClick = (addon: any, items: any) => {
    if (items.isComingFromAPI) {
      return;
    }
    dispatch(SET_ISEDITING_ADDON(true));
    const selectedCategory = orderMenu.value.filter(
      (Item: any) => Item.Name === items.categoryName
    );
    const selectedItem = selectedCategory[0].MenuItemsList.filter(
      (Item: any) => Item.ID === items.itemID
    );

    let selectedSize;
    if (items.sizeID) {
      //@ts-ignore
      selectedSize = selectedItem[0].MenuSizesList.filter(
        (size: any) => size.ID === items.sizeID
      )[0];
    } else {
      //@ts-ignore
      selectedSize = selectedItem[0].MenuSizesList[0];
    }

    dispatch(
      SET_ITEMS_STATES_DATA({
        name: items.itemName,
        quantity: items.quantity,
        data: selectedItem[0],
        //@ts-ignore
        size: selectedItem[0].MenuSizesList
          ? //@ts-ignore
            selectedItem[0].MenuSizesList?.length
          : 0,
        flavor: selectedSize.FlavourAndToppingsList,
        itemPrice: items.itemPrice,
        itemID: items.itemID,
        //@ts-ignore
        MinimumDelivery: selectedItem[0]?.MinimumDelivery || 0,
        //@ts-ignore
        sizeID: selectedSize.ID,
        category: selectedCategory[0].Name,
      })
    );

    dispatch(
      SET_EDITING_ADDON({
        addOns: items.addOns,
        itemName: items.itemName,
        categoryName: items.categoryName,
        itemID: items.itemID,
        index: props.index,
      })
    );

    const addonIndex =
      //@ts-ignore
      selectedSize.FlavourAndToppingsList.filter(
        (a: any) => a.Name === addon[0].objGroupName
      );
    const optionIndex = addonIndex[0].OptionsList.findIndex(
      (a: any) => a.Name === addon[0].name
    );
    dispatch(SET_FLAVOURS_AND_TOPPINGS_OPEN(true));
    dispatch(SET_UPDATEDADDONITEMINDEX(optionIndex));
    dispatch(SET_POPUPITERATOR(optionIndex));
    dispatch(handleOpen(true));
  };

  return (
    <div
      className={`${
        props.item.isComingFromAPI ? "bg-red-100" : "bg-green-100"
      } rounded-lg gap-2`}
    >
      <div
        className={`flex items-start justify-between p-[20px] w-full ${
          selected && "hidden"
        }`}
      >
        <div className="flex">
          <div
            className={`mt-[3px] rounded-[4px] p-[3px] bg-[#FF0000] h-[25px] cursor-pointer
            ${
              darkMode
                ? `text-[${pos_config?.deals_bar_text_color_darkmode}]`
                : `text-white border-[1px]`
            }
            `}
            onClick={() => {
              if (
                !(
                  cart.selectedOrderStatus === "DISPATCHED" ||
                  cart.selectedOrderStatus === "SUSPENDED" ||
                  cart.selectedOrderStatus === "RETURNED"
                )
              ) {
                dispatch(
                  REMOVE_ITEM_FROM_ORDER({
                    index: props.index,
                    total: props.item.total,
                  })
                );
                dispatch(
                  DELETE_PRODUCT_CONSUMPTION_DETAIL(props.item.itemName)
                );
              }
            }}
          >
            <RiDeleteBin7Line />
          </div>
          <div className="flex flex-col  w-[100%] pl-[16px] pr-[25px]">
            <div
              className="flex font-[700] text-[16px] cursor-pointer"
              onClick={() => {
                if (
                  !(
                    cart.selectedOrderStatus === "DISPATCHED" ||
                    cart.selectedOrderStatus === "SUSPENDED" ||
                    cart.selectedOrderStatus === "RETURNED"
                  )
                ) {
                  setOpenSizePopup(true);
                }
              }}
            >
              <div
                className={`${
                  darkMode
                    ? `text-[${pos_config?.deals_bar_text_color_darkmode}]`
                    : `text-[${pos_config?.deals_bar_text_color}]`
                }`}
              >
                {props.item?.itemName}
              </div>
            </div>

            <div
              className={`${
                darkMode
                  ? `text-[${pos_config?.deals_bar_quanitityxprice_color_darkmode}]`
                  : `text-[${pos_config?.deals_bar_quanitityxprice_color}]`
              } text-[13px] font-[500]`}
            >
              {props.item?.quantity} x Rs.{props.item?.itemPrice}
            </div>
            <div
              onClick={() => {
                setSelected(!selected);
              }}
              className={`cursor-pointer flex justify-between text-[12px] font-[500] rounded-[22px] items-center px-[8px] py-[3px] w-[110px]
              ${
                darkMode
                  ? "bg-[#878787] text-[#E8E8E8]"
                  : "bg-[#E8E8E8] text-[#878787]"
              }
              `}
            >
              <div className="">
                <RiInformationFill />
              </div>
              <div className="underline">What’s in Deal</div>
            </div>
          </div>
        </div>
        <div className="flex gap-3 text-[#111827] justify-between mt-[8px] items-center">
          <div
            className=" bg-[#878787] rounded-[2px] text-white flex items-center p-[1px] h-[20px] cursor-pointer"
            onClick={() => {
              if (props.item.quantity > 1) {
                if (
                  !(
                    cart.selectedOrderStatus === "DISPATCHED" ||
                    cart.selectedOrderStatus === "SUSPENDED" ||
                    cart.selectedOrderStatus === "RETURNED"
                  )
                ) {
                  dispatch(
                    UPDATE_ITEM_QUANTITY({
                      price: props.item.total,
                      quantity: props.item.quantity - 1,
                      index: props.index,
                    })
                  );
                  setQuantity(quantity - 1);
                }
              }
            }}
          >
            <RiSubtractFill />
          </div>
          <div
            className={`font-[700] text-[13px] text-black bg-[${
              darkMode ? "#878787" : "#F2F2F2"
            }] rounded-[50%] p-[5px] px-[10px]`}
          >
            {props.item?.quantity}
          </div>
          <div
            className=" bg-[#06D6A0] rounded-[2px] text-white flex items-center h-[20px] cursor-pointer"
            onClick={() => {
              if (
                !(
                  cart.selectedOrderStatus === "DISPATCHED" ||
                  cart.selectedOrderStatus === "SUSPENDED" ||
                  cart.selectedOrderStatus === "RETURNED"
                )
              ) {
                dispatch(
                  UPDATE_ITEM_QUANTITY({
                    price: props.item.total,
                    quantity: props.item.quantity + 1,
                    index: props.index,
                  })
                );
                setQuantity(quantity + 1);
              }
            }}
          >
            <RiAddFill />
          </div>
        </div>
      </div>
      <div
        className={`flex items-start justify-between w-full pr-[30px] gap-3 p-[20px] pb-[10px] ${
          !selected && "hidden"
        } `}
      >
        <div className="flex w-full gap-3 ">
          <div
            className={`mt-[3px] rounded-[4px] p-[3px] bg-[#FF0000] h-[25px] cursor-pointer
            ${
              darkMode
                ? `text-[${pos_config?.deals_bar_text_color_darkmode}]`
                : `text-white border-[1px]`
            }
            `}
            onClick={() => {
              if (
                !(
                  cart.selectedOrderStatus === "DISPATCHED" ||
                  cart.selectedOrderStatus === "SUSPENDED" ||
                  cart.selectedOrderStatus === "RETURNED"
                )
              ) {
                dispatch(
                  REMOVE_ITEM_FROM_ORDER({
                    index: props.index,
                    total: props.item.total,
                  })
                );
                dispatch(
                  DELETE_PRODUCT_CONSUMPTION_DETAIL(props.item.itemName)
                );
              }
            }}
          >
            <RiDeleteBin7Line />
          </div>
          <div className="w-full">
            <div className="flex  w-full justify-between">
              <div className="flex flex-col justify-between font-[400] text-[11px] text-[#878787]">
                <div
                  className={`${
                    darkMode
                      ? `text-[${pos_config?.deals_details_color_darkmode}]`
                      : `text-[${pos_config?.deals_details_color}]`
                  } font-[700] text-[16px] cursor-pointer`}
                  onClick={() => {
                    if (
                      !(
                        cart.selectedOrderStatus === "DISPATCHED" ||
                        cart.selectedOrderStatus === "SUSPENDED" ||
                        cart.selectedOrderStatus === "RETURNED"
                      )
                    ) {
                      setOpenSizePopup(true);
                    }
                  }}
                >
                  {props.item?.itemName}
                </div>
                <div
                  onClick={() =>
                    handleAddonClick(props.item.addOns, props.item)
                  }
                >
                  {props.item?.addOns.map((obj: any, i: any) => (
                    <div
                      className={`${
                        darkMode
                          ? `text-[${pos_config?.deals_details_color_darkmode}]`
                          : `text-[${pos_config?.deals_details_color}]`
                      } cursor-pointer`}
                      key={i}
                    >
                      {obj.name}
                      {props.item.isComingFromAPI && ( // Render the block sign if isComingFromAPI is true
                        <div className="absolute top-0 right-0 opacity-0 hover:opacity-100 transition-opacity duration-300">
                          <FaBan className="text-red-500" />
                        </div>
                      )}
                    </div>
                  ))}{" "}
                </div>
              </div>
              <div className="flex flex-col justify-between font-[400] text-[11px] text-[#878787]">
                <div
                  className={`${
                    darkMode
                      ? `text-[${pos_config?.deals_details_color_darkmode}]`
                      : `text-[${pos_config?.deals_details_color}]`
                  }  font-[700] text-[16px]`}
                >
                  {props.item?.itemPrice === 0
                    ? null
                    : `${props.item?.quantity} x ${props.item?.itemPrice}`}
                </div>
                <div>
                  {props.item?.addOns.map((obj: any, i: any) => (
                    <div
                      className={`${
                        darkMode
                          ? `text-[${pos_config?.deals_details_color_darkmode}]`
                          : `text-[${pos_config?.deals_details_color}]`
                      } `}
                      key={i}
                    >
                      {obj.quantity} x {obj.price}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              className={`${
                darkMode
                  ? `text-[${pos_config?.deals_details_color_darkmode}]`
                  : `text-[${pos_config?.deals_details_color}]`
              }  flex w-[70px] justify-between mt-[8px]`}
            >
              <div
                className="cursor-pointer"
                onClick={() => {
                  if (props.item.quantity > 1) {
                    if (
                      !(
                        cart.selectedOrderStatus === "DISPATCHED" ||
                        cart.selectedOrderStatus === "SUSPENDED" ||
                        cart.selectedOrderStatus === "RETURNED"
                      )
                    ) {
                      dispatch(
                        UPDATE_ITEM_QUANTITY({
                          price: props.item.itemPrice,
                          quantity: quantity - 1,
                          index: props.index,
                        })
                      );
                      setQuantity(quantity - 1);
                    }
                  }
                }}
              >
                <RiSubtractFill />
              </div>
              <div className="font-[700] text-[13px] text-[#878787]">
                {props.item?.quantity}
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  if (
                    !(
                      cart.selectedOrderStatus === "DISPATCHED" ||
                      cart.selectedOrderStatus === "SUSPENDED" ||
                      cart.selectedOrderStatus === "RETURNED"
                    )
                  ) {
                    dispatch(
                      UPDATE_ITEM_QUANTITY({
                        price: props.item.itemPrice,
                        quantity: quantity + 1,
                        index: props.index,
                      })
                    );
                    setQuantity(quantity + 1);
                  }
                }}
              >
                <RiAddFill />
              </div>
            </div>
            <div
              className={`${
                darkMode ? "text-white" : "text-[#111827]"
              } w-[10px] cursor-pointer `}
              onClick={() => {
                setSelected(!selected);
              }}
            >
              <RiArrowUpSFill />
            </div>
          </div>
        </div>
        <div
          className={`${
            darkMode
              ? `text-[${pos_config?.deals_bar_text_color_darkmode}]`
              : `text-[${pos_config?.deals_bar_text_color}]`
          } font-[700] text-[13px]`}
        >
          Rs{props.item?.orderTotal}
        </div>
      </div>
      <Modal
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          minWidth: 400,
          border: 0,
        }}
        disableAutoFocus={true}
        open={openSizePopup}
        onClose={() => setOpenSizePopup(false)}
      >
        <div
          className={`bg-[${
            !darkMode
              ? pos_config?.popup_color
              : pos_config?.popup_color_darkmode
          }] p-[32px] mt-[100px] rounded-[12px] flex-col items-center justify-between border-none w-[450px] mx-auto select-none`}
        >
          <div
            className={`text-[${
              !darkMode
                ? pos_config?.popup_text_color
                : pos_config?.popup_text_color_darkmode
            }] text-[24px] font-[700] text-center flex`}
          >
            <div>Quantity:</div>

            <div className="border-2 border-black p-[5px] mr-[5px] w-[100px] rounded-[10px]">
              <input
                defaultValue={quantity}
                autoFocus
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (
                      !(
                        cart.selectedOrderStatus === "DISPATCHED" ||
                        cart.selectedOrderStatus === "SUSPENDED" ||
                        cart.selectedOrderStatus === "RETURNED"
                      )
                    ) {
                      dispatch(
                        UPDATE_ITEM_QUANTITY({
                          price: props.item.itemPrice,
                          quantity: quantity,
                          index: props.index,
                        })
                      );
                      setOpenSizePopup(false);
                    }
                  }
                }}
                type="number"
                className={`bg-[${
                  !darkMode
                    ? pos_config?.popup_color
                    : pos_config?.popup_color_darkmode
                }] w-[80px] focus:outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                onChange={(e) => {
                  setQuantity(parseInt(e.target.value));
                }}
              />
            </div>
          </div>

          <div className="flex flex-row justify-around pt-[20px]">
            <div
              className={`cursor-pointer w-[165px] h-[46px] rounded-[62px] flex items-center justify-center bg-[${
                !darkMode
                  ? pos_config?.popup_secondary_button_color
                  : pos_config?.popup_secondary_button_color_darkmode
              }] text-[${
                !darkMode
                  ? pos_config?.popup_secondary_button_text_color
                  : pos_config?.popup_secondary_button_text_color_darkmode
              }] text-[16px] font-[700]`}
              onClick={() => {
                if (
                  !(
                    cart.selectedOrderStatus === "DISPATCHED" ||
                    cart.selectedOrderStatus === "SUSPENDED" ||
                    cart.selectedOrderStatus === "RETURNED"
                  )
                ) {
                  dispatch(
                    UPDATE_ITEM_QUANTITY({
                      price: props.item.itemPrice,
                      quantity: quantity,
                      index: props.index,
                    })
                  );
                }
                setOpenSizePopup(false);
              }}
            >
              OK
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DealsList;
