import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import cart, {
  SET_VOUCHER_DISCOUNT,
  SET_MIN_VOUCHER_ORDER_AMOUNT,
  SET_VOUCHER,
} from "../../Features/cart";
import { Modal } from "@mui/material";
import axios from "axios";
import { createNewBaseService } from "../../assets/BaseService";

const PromoBar = () => {
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const dispatch = useDispatch();
  const [voucher, setVoucher] = useState("");
  const [invalidVoucherError, setInvalidVoucherError] = useState(false);
  const [validVoucherPrompt, setValidVoucherPrompt] = useState(false);
  const [minVoucherAmountPrompt, setMinVoucherAmountPrompt] = useState(false);
  const token = localStorage.getItem("access_token");
  const cart = useSelector((state: RootState) => state.cart);
  const [minOrderAmount, setMinOrderAmount] = useState(0);
  const voucherCode = useSelector((state: RootState) => state.cart.voucher);

  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );

  const NewBaseService = createNewBaseService(pos_config);

  useEffect(() => {
    setVoucher(voucherCode);
  }, [voucherCode]);

  const isVoucherValid = (voucherData: any, currentBranch: string) => {
    const currentDate = new Date();
    const startDate = new Date(voucherData.startdate);
    const endDate = new Date(voucherData.enddate);

    const branchesArray = JSON.parse(voucherData.startdate);
    const branchMatch = branchesArray.some(
      (branch: { value: string }) => branch.value === currentBranch
    );
    return (
      voucherData.isActive === "True" &&
      currentDate >= startDate &&
      currentDate <= endDate &&
      branchMatch
    );
  };

  return (
    <div className="h-[30px] lg:h-[35px] xl:h-[45px] flex gap-2 ">
      <div
        className={`${
          !darkMode
            ? "bg-white text-black "
            : "bg-[#111827] text-white border-[#2b2c37]"
        } border-[1px] flex items-center   
        px-[3px] rounded-[30px]
        lg:px-[5px] lg:rounded-[40px]
        xl:px-[10px] xl:rounded-[60px]
        `}
      >
        <input
          className={`${
            !darkMode ? "bg-white text-black" : "bg-[#111827] text-white"
          }  focus:outline-none 
          ml-[5px]
          lg:ml-[10px] 
          xl:ml-[20px] 
          `}
          type="text"
          value={voucher}
          placeholder="Enter promo code"
          onChange={(e) => {
            setVoucher(e.target.value);
            dispatch(SET_VOUCHER(e.target.value));
            if (e.target.value === "") {
              dispatch(SET_VOUCHER_DISCOUNT(0));
              dispatch(SET_VOUCHER(""));
            }
          }}
        />
      </div>
      <div
        className={`${
          !darkMode
            ? `bg-[${pos_config?.primary_button_color}] text-[${pos_config?.primary_button_text_color}]`
            : `bg-[${pos_config?.primary_button_color_darkmode}] text-[${pos_config?.primary_button_text_color_darkmode}]`
        }
        ${
          darkMode
            ? `hover:bg-[${pos_config.primary_button_hover_color_darkmode}] hover:text-[${pos_config?.primary_button_hover_text_color_darkmode}] border-[#2b2c37]`
            : `hover:bg-[${pos_config.primary_button_hover_color}] hover:text-[${pos_config?.primary_button_hover_text_color}] border-[#E8E8E8]`
        }
        border-[1px] flex items-center cursor-pointer
        px-[10px] text-[10px] rounded-[20px]
        lg:px-[15px] lg:text-[12px] lg:rounded-[40px]  
        xl:px-[30px] xl:text-[16px] xl:rounded-[68px] 
        `}
        onClick={async () => {
          let voucherData: Array<any> = [];
          await NewBaseService.post(
            `/dynamic/get`,
            {
              collection: "vouchers",
              filter: {
                voucherCode: voucher,
              },
            },
            {}
          ).then((res) => {
            console.log("Voucher API response", res);
            voucherData = res.data.data;
          });
          if (voucherData.length > 0) {
            const currentBranch = cart.loginData.branchName;
            const isValid = isVoucherValid(voucherData[0], currentBranch);
            if (isValid) {
              setMinOrderAmount(voucherData[0].minOrderAmount);
              dispatch(
                SET_MIN_VOUCHER_ORDER_AMOUNT(voucherData[0].minOrderAmount)
              );
              if (cart.cartTotal >= voucherData[0].minOrderAmount) {
                dispatch(SET_VOUCHER_DISCOUNT(voucherData[0].discount));
                dispatch(SET_VOUCHER(voucher));
                setValidVoucherPrompt(true);
              } else {
                console.log(
                  "min ",
                  voucherData[0].minOrderAmount,
                  minOrderAmount
                );
                dispatch(SET_VOUCHER_DISCOUNT(0));
                setMinVoucherAmountPrompt(true);
              }
            }
            else{
              dispatch(SET_VOUCHER_DISCOUNT(0));
              setInvalidVoucherError(true)
            }
          } else {
            dispatch(SET_VOUCHER_DISCOUNT(0));
            setInvalidVoucherError(true);
          }
        }}
      >
        Apply
      </div>
      <Modal
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          minWidth: 300,
          border: 0,
        }}
        disableAutoFocus={true}
        open={invalidVoucherError}
        onClose={() => setInvalidVoucherError(false)}
      >
        <div className="bg-[#FFFFFF] p-[32px] mt-[100px] rounded-[12px] flex-col items-center justify-between border-none w-[450px] mx-auto select-none">
          <div className="text-[#000000] text-[24px] font-[700] text-center">
            Invalid Promo Code
          </div>
          <div className="flex flex-row justify-around pt-[20px]">
            <div
              onClick={() => {
                setInvalidVoucherError(false);
              }}
              className="cursor-pointer w-[165px] h-[46px] rounded-[62px] flex items-center justify-center bg-[#EF233C] text-[#FFFFFF] text-[16px] font-[700]"
            >
              Cancel
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          minWidth: 300,
          border: 0,
        }}
        disableAutoFocus={true}
        open={validVoucherPrompt}
        onClose={() => setValidVoucherPrompt(false)}
      >
        <div className="bg-[#FFFFFF] p-[32px] mt-[100px] rounded-[12px] flex-col items-center justify-between border-none w-[450px] mx-auto select-none">
          <div className="text-[#000000] text-[24px] font-[700] text-center">
            Promo Code applied successfully
          </div>
          <div className="flex flex-row justify-around pt-[20px]">
            <div
              onClick={() => {
                setValidVoucherPrompt(false);
              }}
              className="cursor-pointer w-[165px] h-[46px] rounded-[62px] flex items-center justify-center bg-[#EF233C] text-[#FFFFFF] text-[16px] font-[700]"
            >
              OK
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        sx={{
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          minWidth: 300,
          border: 0,
        }}
        disableAutoFocus={true}
        open={minVoucherAmountPrompt}
        onClose={() => setMinVoucherAmountPrompt(false)}
      >
        <div className="bg-[#FFFFFF] p-[32px] mt-[100px] rounded-[12px] flex-col items-center justify-between border-none w-[450px] mx-auto select-none">
          <div className="text-[#000000] text-[24px] font-[700] text-center">
            Minimum Order Amount Rs {cart.minVoucherOrderAmount} is not reached
          </div>
          <div className="flex flex-row justify-around pt-[20px]">
            <div
              onClick={() => {
                setMinVoucherAmountPrompt(false);
              }}
              className="cursor-pointer w-[165px] h-[46px] rounded-[62px] flex items-center justify-center bg-[#EF233C] text-[#FFFFFF] text-[16px] font-[700]"
            >
              OK
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PromoBar;
