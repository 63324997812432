import React, { useState, useEffect } from "react";
import { data } from "../../data";
import Items from "./Items";
import FlavoursAndToppings from "./FlavoursAndToppings";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  ADD_MENU_ITEM_TO_CURRENT_ORDER,
  DEFAULT_ITEM_PROPERTIES,
  handleOpen,
  INITIALIZE_MENU_ITEM,
  SET_ISEDITING_ADDON,
  UPDATE_MENU_ITEM_TO_CURRENT_ORDER,
} from "../../Features/cart";
import { ImCross } from "react-icons/im";
import { Modal } from "@mui/material";
import { RootState } from "../..";
import {
  SET_ARROWKEYLEVEL,
  SET_FLAVOURS_AND_TOPPINGS_OPEN,
  SET_ITERATOR,
  SET_POPUPITERATOR,
  SET_POPUPSECTION,
  SET_TOPLEVELCATEGORY,
  SET_TO_INITIAL_ITEMS_STATE,
} from "../../Features/ItemsStates";

const PopUp = (props: any) => {
  const cart = useSelector((state: RootState) => state.cart);
  const itemsStates = useSelector((state: RootState) => state.itemStates);
  const dispatch = useDispatch();
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const firstUpdatedItemIndex = itemsStates.firstUpdatedAddonIndex;

  const isEditing = cart.isEditingAddon;

  useEffect(() => {
    if (!isEditing) {
      let arrayObj: { name: any; property: any; isMultiple: any }[] = [];
      itemsStates?.flavor?.map((val: any) => {
        arrayObj.push({
          name: val.Name,
          property: val.OptionsList[0],
          isMultiple: val.IsMultiple,
        });
      });
      dispatch(
        INITIALIZE_MENU_ITEM({
          itemName: itemsStates.name,
          price: itemsStates.itemPrice,
          category: itemsStates.category,
          quantity: 1,
          MinimumDelivery: itemsStates.MinimumDelivery || 0,
          sizeID: itemsStates.sizeID,
          //@ts-ignore
          itemID: itemsStates.itemID,
          objArray: arrayObj,
        })
      );
    }
  }, [
    dispatch,
    itemsStates?.flavor,
    itemsStates.itemID,
    itemsStates.itemPrice,
    itemsStates.name,
    itemsStates.MinimumDelivery,
    itemsStates.category,
    itemsStates.sizeID,
  ]);

  useEffect(() => {
    dispatch(SET_FLAVOURS_AND_TOPPINGS_OPEN(true));
    return () => {
      dispatch(SET_FLAVOURS_AND_TOPPINGS_OPEN(false));
    };
  }, []);

  const handleClose = () => {
    dispatch(SET_ISEDITING_ADDON(false));
    dispatch(SET_POPUPSECTION(0));
    dispatch(SET_POPUPITERATOR(0));
    dispatch(handleOpen(false));
    dispatch(DEFAULT_ITEM_PROPERTIES());
    dispatch(SET_TO_INITIAL_ITEMS_STATE());
    dispatch(SET_ARROWKEYLEVEL(0));
    dispatch(SET_ITERATOR(0));
    dispatch(SET_TOPLEVELCATEGORY(""));
  };

  const handleAddorUpdate = () => {
    if (
      !(
        cart.selectedOrderStatus === "DISPATCHED" ||
        cart.selectedOrderStatus === "SUSPENDED" ||
        cart.selectedOrderStatus === "RETURNED"
      )
    ) {
      dispatch(SET_POPUPSECTION(0));
      if (isEditing) {
        dispatch(UPDATE_MENU_ITEM_TO_CURRENT_ORDER());
        dispatch(SET_ARROWKEYLEVEL(0));
        dispatch(SET_ITERATOR(0));
        dispatch(SET_TOPLEVELCATEGORY(""));
      } else {
        dispatch(ADD_MENU_ITEM_TO_CURRENT_ORDER());
        dispatch(SET_ARROWKEYLEVEL(0));
        dispatch(SET_ITERATOR(0));
        dispatch(SET_TOPLEVELCATEGORY(""));
      }
      handleClose();
    }
  };

  return (
    <Modal open={cart.open} onClose={handleClose}>
      <div
        className={`
      ${darkMode ? "bg-[#111827]" : "bg-white"}
      h-[80%]  overflow-y-scroll mx-auto my-[50px]
      xl:w-[calc(100%-400px)] xl:p-[24px]
      lg:w-[calc(100%-400px)] lg:p-[24px]
      w-[calc(100%-120px)] p-[15px]
      flex flex-col justify-between
      `}
      >
        <div>
          <FlavoursAndToppings />
        </div>
        <div
          //   className="fixed z-10
          // lg:right-[220px] lg:top-[50px]
          // xl:right-[220px] xl:top-[50px]
          // right-[80px] top-[50px]
          // "
          className="sticky bottom-[-23px] pt-4"
        >
          <div className="flex items-center justify-end">
            <div
              className="cursor-pointer text-white font-bold px-[16px] py-[4px] rounded-[10px] m-[10px] bg-[#EF233C]"
              onClick={handleAddorUpdate}
            >
              {isEditing ? "Update" : "Add"}
            </div>
            <div
              className="cursor-pointer bg-[#E8E8E8] rounded-[50%] p-[5px]"
              onClick={handleClose}
            >
              <ImCross />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PopUp;
