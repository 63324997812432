import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../";
import {
  SET_ITEMID,
  SET_PRICECHECKER_TRIGGER,
  SET_SENDREQUEST,
} from "../../../Features/rightBarHeader";
import { AiFillCamera } from "react-icons/ai";
import { SET_SEARCH_FIELD_TEXT } from "../../../Features/leftBarHeader";
import { SET_PRICECHECKER_ITEM } from "../../../Features/rightBarHeader";

const PriceCheckerSearchDiv = () => {
  const dispatch = useDispatch();

  const orderMenu = useSelector((state: RootState) => state.orderMenu.value);
  const [products, setProducts] = useState<Array<object> | null>(null);
  const [filterdArray, setFilteredArray] = useState<
    Array<object> | null | undefined
  >(null);
  const [toggle, setToggle] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [index, setIndex] = useState(0);
  const [qrCode, setQrCode] = useState(false);
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );

  const changeFunction = (e: any) => {
    setValue(e.target.value);
    const temp = e.target.value.toString();
    setIndex(0);
    setFilteredArray(
      products?.filter(
        (val: any) =>
          val.Name.toUpperCase().includes(e.target.value.toUpperCase()) ||
          val.ID.includes(e.target.value) ||
          val.RemoteCode.includes(
            e.target.value.substr(0, pos_config.remote_code_id_length)
          )
      )
    );

    if (e.target.value.length === 0) {
      setToggle(false);
    } else {
      setToggle(true);
    }
  };

  const handleKeyDown = async (e: any) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
      return;
    }
    if (e.key === "Enter") {
      if (filterdArray && filterdArray.length > 0 && value?.length > 0) {
        dispatch(SET_SEARCH_FIELD_TEXT(value));
        // @ts-ignore
        dispatch(SET_PRICECHECKER_ITEM(filterdArray[0]));
        dispatch(SET_PRICECHECKER_TRIGGER());

        setValue("");
      }
    }
  };

  useEffect(() => {
    let arr: any[] = [];
    orderMenu.map((val: any) => {
      val.MenuItemsList.map((innerVal: any) => {
        arr.push(innerVal);
      });
    });
    setProducts(arr);
  }, [orderMenu]);

  return (
    <>
      <div
        className={`border-white border flex items-center justify-center gap-2 rounded-full`}
      >
        <BsSearch className="text-white ml-[6px]" />
        <input
          autoFocus
          onBlur={() => setValue("")}
          value={value}
          onKeyDown={handleKeyDown}
          onChange={(e) => changeFunction(e)}
          className={`${
            darkMode ? "text-[#111827]" : "text-[white]"
          } focus:outline-none w-full h-full bg-transparent flex items-center justify-center placeholder:text-white placeholder:text-[8px]`}
          type="text"
          placeholder="Search"
        />
      </div>
    </>
  );
};

export default PriceCheckerSearchDiv;
