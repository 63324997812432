//@ts-nocheck
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ExpenseObject } from "../Interfaces/IExpenseObject";

interface Header {
  selectedHeader: string;
  printerPopUp: boolean;
  selectedBookingId: undefined | string;
  bardcodePrintPagePopUp: boolean;
  addDiscountPopupPage: boolean;
  promptButton: boolean;
  itemID: string | null;
  batchNumber: string | undefined;
  sendRequest: number;
  route: string;
  isCheckout: boolean;
  settingsPopup: boolean;
  priceCheckerItem: any;
  priceCheckerTrigger: number;
  port: any;
  reader: any;
  visualHeader: string;
}

const initialState: Header = {
  printerPopUp: false,
  barcodePrintPagePopUp: false,
  selectedHeader: "Dine-in",
  addDiscountPopupPage: false,
  promptButton: false,
  selectedBookingId: undefined,
  itemID: null,
  batchNumber: undefined,
  sendRequest: 0,
  route: "",
  priceCheckerItem: {},
  priceCheckerTrigger: 0,
  isCheckout: false,
  settingsPopup: false,
  port: null,
  reader: null,
  visualHeader: "Dine-in"
};

const rightBarHeaderSlice = createSlice({
  name: "rightBarHeader",
  initialState,
  reducers: {
    SET_SELECTEDHEADER: (state, action: PayloadAction<string>) => {
      state.selectedHeader = action.payload;
    },
    SET_PRINTERPOPUP: (state, action: PayloadAction<boolean>) => {
      state.printerPopUp = action.payload;
    },
    SET_VISUAL_HEADER: (state, action: PayloadAction<string>) => {
      state.visualHeader = action.payload;
    },
    SET_SELECTED_BOOKING_ID: (
      state,
      action: PayloadAction<undefined | string>
    ) => {
      state.selectedBookingId = action.payload;
    },
    SET_BARCODEPRINTPAGEPOPUP: (state, action: PayloadAction<boolean>) => {
      state.bardcodePrintPagePopUp = action.payload;
    },
    SET_PROMPTBUTTON: (state, action: PayloadAction<boolean>) => {
      state.promptButton = action.payload;
    },
    SET_PRICECHECKER_ITEM: (state, action: PayloadAction<any>) => {
      state.priceCheckerItem = action.payload;
    },
    SET_PRICECHECKER_TRIGGER: (state) => {
      state.priceCheckerTrigger = state.priceCheckerTrigger + 1;
    },
    SET_ITEMID: (state, action: PayloadAction<string | null>) => {
      state.itemID = action.payload;
    },
    SET_BATCH_NUMBER: (state, action: PayloadAction<string | undefined>) => {
      state.batchNumber = action.payload;
    },
    SET_SENDREQUEST: (state, action: PayloadAction<number>) => {
      state.sendRequest += action.payload;
    },
    SET_ROUTE: (state, action: PayloadAction<string>) => {
      state.route = action.payload;
    },
    SET_ISCHECKOUT: (state, action: PayloadAction<boolean>) => {
      state.isCheckout = action.payload;
    },
    SET_SETTINGS_POPUP: (state, action: PayloadAction<boolean>) => {
      state.settingsPopup = action.payload;
    },
    SET_PORT: (state, action: PayloadAction<any>) => {
      state.port = action.payload;
    },
    SET_READER: (state, action: PayloadAction<any>) => {
      state.reader = action.payload;
    },
    SET_ADD_DISCOUNT_POPUP_PAGE: (state, action: PayloadAction<boolean>) => {
      state.addDiscountPopupPage = action.payload;
    },
  },
});

export const {
  SET_SELECTEDHEADER,
  SET_PRINTERPOPUP,
  SET_BARCODEPRINTPAGEPOPUP,
  SET_PROMPTBUTTON,
  SET_ITEMID,
  SET_SELECTED_BOOKING_ID,
  SET_PRICECHECKER_ITEM,
  SET_PRICECHECKER_TRIGGER,
  SET_BATCH_NUMBER,
  SET_SENDREQUEST,
  SET_ROUTE,
  SET_ISCHECKOUT,
  SET_SETTINGS_POPUP,
  SET_PORT,
  SET_READER,
  SET_ADD_DISCOUNT_POPUP_PAGE,
  SET_VISUAL_HEADER,
} = rightBarHeaderSlice.actions;
export default rightBarHeaderSlice.reducer;
