import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { createOrder } from "../../Features/order";
import { Modal } from "@mui/material";
import PopUp from "./PopUp";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { SET_ISEDITING_ADDON, handleOpen } from "../../Features/cart";
import { ImCross } from "react-icons/im";
import { RootState } from "../..";
import ItemsStates, {
  SET_COMINGFROM,
  SET_SIZES_UPDATES,
} from "../../Features/ItemsStates";
import { AiFillCheckCircle } from "react-icons/ai";
import { useIsFirstRender } from "usehooks-ts";

const Size = (props: any) => {
  const isFirst = useIsFirstRender();
  const order = useSelector((state: any) => state.order.value);
  const itemStates = useSelector((state: RootState) => state.itemStates);
  const cart = useSelector((state: any) => state.cart);
  const dispatch = useDispatch();
  const [checkedItem, setCheckedItem] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [checkedSize, setCheckedSize] = useState<number | null>(null);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);

  useEffect(() => {
    if (itemStates.comingFrom === "Search") {
      dispatch(handleOpen(true));
    }
  }, [itemStates.counter]);

  useEffect(() => {
    if (!isFirst) {
      setIsLoading(true);
      dispatch(
        SET_SIZES_UPDATES({
          flavor:
            itemStates.data?.MenuSizesList[itemStates.iterator3]
              .FlavourAndToppingsList,
          itemPrice:
            itemStates.data?.MenuSizesList[itemStates.iterator3]
              .DiscountedPrice,
          MinimumDelivery: itemStates.data.MinimumDelivery,
          sizeID: itemStates.data?.MenuSizesList[itemStates.iterator3].ID,
        })
      );
      dispatch(SET_COMINGFROM("Click"));
      dispatch(handleOpen(true));

      setIsLoading(false);
    }
  }, [itemStates.trigger2]);

  useEffect(() => {
    if (itemStates.arrowKeyLevel === 2) {
      setCheckedItem(itemStates.iterator3);
    }
  }, [itemStates.iterator3]);

  return (
    <div
      id="sizes-div"
      className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-3 my-4"
    >
      {itemStates.data?.MenuSizesList?.map(
        (item: any, i: any) =>
          !props.isFromSearchHotkey && (
            <div
              key={i}
              className={`
              ${
                checkedSize === i || checkedItem === i
                  ? !darkMode
                    ? `bg-[${pos_config?.size_button_hover_color}] text-[${pos_config?.size_button_hover_text_color}] border-2 border-[${pos_config?.size_button_hover_text_color}]`
                    : `bg-[${pos_config?.size_button_hover_color_darkmode}] text-[${pos_config?.size_button_hover_text_color_darkmode}] border-2 border-[${pos_config?.size_button_hover_text_color_darkmode}]`
                  : !darkMode
                  ? `bg-[${pos_config?.size_button_color}] text-[${pos_config?.size_button_text_color}]`
                  : `bg-[${pos_config?.size_button_color_darkmode}] text-[${pos_config?.size_button_text_color_darkmode}]`
              }
            ${
              darkMode
                ? `hover:bg-[${pos_config.size_button_hover_color_darkmode}] hover:text-[${pos_config?.size_button_hover_text_color_darkmode}] hover:border-[${pos_config?.size_button_text_color_darkmode}] `
                : `hover:bg-[${pos_config.size_button_hover_color}] hover:text-[${pos_config?.size_button_hover_text_color}] hover:border-[${pos_config?.size_button_text_color}]`
            }
              p-4 flex flex-col hover:scale-105 rounded-md relative border-solid border-2 cursor-pointer ease-in-out duration-100 hover:shadow-md
              `}
              onClick={() => {
                setIsLoading(true);
                dispatch(
                  SET_SIZES_UPDATES({
                    flavor: item.FlavourAndToppingsList,
                    itemPrice: item.DiscountedPrice,
                    MinimumDelivery: itemStates.data.MinimumDelivery,
                    sizeID: item.ID,
                  })
                );
                dispatch(SET_ISEDITING_ADDON(false));
                dispatch(SET_COMINGFROM("Click"));
                dispatch(handleOpen(true));

                setIsLoading(false);
              }}
            >
              <div
                className={`${
                  checkedItem !== i && "hidden"
                } absolute top-1 right-1`}
              >
                <AiFillCheckCircle />
              </div>
              <div className="text-sm font-medium">{item.Size}</div>
              <div
                className={`flex text-xs font-bold text-[${
                  darkMode
                    ? pos_config?.size_price_text_color_darkmode
                    : pos_config?.size_price_text_color
                }] gap-1`}
              >
                <div>Rs</div>
                {item.Discount === 0 ? (
                  item.DiscountedPrice
                ) : (
                  <div
                    className={`flex gap-1 text-[${
                      darkMode
                        ? pos_config?.items_price_text_color_darkmode
                        : pos_config?.items_price_text_color
                    }]`}
                  >
                    <div className="line-through">
                      {item.DiscountedPrice + item.Discount}
                    </div>
                    <div>{item.DiscountedPrice}</div>
                  </div>
                )}
              </div>
            </div>
          )
      )}

      {cart.open && !isLoading && <PopUp />}
    </div>
  );
};

export default Size;
