import React, { Reducer } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import orderReducer from "./Features/order";
import orderMenuReducer from "./Features/orderMenu";
import cartReducer from "./Features/cart";
import rightBarHeader from "./Features/rightBarHeader";
import leftBarHeader from "./Features/leftBarHeader";
import registerServiceWorker from "./registerServiceWorker";
import database from "./Features/database";
import ItemsStates from "./Features/ItemsStates";
import customer from "./Features/customer";
import s3 from "./Features/s3";
import salesReport from "./Features/salesReport";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { Action, combineReducers } from "redux";
import { OrderType } from "./Features/order";
import OrderConsumption from "./Features/OrderConsumption";
import StyleManager from "./StyleManager";

const persistConfig = {
  key: "root", // The key to use in local storage
  storage, // The storage method
  // Optionally, you can specify which reducers to persist or exclude
  // whitelist: ['order', 'cart', 'customer'],
  // blacklist: ['itemStates', 's3', 'salesReport'],
};

const reducers = {
  order: orderReducer,
  orderMenu: orderMenuReducer,
  cart: cartReducer,
  header: rightBarHeader,
  leftHeader: leftBarHeader,
  database: database,
  itemStates: ItemsStates,
  customer: customer,
  s3: s3,
  salesReport: salesReport,
  orderConsumption: OrderConsumption,
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers(reducers)
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <StyleManager />
    <App />
  </Provider>
);


registerServiceWorker();
