//@ts-nocheck
import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";
import { Root, Root2, currentOrder } from "../Interfaces/IOrderData";
import { OrderDetails } from "../Interfaces/IOrderDetails";
import { CartOrder } from "../Interfaces/ICartOrder";

interface CartType {
  //this is a single entity of selected menu
  item: {
    itemName: string;
    categoryName: string;
    MinimumDelivery: number;
    value: Array<object>;
    total: number;
    itemPrice: number;
    addOns: Array<object>;
    quantity: number;
    batchNumber: string;
    sizeID: number;
    itemID: string;
    _id: string;
  };

  //all the items in an order for a single person are stored here
  order: CartOrder;

  checkoutCompleted: boolean;

  showCustomerPopup: boolean;

  focusCustomerNamePopup: boolean;

  //json of the order
  orderJson: OrderDetails | null;

  //discount
  discount: number;

  //login data
  loginData: {
    employeeID: string;
    employeeName: string;
    user: string;
    branchName: string;
    branchTaxPercentage: number;
  };

  voucherDiscount: number;

  manualDiscount: number;

  //discounted price
  discountedTotal: number;

  //tax amount
  taxAmount: number;

  //dicount + tax amoiunt
  discountedAndTaxedAmount: number;

  //the total of all items in an order
  cartTotal: number;

  //the json which will be sent on checkout
  orderDetails: OrderDetails;

  //determines when the orderDetails is ready to be used in the post order api
  isOrderReady: number;

  //popup is open or not
  open: boolean;

  //for the dine-in tables
  tableArea: Array<{
    tableNo: number;
    isAvailable: boolean;
    tableTotal: number;
    tableOrder: Array<object>;
    currentOrderNo: number | null;
    tableDiscountedTotal: number;
    tableDiscount: number;
  }>;

  name: string;

  //currently selected table
  currentTable: number;

  //current selected order
  selectedOrder: number | null;

  // selectedOrderCode
  selectedOrderCode: string | null;

  //selectedOrderStatus
  selectedOrderStatus: string | null;

  //orders
  ordersPlaced: {
    orderNo: number;
    currentOrder: Array<currentOrder>;
  };

  paymentMethod: string;

  searchedOrder: string;

  filteredOrder: string;

  voucher: string;

  minVoucherOrderAmount: number;

  creditorName: string;

  payment: {
    cashPaid: number;
    cashPaymentPopup: boolean;
    checkoutPopup: boolean;
    cardPopup: boolean;
    creditPopup: boolean;
    jointPopup: boolean;
    isCardPayment: boolean;
    isJointPayment: boolean;
    isCreditPayment: boolean;
    isCashPayment: boolean;
    isOnlineBankPayment: boolean;
    creditors: string[] | undefined;
    bankOptions: Array<string>;
    cashAmount: number;
    amountNotReachedPrompt: boolean;

    card1Details: {
      bankName: string;
      binNumber: number;
      cardAmount: number;
    };
    card2Details: {
      bankName: string;
      binNumber: number;
      cardAmount: number;
    };
  };

  editingAddon: any | null;
  isEditingAddon: boolean;
}

const initialState: CartType = {
  item: {
    itemName: "",
    categoryName: "",
    MinimumDelivery: 0,
    value: [],
    total: 0,
    itemPrice: 0,
    addOns: [],
    quantity: 0,
    sizeID: 0,
    itemID: "",
    _id: "",
  },
  name: "",
  order: [],
  checkoutCompleted: false,
  orderJson: null,
  selectedOrder: null,
  selectedOrderCode: null,
  selectedOrderStatus: null,
  focusCustomerNamePopup: false,
  cartTotal: 0,
  discountedTotal: 0,
  taxAmount: 0,
  discountedAndTaxedAmount: 0,
  voucherDiscount: 0,
  manualDiscount: 0,
  discount: 0,
  showCustomerPopup: false,
  loginData: {
    employeeID: "",
    employeeName: "",
    user: "",
    branchName: "",
    branchTax: 0,
  },
  orderDetails: {},
  isOrderReady: 0,
  open: false,
  tableArea: [],

  currentTable: 0,
  ordersPlaced: {
    orderNo: 0,
    currentOrder: [],
  },
  paymentMethod: "Cash",
  searchedOrder: "",
  filteredOrder: "None",
  voucher: "",
  minVoucherOrderAmount: 0,
  creditorName: "",
  payment: {
    cashPaid: 0,
    cashPaymentPopup: false,
    checkoutPopup: false,
    cardPopup: false,
    creditPopup: false,
    jointPopup: false,
    isCardPayment: false,
    isJointPayment: false,
    isCreditPayment: false,
    isOnlineBankPayment: false,
    amountNotReachedPrompt: false,
    creditors: undefined,
    bankOptions: ["Online Bank Payment", "Meezan Bank", "HBL", "Askari Bank"],
    cashAmount: 0,
    isCashPayment: true,
    card1Details: {
      cardAmount: 0,
      bankName: "",
      binNumber: 0,
    },
    card2Details: {
      cardAmount: 0,
      bankName: "",
      binNumber: 0,
    },
  },
  editingAddon: null,
  isEditingAddon: false,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    SET_EDITING_ADDON: (state, action: PayloadAction<any>) => {
      state.editingAddon = action.payload;
      if (action.payload) {
        const editingItem = state.order[action.payload.index];
        state.item = {
          ...editingItem,
          addOns: action.payload.addOns,
        };
      }
    },

    SET_ISEDITING_ADDON: (state, action: PayloadAction<boolean>) => {
      state.isEditingAddon = action.payload;
    },

    UPDATE_EDITED_ADDON: (
      state,
      action: PayloadAction<{ name: string; item: object; isMultiple: boolean }>
    ) => {
      const { name, item, isMultiple } = action.payload;
      const index = state.item.value.findIndex((e) => e.name === name);
      if (index !== -1 && state.item.value[index]) {
        if (isMultiple) {
          const propertyIndex = state.item.value[index].properties.findIndex(
            (prop) => prop.ID === item.ID
          );
          if (propertyIndex === -1) {
            state.item.value[index].properties.push(item);
          } else {
            state.item.value[index].properties.splice(propertyIndex, 1);
          }
        } else {
          state.item.value[index].properties = [item];
        }
        // Replace the properties for this specific addon group
        // Recalculate total and update addOns
        state.item.total = state.item.itemPrice;
        state.item.addOns = [];

        // Only add addons from groups that have properties
        state.item.value.forEach((val) => {
          if (val.properties && val.properties.length > 0) {
            val.properties.forEach((prop) => {
              state.item.total += prop.Price;
              state.item.addOns.push({
                name: prop.Name,
                price: prop.Price,
                quantity: 1,
                objGroupName: val.name,
                optionID: prop.ID,
              });
            });
          }
        });
      }
    },

    //Initializes the menu item as it is added
    INITIALIZE_MENU_ITEM: (
      state,
      action: PayloadAction<{
        itemName: string;
        price: number;
        quantity: number;
        category: string;
        MinimumDelivery: number;
        batchNumber: string;
        sizeID: number;
        itemID: number;
        _id: string;
        objArray: Array<{
          name: string;
          property: object;
          isMultiple: boolean;
        }>;
      }>
    ) => {
      state.item.itemName = action.payload.itemName;
      state.item.quantity = action.payload.quantity;
      state.item.total = action.payload.price * state.item.quantity;
      state.item.itemPrice = action.payload.price;
      state.item.categoryName = action.payload.category;
      state.item.MinimumDelivery = action.payload.MinimumDelivery;
      state.item.sizeID = action.payload.sizeID;
      state.item.itemID = action.payload.itemID;
      state.item._id = action.payload._id;
      state.item.batchNumber = action.payload.batchNumber;
      let objArray = action.payload.objArray;
      for (let i = 0; i < objArray.length; i++) {
        let obj = {
          name: objArray[i].name,
          properties: [],
          price: 0,
          isMultiple: objArray[i].isMultiple,
        };
        if (obj.isMultiple === false) {
          obj?.properties.push(objArray[i].property);
        }
        state.item.value.push(obj);
      }
      console.log("ITEM ARRAY IN CART.TSX", current(state.item));
    },

    SET_FOCUS_ON_CUSTOMER_NAME_POPUP: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.focusCustomerNamePopup = action.payload;
    },

    //Updates the Properties array objects which helps in making AddOns list
    ADD_OR_REMOVE_ADDONS: (
      state,
      action: PayloadAction<{ name: string; item: object }>
    ) => {
      const obj = action.payload;
      const index = state.item.value.findIndex((e) => e.name === obj.name);
      if (index !== -1 && state.item.value[index]) {
        if (state.item.value[index].isMultiple === true) {
          let included = false;
          let includedIndex = null;
          state.item.value[index].properties?.forEach((e, i) => {
            if (e.ID === obj.item.ID) {
              included = true;
              includedIndex = i;
            }
          });
          if (!included) {
            console.log("IF EXECUTED");
            state.item.value[index].properties.push(obj.item);
          } else if (includedIndex !== null) {
            console.log("ELSE IF EXECUTED");
            state.item.value[index].properties.splice(includedIndex, 1);
          }
        } else {
          console.log("ELSE EXECUTED");
          state.item.value[index].properties = [obj.item];
        }
      }
    },

    //Adds the selected item to the list of current order list and initializes
    //the state.item to default values for new item
    ADD_MENU_ITEM_TO_CURRENT_ORDER: (state) => {
      state.item.value.map((item) => {
        item.properties.map((obj) => {
          state.item.total = state.item.total + obj.Price;
          state.item.addOns.push({
            name: obj.Name,
            price: obj.Price,
            quantity: 1,
            objGroupName: item.name,
            optionID: obj.ID,
          });
        });
      });
      state.cartTotal += state.item.total;
      state.discountedTotal = (state.cartTotal - state.discount).toFixed(2);
      state.taxAmount = (
        (state.cartTotal / 100) *
        state.loginData.branchTaxPercentage
      ).toFixed(2);
      state.discountedAndTaxedAmount = (
        parseFloat(state.discountedTotal) + parseFloat(state.taxAmount)
      ).toFixed(2);
      let index = state.order.findIndex((i: any) => {
        if (
          i.itemName === state.item.itemName &&
          JSON.stringify(i.addOns) === JSON.stringify(state.item.addOns)
        ) {
          return true;
        } else {
          return false;
        }
      });
      if (index >= 0) {
        const obj = state.order.map((item, i) => {
          if (i === index) {
            var newQuantity = item.quantity + 1;
            var newOrderTotal = item.orderTotal + item.total;
            return {
              ...item,
              //@ts-ignore
              quantity: newQuantity,
              //@ts-ignore
              orderTotal: newOrderTotal,
            };
          }
          return item;
        });
        state.order = obj;
        state.cartTotal = 0;
        state.order.map((item) => {
          //@ts-ignore
          state.cartTotal = state.cartTotal + item.orderTotal;
        });
        if (state.cartTotal < state.minVoucherOrderAmount) {
          state.voucher = "";
          state.minVoucherOrderAmount = 0;
          state.discount = 0;
        }
        state.discountedTotal = (state.cartTotal - state.discount).toFixed(2);
        state.taxAmount = (
          (state.cartTotal / 100) *
          state.loginData.branchTaxPercentage
        ).toFixed(2);
        state.discountedAndTaxedAmount = (
          parseFloat(state.discountedTotal) + parseFloat(state.taxAmount)
        ).toFixed(2);
      } else {
        state.order.push({
          ...state.item,
          orderTotal: state.item.total,
          index: state.order.length,
        });
      }

      state.item = {
        itemName: "",
        value: [],
        total: 0,
        itemPrice: 0,
        batchNumber: undefined,
        addOns: [],
        quantity: 0,
        _id: "",
        itemID: "",
      };
      //console.log("ORDER ARRAY IN CART.TSX", current(state.order));
    },

    UPDATE_MENU_ITEM_TO_CURRENT_ORDER: (state) => {
      const itemIndex = state.order.findIndex(
        (i: any) => i.itemID === state.item.itemID
      );
      if (itemIndex !== -1) {
        state.order[itemIndex] = {
          ...state.order[itemIndex],
          ...state.item,
          addOns: state.item.addOns.map((addon) => ({ ...addon })), // Create a new array with new addon objects
          total: state.item.total,
          orderTotal: state.item.total * state.item.quantity,
        };
      }
      // Recalculate cart total and other values
      state.cartTotal = state.order.reduce(
        (total, item) => total + item.orderTotal,
        0
      );
      state.discountedTotal = (state.cartTotal - state.discount).toFixed(2);
      state.taxAmount = (
        (state.cartTotal / 100) *
        state.loginData.branchTaxPercentage
      ).toFixed(2);
      state.discountedAndTaxedAmount = (
        parseFloat(state.discountedTotal) + parseFloat(state.taxAmount)
      ).toFixed(2);
    },
    // In the cartSlice reducers:

    //Increases the quantity of selected item from orders
    INCREASE_ITEM_QUANTITY: (
      state,
      action: PayloadAction<{
        index: number;
        quantity: number;
        total: number;
        price: number;
      }>
    ) => {
      const obj = state.order.map((item, i) => {
        if (i === action.payload.index) {
          return {
            ...item,
            //@ts-ignore
            quantity: action.payload.quantity,
            //@ts-ignore
            orderTotal: action.payload.total,
          };
        }
        return item;
      });
      state.order = obj;
      state.cartTotal += action.payload.price;
      if (state.cartTotal < state.minVoucherOrderAmount) {
        state.voucher = "";
        state.minVoucherOrderAmount = 0;
        state.discount = 0;
      }
      state.discountedTotal = (state.cartTotal - state.discount).toFixed(2);
      state.taxAmount = (
        (state.cartTotal / 100) *
        state.loginData.branchTaxPercentage
      ).toFixed(2);
      state.discountedAndTaxedAmount = (
        parseFloat(state.discountedTotal) + parseFloat(state.taxAmount)
      ).toFixed(2);
    },

    //Increases the quantity of selected item from orders
    DECREASE_ITEM_QUANTITY: (
      state,
      action: PayloadAction<{
        index: number;
        quantity: number;
        total: number;
        price: number;
      }>
    ) => {
      const obj = state.order.map((item, i) => {
        if (i === action.payload.index) {
          return {
            ...item,
            //@ts-ignore
            quantity: action.payload.quantity,
            //@ts-ignore
            orderTotal: action.payload.total,
          };
        }
        return item;
      });
      state.order = obj;
      state.cartTotal -= action.payload.price;
      if (state.cartTotal < state.minVoucherOrderAmount) {
        state.voucher = "";
        state.minVoucherOrderAmount = 0;
        state.discount = 0;
      }
      state.discountedTotal = (state.cartTotal - state.discount).toFixed(2);
      state.taxAmount = (
        (state.cartTotal / 100) *
        state.loginData.branchTaxPercentage
      ).toFixed(2);
      state.discountedAndTaxedAmount = (
        parseFloat(state.discountedTotal) + parseFloat(state.taxAmount)
      ).toFixed(2);
    },

    UPDATE_ITEM_QUANTITY: (
      state,
      action: PayloadAction<{ price: number; quantity: number; index: number }>
    ) => {
      const obj = state.order.map((item, i) => {
        if (i === action.payload.index) {
          return {
            ...item,
            //@ts-ignore
            quantity: action.payload.quantity,
            //@ts-ignore
            orderTotal: action.payload.price * action.payload.quantity,
          };
        }
        return item;
      });
      state.order = obj;
      state.cartTotal = 0;
      state.order.map((item) => {
        //@ts-ignore
        state.cartTotal = state.cartTotal + item.orderTotal;
      });
      if (state.cartTotal < state.minVoucherOrderAmount) {
        state.voucher = "";
        state.minVoucherOrderAmount = 0;
        state.discount = 0;
      }
      state.discountedTotal = (state.cartTotal - state.discount).toFixed(2);
      state.taxAmount = (
        (state.cartTotal / 100) *
        state.loginData.branchTaxPercentage
      ).toFixed(2);
      state.discountedAndTaxedAmount = (
        parseFloat(state.discountedTotal) + parseFloat(state.taxAmount)
      ).toFixed(2);
    },

    REMOVE_ITEM_FROM_ORDER: (
      state,
      action: PayloadAction<{ index: number; total: number }>
    ) => {
      const obj = state.order;
      const total = state.order[action.payload.index].orderTotal;
      obj.splice(action.payload.index, 1);
      state.value = obj;
      state.cartTotal -= total;
      if (state.cartTotal < state.minVoucherOrderAmount) {
        state.voucher = "";
        state.minVoucherOrderAmount = 0;
        state.discount = 0;
      }
      state.discountedTotal = (state.cartTotal - state.discount).toFixed(2);
      state.taxAmount = (
        (state.cartTotal / 100) *
        state.loginData.branchTaxPercentage
      ).toFixed(2);
      state.discountedAndTaxedAmount = (
        parseFloat(state.discountedTotal) + parseFloat(state.taxAmount)
      ).toFixed(2);
    },

    //sets item object to default properties
    DEFAULT_ITEM_PROPERTIES: (state) => {
      state.item = {
        itemName: "",
        value: [],
        total: 0,
        itemPrice: 0,
        addOns: [],
        quantity: 0,
      };
    },

    handleOpen: (state, action) => {
      state.open = action.payload;
    },

    SET_TABLE_NAME: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },

    INITIALIZE_TABLE_AREA: (state, action: PayloadAction<number>) => {
      for (let i = 0; i < action.payload; i = i + 1) {
        state.tableArea.push({
          tableNo: i + 1,
          tableTotal: 0,
          isAvailable: true,
          tableOrder: [],
          currentOrderNo: null,
          tableDiscountedTotal: 0,
          tableDiscount: 0,
        });
      }
    },

    SET_CURRENT_TABLE: (state, action: PayloadAction<number>) => {
      state.currentTable = action.payload;
      let obj = state.tableArea.find(
        (item) => item.tableNo === state.currentTable
      );
      state.order = obj?.tableOrder;
      state.cartTotal = obj?.tableTotal;
      state.discount = obj?.tableDiscount;
      state.discountedTotal = state.cartTotal - state.discount;
      state.taxAmount =
        (state.cartTotal / 100) * state.loginData.branchTaxPercentage;
      state.discountedAndTaxedAmount = state.discountedTotal + state.taxAmount;
    },

    INITIALIZE_ORDER_DATA: (state) => {
      state.order = [];
      state.cartTotal = 0;
      state.discount = 0;
      state.discountedTotal = 0;
      state.taxAmount = 0;
      state.discountedAndTaxedAmount = 0;
    },

    //makes the object ready to be posted
    PLACE_ORDER: (
      state,
      action: PayloadAction<{ orderType: string; isDineIn: boolean }>
    ) => {
      if (action.payload.isDineIn) {
        let obj = state.tableArea.find(
          (item) => item.tableNo === state.currentTable
        );
        if (obj?.currentOrderNo) {
          state.selectedOrder = obj.currentOrderNo;
        }
      }
      if (state.selectedOrder) {
        console.log("Updating existing order:", state.selectedOrder);
        state.ordersPlaced.currentOrder[state.selectedOrder - 1].orderData =
          state.order;
        state.ordersPlaced.currentOrder[state.selectedOrder - 1].orderTotal =
          state.cartTotal;
        state.ordersPlaced.currentOrder[state.selectedOrder - 1].isInProgress =
          false;
        state.ordersPlaced.currentOrder[state.selectedOrder - 1].isDispatched =
          true;
        state.ordersPlaced.currentOrder[
          state.selectedOrder - 1
        ].discountedPrice = state.discountedTotal;
        state.ordersPlaced.currentOrder[state.selectedOrder - 1].discount =
          state.discount;
      } else {
        console.log("Adding new order");

        state.ordersPlaced.orderNo = state.ordersPlaced.orderNo + 1;
        state.ordersPlaced.currentOrder.push({
          currentOrderNo: state.ordersPlaced.orderNo,
          orderID: 1234,
          tableNo: action.payload.isDineIn ? state.currentTable : null,
          orderType: action.payload.orderType,
          isInProgress: false,
          isSuspended: false,
          isDispatched: true,
          orderData: state.order,
          orderTotal: state.cartTotal,
          voucher: "",
          discountedPrice: state.discountedTotal,
          discount: state.discount,
          isDelivery: action.payload.orderType === "Delivery" ? true : false,
          isReturned: false,
        });
      }

      // state.orderJson = {
      //   branch: state.loginData.branchName,
      //   platform: "POS",
      //   name: state.name,
      //   status: 'dispatched',
      //   orderType: action.payload.orderType,
      //   area: "Test area",
      //   tax: state.taxAmount,
      //   voucher: state.voucher,
      //   discountamount: state.discount,
      //   orderamount: state.cartTotal,
      //   paymenttype: state.paymentMethod,
      //   paymentRemarks: state.creditorName || "",
      //   totalamount: state.discountedAndTaxedAmount,
      //   taxamount: state.taxAmount,
      //   deliverycharges: 0,
      //   customerFullName: state.orderDetails.customerFullName || null,
      //   customerPhone: state.orderDetails.customerPhone || null,
      //   customerAddress: state.orderDetails.customerAddress || null,
      //   customerCity: state.orderDetails.customerCity || null,
      //   customerLatitude: null,
      //   customerLongitude: null,
      //   customerOtherAddresses: null,
      //   orderdata: state.order.map((obj) => ({
      //     productName: obj.itemName,
      //     refCode: obj.itemID,
      //     categoryName: obj.categoryName,
      //     discountGiven: 0,
      //     minimumDelivery: obj.MinimumDelivery,
      //     itemImage: "https://admin.dunkinpizza.com.pk/Images/ProductImages/13-inch-thumbnails.jpg",
      //     options: obj.addOns.map((temp) => ({
      //       OptionID: temp.optionID,
      //       OptionName: temp.name,
      //       OptionGroupName: temp.objGroupName,
      //       Price: temp.price,
      //       Quantity: temp.quantity,
      //     })),
      //     quantity: obj.quantity,
      //     price: obj.total,
      //     totalProductPrice: obj.orderTotal,
      //     SizeID: obj.sizeID,
      //   })),
      //   Remarks: "",
      //   category: "",
      // };

      if (action.payload.isDineIn) {
        let obj = state.tableArea.find(
          (item) => item.tableNo === state.currentTable
        );
        // state.order = obj?.tableOrder;
        // state.cartTotal = obj?.tableTotal;
        obj.isAvailable = true;
        obj.tableOrder = [];
        obj.tableTotal = 0;
        obj.tableDiscount = 0;
        obj.tableDiscountedTotal = 0;
        obj.currentOrderNo = null;
        state.tableArea = state.tableArea.map((temp) => {
          if (temp.tableNo === state.currentTable) {
            return obj;
          } else {
            return temp;
          }
        });
      }

      state.orderDetails = {
        ...state.orderDetails,
        branch: state.loginData.branchName,
        platform: "POS",
        status: "dispatched",
        area: "Test Area",
        tax: 0,
        voucher: state.voucher,
        discountamount: state.discount,
        orderamount: state.cartTotal,

        paymenttype: state.paymentMethod,
        paymentRemarks: "",
        totalamount: state.discountedAndTaxedAmount,
        Remarks: "Extra Cheese",
        taxamount: state.taxAmount,
        deliverycharges: 0,
        category: "category #",
        customerFullName: state.orderDetails.customerFullName
          ? state.orderDetails.customerFullName
          : "",
        customerPhone: state.orderDetails.customerPhone
          ? state.orderDetails.customerPhone
          : "",
        customerAddress: "Sharahe faisal near ghalib restaurant",
        customerCity: "Karachi",
        customerLatitude: "latitude",
        customerLongitude: "longitude",

        orderdata: state.order.map((obj) => {
          return {
            productName: obj.itemName,
            productID: obj.itemID,
            categoryName: obj.categoryName,
            discountGiven: 0,
            minimumDelivery: obj.MinimumDelivery,
            itemImage:
              "https://admin.dunkinpizza.com.pk/Images/ProductImages/13-inch-thumbnails.jpg",

            options: obj.addOns.map((temp) => {
              return {
                OptionID: temp.optionID,
                OptionName: temp.name,
                OptionGroupName: temp.objGroupName,
                Price: temp.price,
                Quantity: temp.quantity,
              };
            }),
            quantity: obj.quantity,
            price: obj.total,
            totalProductPrice: obj.orderTotal,
            SizeID: obj.sizeID,
          };
        }),
      };
      state.creditorName = "";
      console.log("order placed", state.orderJson);
    },

    HOLD_ORDER: (
      state,
      action: PayloadAction<{ orderType: string; isDineIn: boolean }>
    ) => {
      if (action.payload.isDineIn) {
        let obj = state.tableArea.find(
          (item) => item.tableNo === state.currentTable
        );
        if (obj?.currentOrderNo) {
          state.selectedOrder = obj.currentOrderNo;
        }
        obj.currentOrderNo = obj?.isAvailable
          ? state.ordersPlaced.orderNo + 1
          : obj?.currentOrderNo;
        obj.isAvailable = false;
        obj.tableOrder = [...state.order];
        obj.tableTotal = state.cartTotal;
        obj.tableDiscountedTotal = state.discountedTotal;
        obj.tableDiscount = state.discount;

        state.tableArea = state.tableArea.map((temp) => {
          if (temp.tableNo === state.currentTable) {
            return obj;
          } else {
            return temp;
          }
        });
        //@ts-ignore
      }
      if (state.selectedOrder) {
        if (
          state.ordersPlaced.currentOrder[state.selectedOrder - 1].orderData
        ) {
          state.ordersPlaced.currentOrder[state.selectedOrder - 1].orderData =
            state.order;
          state.ordersPlaced.currentOrder[state.selectedOrder - 1].orderTotal =
            state.cartTotal;
          state.ordersPlaced.currentOrder[state.selectedOrder - 1].discount =
            state.discount;
          state.ordersPlaced.currentOrder[
            state.selectedOrder - 1
          ].discountedPrice = state.discountedTotal;
        }
      } else {
        state.ordersPlaced.orderNo = state.ordersPlaced.orderNo + 1;
        state.ordersPlaced.currentOrder.push({
          currentOrderNo: state.ordersPlaced.orderNo,
          orderID: 1234,
          tableNo: action.payload.isDineIn ? state.currentTable : null,
          orderType: action.payload.orderType,
          isInProgress: true,
          isSuspended: false,
          isDispatched: false,
          orderData: state.order,
          orderTotal: state.cartTotal,
          voucher: state.voucher,
          discountedPrice: state.discountedTotal,
          discount: state.discount,
          isDelivery: action.payload.orderType === "Delivery" ? true : false,
          isReturned: false,
        });
      }
    },

    SUSPEND_ORDER: (state, action: PayloadAction<{ isDineIn: boolean }>) => {
      if (action.payload.isDineIn) {
        let obj = state.tableArea.find(
          (item) => item.tableNo === state.currentTable
        );
        if (obj?.currentOrderNo) {
          state.selectedOrder = obj.currentOrderNo;
        }
      }
      if (state.selectedOrder) {
        if (action.payload.isDineIn) {
          let obj = state.tableArea.find(
            (item) => item.tableNo === state.currentTable
          );
          obj.isAvailable = true;
          obj.tableOrder = [];
          obj.tableDiscount = 0;
          obj.tableDiscountedTotal = 0;
          obj.tableTotal = 0;
          obj.currentOrderNo = null;
          state.tableArea = state.tableArea.map((temp) => {
            if (temp.tableNo === state.currentTable) {
              return obj;
            } else {
              return temp;
            }
          });
        }
        if (
          state.ordersPlaced.currentOrder[state.selectedOrder - 1].isInProgress
        ) {
          state.ordersPlaced.currentOrder[
            state.selectedOrder - 1
          ].isInProgress = false;
          state.ordersPlaced.currentOrder[state.selectedOrder - 1].isSuspended =
            true;
        }
      } else {
        state.order = [];
        state.cartTotal = 0;
        state.discountedTotal = 0;
        state.discount = 0;
        state.discountedAndTaxedAmount = 0;
        state.taxAmount = 0;
      }
    },
    SET_PAYMENT_METHOD: (state, action: PayloadAction<string>) => {
      state.paymentMethod = action.payload;
      if (action.payload === "Online") {
        state.payment.cardPopup = true;
        state.payment.isCardPayment = true;
        state.payment.isJointPayment = false;
        state.payment.isCashPayment = false;
        state.payment.isCreditPayment = false;
      } else if (action.payload === "Joint") {
        state.payment.jointPopup = true;
        state.payment.isCardPayment = false;
        state.payment.isJointPayment = true;
        state.payment.isCashPayment = false;
        state.payment.isOnlineBankPayment = false;
        state.payment.isCreditPayment = false;
      } else if (action.payload === "Credit") {
        state.payment.creditPopup = true;
        state.payment.isCardPayment = false;
        state.payment.isJointPayment = false;
        state.payment.isCashPayment = false;
        state.payment.isCreditPayment = true;
        state.payment.isOnlineBankPayment = false;
      } else if (action.payload === "Cash") {
        state.payment.isCardPayment = false;
        state.payment.isJointPayment = false;
        state.payment.isCashPayment = true;
        state.payment.isOnlineBankPayment = false;
        state.payment.isCreditPayment = false;
      }
    },

    SET_CREDITOR_NAME: (state, action: PayloadAction<string>) => {
      state.creditorName = action.payload;
    },

    SET_ONLINE_BANK_PAYMENT: (state, action: PayloadAction<boolean>) => {
      state.payment.isOnlineBankPayment = action.payload;
    },

    SET_IS_CARD_PAYMENT: (state, action: PayloadAction<boolean>) => {
      state.payment.isCardPayment = action.payload;
    },

    SET_IS_ORDER_READY: (state, action: PayloadAction<number>) => {
      state.isOrderReady = action.payload;
    },

    SET_SEARCHED_ORDER: (state, action: PayloadAction<string>) => {
      state.searchedOrder = action.payload;
    },

    SET_FILTERED_ORDER: (state, action: PayloadAction<string>) => {
      state.filteredOrder = action.payload;
    },

    SET_VOUCHER_DISCOUNT: (state, action: PayloadAction<number>) => {
      state.voucherDiscount = action.payload;
      state.discount = state.voucherDiscount + state.manualDiscount;
      state.discountedTotal = (state.cartTotal - state.discount).toFixed(2);
      state.taxAmount = (
        (state.cartTotal / 100) *
        state.loginData.branchTaxPercentage
      ).toFixed(2);
      state.discountedAndTaxedAmount = (
        parseFloat(state.discountedTotal) + parseFloat(state.taxAmount)
      ).toFixed(2);
    },

    SET_MANUAL_DISCOUNT: (state, action: PayloadAction<number>) => {
      state.manualDiscount = action.payload;
      state.discount = state.voucherDiscount + state.manualDiscount;
      state.discountedTotal = (state.cartTotal - state.discount).toFixed(2);
      state.taxAmount = (
        (state.cartTotal / 100) *
        state.loginData.branchTaxPercentage
      ).toFixed(2);
      state.discountedAndTaxedAmount = (
        parseFloat(state.discountedTotal) + parseFloat(state.taxAmount)
      ).toFixed(2);
    },
    SET_VOUCHER: (state, action: PayloadAction<string>) => {
      state.voucher = action.payload;
    },
    SET_CARD_POPUP: (state, action: PayloadAction<boolean>) => {
      state.payment.cardPopup = action.payload;
    },
    SET_CREDIT_POPUP: (state, action: PayloadAction<boolean>) => {
      state.payment.creditPopup = action.payload;
    },
    SET_JOINT_POPUP: (state, action: PayloadAction<boolean>) => {
      state.payment.jointPopup = action.payload;
    },
    SET_CASH_PAYMENT_POPUP: (state, action: PayloadAction<boolean>) => {
      state.payment.cashPaymentPopup = action.payload;
    },
    SET_AMOUNT_NOT_REACHED_PROMPT: (state, action: PayloadAction<boolean>) => {
      state.payment.amountNotReachedPrompt = action.payload;
    },
    SET_CHECKOUT_POPUP: (state, action: PayloadAction<boolean>) => {
      state.payment.checkoutPopup = action.payload;
    },
    SET_BANK_NAME: (state, action: PayloadAction<string>) => {
      state.payment.cardDetails.bankName = action.payload;
    },
    SET_BIN_CODE: (state, action: PayloadAction<number>) => {
      state.payment.cardDetails.binNumber = action.payload;
    },

    SET_CHECKOUT_COMPLETED: (state, action: PayloadAction<boolean>) => {
      state.checkoutCompleted = action.payload;
    },
    SET_CARD1_DETAILS: (
      state,
      action: PayloadAction<{ bankName: string; binCode: number }>
    ) => {
      state.payment.card1Details.binNumber = action.payload.binCode;
      state.payment.card1Details.bankName = action.payload.bankName;
    },
    SET_CARD2_DETAILS: (
      state,
      action: PayloadAction<{ bankName: string; binCode: number }>
    ) => {
      state.payment.card2Details.binNumber = action.payload.binCode;
      state.payment.card2Details.bankName = action.payload.bankName;
    },
    SET_RETURN_SALE: (state) => {
      state.ordersPlaced.currentOrder[state.selectedOrder - 1].isReturned =
        true;
      state.ordersPlaced.currentOrder[state.selectedOrder - 1].isDispatched =
        false;
    },

    SET_CASH_AMOUNT: (state) => {
      state.payment.cashAmount = state.discountedAndTaxedAmount;
    },

    SET_CARD_PAYMENT_AMOUNT: (state) => {
      state.payment.card1Details = state.discountedAndTaxedAmount;
    },

    SET_CASH_PAID: (state, action: PayloadAction<number>) => {
      state.payment.cashPaid = action.payload;
    },
    SET_ORDER_DETAILS: (state, action: PayloadAction<object>) => {
      state.orderDetails = action.payload;
    },
    SET_CREDITORS: (state, action: PayloadAction<string[]>) => {
      state.payment.creditors = action.payload;
    },
    SET_MIN_VOUCHER_ORDER_AMOUNT: (state, action: PayloadAction<number>) => {
      state.minVoucherOrderAmount = action.payload;
    },
    SET_LOGIN_DATA: (
      state,
      action: PayloadAction<{
        employeeID: string;
        employeeName: string;
        user: string;
        branchName: string;
      }>
    ) => {
      state.loginData = action.payload;
      console.log("login data ", state.loginData);
    },

    SET_SELECTED_ORDER: (state, action: PayloadAction<Root2 | null>) => {
      if (action.payload) {
        state.selectedOrderCode = action.payload?.code;
        state.selectedOrderStatus = action.payload?.POSStatus.toUpperCase();
        state.discountedTotal = parseFloat(action.payload?.Discount);
        state.taxAmount = parseFloat(action.payload.TaxAmount);
        state.discountedAndTaxedAmount = parseFloat(action.payload.OrderAmount);
        state.voucher = action.payload?.VoucherValue;
        state.cartTotal = parseFloat(action.payload?.SubTotal);
        state.discount = parseFloat(action.payload?.Discount);
      } else {
        state.selectedOrderCode = null;
        state.selectedOrderStatus = null;
        state.discountedTotal = 0;
        state.taxAmount = 0;
        state.discountedAndTaxedAmount = 0;
        state.voucher = "";
        state.cartTotal = 0;
        state.discount = 0;
      }
    },

    // SET_SELECTED_ORDER: (state, action: PayloadAction<Root2 | null>) => {
    //   // state.selectedOrder = action.payload;
    //   // if (state.selectedOrder) {
    //   //   if (state.ordersPlaced.currentOrder[state.selectedOrder - 1].tableNo) {
    //   //     state.currentTable =
    //   //       state.ordersPlaced.currentOrder[state.selectedOrder - 1].tableNo;
    //   //   }
    //   //   state.order =
    //   //     state.ordersPlaced.currentOrder[state.selectedOrder - 1].orderData;

    //   //   state.cartTotal =
    //   //     state.ordersPlaced.currentOrder[state.selectedOrder - 1].orderTotal;
    //   //   state.discountedTotal =
    //   //     state.ordersPlaced.currentOrder[
    //   //       state.selectedOrder - 1
    //   //     ].discountedPrice;
    //   //   state.discount =
    //   //     state.ordersPlaced.currentOrder[state.selectedOrder - 1].discount;
    //   // }
    //   if (action.payload) {
    //     state.selectedOrderCode = action.payload?.code;
    //     state.selectedOrderStatus = action.payload?.status.toUpperCase();
    //     state.discountedTotal = action.payload?.discountamount;
    //     state.taxAmount = action.payload.taxamount;
    //     state.discountedAndTaxedAmount = action.payload.totalamount;
    //     state.voucher = action.payload?.voucher;
    //     state.cartTotal =
    //       Number(action.payload?.totalamount) +
    //       Number(action.payload?.discountamount);
    //     state.discount = Number(action.payload?.discountamount);
    //   } else {
    //     state.selectedOrderCode = null;
    //     state.selectedOrderStatus = null;
    //     state.discountedTotal = 0;
    //     state.taxAmount = 0;
    //     state.discountedAndTaxedAmount = 0;
    //     state.voucher = "";
    //     state.cartTotal = 0;
    //     state.discount = 0;
    //   }
    // },

    SET_SHOW_CUSTOMER_POPUP: (state, action: PayloadAction<boolean>) => {
      state.showCustomerPopup = action.payload;
    },
    SET_SELECTED_ORDER_CODE: (state, action: PayloadAction<string>) => {
      state.selectedOrderCode = action.payload;
    },

    SET_SELECTED_ORDER_STATUS: (state, action: PayloadAction<string>) => {
      state.selectedOrderStatus = action.payload.toUpperCase();
    },

    SET_CART_ORDER: (state, action: PayloadAction<CartOrder>) => {
      state.order = action.payload;
    },

    SET_ORDER_JSON: (state, action: PayloadAction<OrderDetails>) => {
      state.orderJson = action.payload;
    },
    SET_BRANCH_TAX_PERCENTAGE: (state, action: PayloadAction<number>) => {
      state.loginData.branchTaxPercentage = action.payload;
      console.log(
        "branch tax percentage: ",
        state.loginData.branchTaxPercentage
      );
    },
  },
});

export const {
  ADD_OR_REMOVE_ADDONS,
  SET_FOCUS_ON_CUSTOMER_NAME_POPUP,
  SET_ORDER_DETAILS,
  INITIALIZE_MENU_ITEM,
  ADD_MENU_ITEM_TO_CURRENT_ORDER,
  UPDATE_MENU_ITEM_TO_CURRENT_ORDER,
  INCREASE_ITEM_QUANTITY,
  SET_ONLINE_BANK_PAYMENT,
  SET_IS_ORDER_READY,
  DECREASE_ITEM_QUANTITY,
  SET_CREDITORS,
  REMOVE_ITEM_FROM_ORDER,
  SET_CREDITOR_NAME,
  DEFAULT_ITEM_PROPERTIES,
  SET_AMOUNT_NOT_REACHED_PROMPT,
  SET_IS_CARD_PAYMENT,
  PLACE_ORDER,
  SET_TABLE_NAME,
  handleOpen,
  INITIALIZE_TABLE_AREA,
  SET_CURRENT_TABLE,
  INITIALIZE_ORDER_DATA,
  HOLD_ORDER,
  SET_SELECTED_ORDER,
  SUSPEND_ORDER,
  SET_PAYMENT_METHOD,
  SET_SEARCHED_ORDER,
  SET_FILTERED_ORDER,
  SET_VOUCHER_DISCOUNT,
  SET_CHECKOUT_POPUP,
  SET_MANUAL_DISCOUNT,
  SET_VOUCHER,
  UPDATE_ITEM_QUANTITY,
  SET_CARD_POPUP,
  SET_CREDIT_POPUP,
  SET_JOINT_POPUP,
  SET_BANK_NAME,
  SET_BIN_CODE,
  SET_CHECKOUT_COMPLETED,
  SET_CARD1_DETAILS,
  SET_CARD2_DETAILS,
  SET_RETURN_SALE,
  SET_CARD_PAYMENT_AMOUNT,
  SET_CASH_AMOUNT,
  SET_CASH_PAYMENT_POPUP,
  SET_CASH_PAID,
  SET_MIN_VOUCHER_ORDER_AMOUNT,
  SET_LOGIN_DATA,
  SET_SELECTED_ORDER_CODE,
  SET_CART_ORDER,
  SET_SELECTED_ORDER_STATUS,
  SET_ORDER_JSON,
  SET_BRANCH_TAX_PERCENTAGE,
  SET_EDITING_ADDON,
  SET_ISEDITING_ADDON,
  UPDATE_EDITED_ADDON,
  SET_SHOW_CUSTOMER_POPUP,
} = cartSlice.actions;
export default cartSlice.reducer;
