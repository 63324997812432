import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "..";
import OrderListingHeader from "../Components/RightBar/OrderListing/OrderListingHeader";
// @ts-ignore
import { IOrderData } from "../Interfaces/IOrderData";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { IoMdArrowDropdown } from "react-icons/io";
import { makeStyles } from "@mui/styles";
import { useIsFirstRender } from "usehooks-ts";
import { BaseService } from "../assets/BaseService";

const useStyles = makeStyles({
  inputRoot: {
    "& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button":
      {
        "-webkit-appearance": "none",
        margin: 0,
      },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
});

const OrderListingPage = () => {
  const token = localStorage.getItem("access_token");
  const classes = useStyles();
  const [isLoadingApi, setLoadingApi] = useState<boolean>(false);
  const [listingOrders, setListingOrders] = useState<Array<IOrderData> | null>(
    null
  );
  const [selectedOrder, setSelectedOrder] = useState<IOrderData | null>(null);
  const [deliveryOptions, setDeliveryOptions] = useState<string | undefined>(
    undefined
  );
  const isFirst = useIsFirstRender();

  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const [deliveryOptionsTrigger, setDeliveryOptionsTrigger] =
    useState<number>(0);
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);

  const handleSelectChange = (e: any, val: IOrderData) => {
    setDeliveryOptions(e.target.value);
    setSelectedOrder(val);
    if (!isFirst) setDeliveryOptionsTrigger(deliveryOptionsTrigger + 1);
  };

  useEffect(() => {
    if (!isFirst) {
      console.log("deliveryOptions", deliveryOptions, selectedOrder);
      BaseService.patch(
        "/dynamic/update",
        {
          collection: "orders",
          data: {
            area: selectedOrder?.area,
            createdAt: selectedOrder?.createdAt,
            deliverycharges: selectedOrder?.deliverycharges,
            discountamount: selectedOrder?.discountamount,
            orderamount: selectedOrder?.orderamount,
            orderdata: selectedOrder?.orderdata,
            paymenttype: selectedOrder?.paymenttype,
            platform: selectedOrder?.platform,
            status: selectedOrder?.status,
            tax: selectedOrder?.tax,
            receipt: selectedOrder?.recipt,
            remarks: selectedOrder?.remarks,
            customerInfo: selectedOrder?.customerInfo,
            taxamount: selectedOrder?.taxamount,
            totalamount: selectedOrder?.totalamount,
            voucher: selectedOrder?.voucher,
            shipmentInfo: selectedOrder?.shipmentInfo,
            vendorInfo: selectedOrder?.vendorInfo,
            shipperInfo: [
              {
                ...selectedOrder?.shipperInfo[0],
                tcsaccount: deliveryOptions,
              },
            ],
          },
        },
        {}
      ).then((res) => {
        console.log(res.data);
      });
    }
  }, [deliveryOptionsTrigger]);

  useEffect(() => {
    BaseService.post(
      "/dynamic/get",
      {
        collection: "orders",
      },
      {}
    ).then((result) => {
      console.log("listing", result.data.data);
      setListingOrders(result.data.data);
    });
  }, []);

  return (
    <div className="w-full h-full flex items-start justify-start flex-col overflow-hidden">
      <OrderListingHeader />
      <div className="w-full h-[48px] min-h-[48px] bg-blue-950 flex items-center justify-between  pl-[10px] pr-[30px]">
        <div className="w-full h-[48px] flex items-center justify-start ">
          <div className="w-[50px] h-[48px] text-white border-r-[1px] border-white font-bold text-[14px] flex items-center justify-center px-1">
            S.No
          </div>
          <div className="w-[280px] h-[48px] text-white border-r-[1px] border-white font-bold text-[14px] flex items-center justify-center">
            Order Number
          </div>
          <div className="w-[110px] h-[48px] text-white border-r-[1px] border-white font-bold text-[14px] flex items-center justify-center">
            Created At
          </div>
          <div className="w-[150px] h-[48px] text-white border-r-[1px] border-white font-bold text-[14px] flex items-center justify-center">
            Branch
          </div>
          <div className="w-[120px] h-[48px] text-white border-r-[1px] border-white font-bold text-[14px] flex items-center justify-center">
            Order Amount
          </div>
          <div className="w-[170px] h-[48px] text-white border-r-[1px] border-white font-bold text-[14px] flex items-center justify-center">
            Delivery Options
          </div>
        </div>
        <div className="w-[220px] h-[48px] text-white font-bold text-[14px] flex items-center justify-center">
          Status
        </div>
      </div>
      <div className="w-full h-full overflow-y-scroll px-[20px] pt-[10px]">
        {listingOrders &&
          listingOrders.map((val: IOrderData, key: number) => (
            <div
              key={key}
              className="flex items-center justify-between h-[48px] rounded-full border-[1px] w-full mb-[16px] pl-[20px] pr-[8px]"
            >
              <div className="text-[14px] text-black flex items-center justify-center">
                <div className="h-[48px] w-[20px] border-r-[1px] border-slate-200 flex items-center justify-center pr-2 ">
                  {key + 1})
                </div>
                <div className="h-[48px] w-[280px] border-r-[1px] border-slate-200 flex items-center justify-start px-2">
                  Order: {val._id}
                </div>
                <div className="h-[48px] w-[110px] border-r-[1px] border-slate-200 flex items-center justify-center px-2">
                  {moment(val.createdAt).utc().format("DD-MM-YYYY")}
                </div>
                <div className="h-[48px] w-[150px] border-r-[1px] border-slate-200 flex items-center justify-start px-2">
                  {val.area}
                </div>
                <div className="h-[48px] w-[120px] border-r-[1px] border-slate-200 flex items-center justify-start px-2">
                  Rs. {val.orderamount}
                </div>
                <div className="h-[48px] w-[170px] border-r-[1px] border-slate-200 flex items-center justify-center">
                  <Select
                    disabled={val.shipperInfo ? false : true}
                    className="max-h-[48px] min-w-[170px] px-0"
                    onChange={(e) => handleSelectChange(e, val)}
                    defaultValue={
                      val.shipperInfo &&
                      val.shipperInfo[0] &&
                      val.shipperInfo[0].tcsaccount
                        ? val.shipperInfo[0].tcsaccount
                        : "Not Selected"
                    }
                    inputProps={{ "aria-label": "Without label" }}
                    sx={darkMode ? { color: "white" } : { color: "black" }}
                    IconComponent={() => {
                      return (
                        <IoMdArrowDropdown
                          size={16}
                          className="min-w-[16px] min-h-[16px] mr-[6px]"
                          style={
                            darkMode ? { color: "white" } : { color: "black" }
                          }
                        />
                      );
                    }}
                  >
                    <MenuItem value="Not Selected">
                      <em>Not Selected</em>
                    </MenuItem>
                    <MenuItem value="TCS">TCS</MenuItem>
                  </Select>
                </div>
              </div>
              <div className="h-[32px] flex items-center justify-center bg-green-600 rounded-full px-[16px]">
                Status: Completed
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default OrderListingPage;
