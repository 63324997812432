import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from "..";

interface Database {
  db: any | null;
}

const initialState: Database = {
  db: null,
};

const database = createSlice({
  name: "rightBarHeader",
  initialState,
  reducers: {
    SET_DB: (state, action: PayloadAction<string>) => {
      state.db = action.payload;
    },
  },
});

export const { SET_DB } = database.actions;
export default database.reducer;
