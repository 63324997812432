import React, { useEffect, useState } from "react";
import Body from "../Components/Body/Body";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import KDSPage from "./KDSPage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "..";
import { makeStyles } from "@mui/styles";
import AddExpensePage from "./AddExpensePage";
import ReservationPage from "./ReservationPage";
import OrderListingPage from "./OrderListingPage";
import PriceCheckerPage from "./PriceCheckerPage";
import { BaseService } from "../assets/BaseService";
import { SET_OPENING_CASH } from "../Features/salesReport";
import {
  SET_ROUTE,
  SET_SELECTEDHEADER,
  SET_VISUAL_HEADER,
} from "../Features/rightBarHeader";
import { SET_SELECTED_LEFT_HEADER } from "../Features/leftBarHeader";
import { SET_CUSTOMER_DATA } from "../Features/customer";
import KDSOrdersPage from "./KDSOrdersPage";

const useStyles = makeStyles({
  inputRoot: {
    "& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button":
      {
        "-webkit-appearance": "none",
        margin: 0,
      },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
});

function PosPage(props: any) {
  //@ts-ignore
  const classes = useStyles();
  const [openTill, setOpenTill] = useState<boolean>(false);
  const [openingCash, setOpeningCash] = useState<number | undefined>(undefined);
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );
  const route = useSelector((state: RootState) => state.header.route);
  console.log("route = ", route);
  const userId = useSelector(
    (state: RootState) => state.leftHeader.response_id
  );
  const openingClosingLog = useSelector(
    (state: RootState) => state.leftHeader.openingClosingLog
  );
  const dispatch = useDispatch();
  const selectedHeader = useSelector(
    (state: RootState) => state.header.selectedHeader
  );
  // const shouldHideFooter = selectedHeader === "Dine-in" || selectedHeader === "Orders" || selectedHeader === "Delivery";

  useEffect(() => {
    if (document.location.search.length === 0) {
      dispatch(SET_SELECTEDHEADER("Dine-in"));
      dispatch(SET_VISUAL_HEADER("Dine-in"));
      dispatch(SET_SELECTED_LEFT_HEADER("Dine-in"));
      dispatch(SET_CUSTOMER_DATA(undefined));
    } else if (document.location.search.includes("customer")) {
      dispatch(SET_SELECTED_LEFT_HEADER("Delivery"));
      dispatch(SET_SELECTEDHEADER("Delivery"));
    }
    console.log("search-params", document.location.search);
  }, [document.location.search]);

  const OpenTillSubmitHandler = async (e: any) => {
    e.preventDefault();
    setOpenTill(false);

    await BaseService.patch(
      "/dynamic/update",
      {
        collection: "open-closing-log",
        filter: {
          createdBy: userId,
          isClosed: false,
        },
        data: {
          openingAmount: openingCash !== undefined ? openingCash : 0,
        },
      },
      {}
    ).then((res) => {
      console.log(res.data.data);
    });
  };

  useEffect(() => {
    if (pos_config.enable_open_till_close_till === true && openingClosingLog) {
      setOpenTill(true);
    }
  }, [pos_config.enable_open_till_close_till]);

  return (
    <div
      className={`w-full h-[100%] flex flex-col ${
        darkMode ? `bg-[#111827]` : `bg-white`
      }`}
    >
      <div className="z-10">
        <Header elementRef={props.elementRef} />
      </div>

      {route === "/PosPage" && (
        <>
          <div className="w-full h-[calc(100%-80px)]">
            <Body />
          </div>
          {/* <div className={`${shouldHideFooter ? "w-0 overflow-hidden" : "w-full absolute bottom-0 h-[50px]"}`}>
            <Footer />
          </div> */}
        </>
      )}
      {route === "/PosPage/KDSPage" && (
        <div className={`w-full h-[calc(100%-70px)]`}>
          <KDSPage />
        </div>
      )}
      {route === "/PosPage/KDSOrders" && (
        <div className="w-full h-[calc(100%-70px)]">
          <KDSOrdersPage/>
        </div>
      )}
      {route === "/PosPage/AddExpensePage" && (
        <div className={`w-full h-[calc(100%-70px)]`}>
          <AddExpensePage />
        </div>
      )}
      {route === "/PosPage/OrderListingPage" && (
        <div className={`w-full h-[calc(100%-70px)]`}>
          <OrderListingPage />
        </div>
      )}
      {route === "/PosPage/ReservationPage" && (
        <div className={`w-full h-[calc(100%-70px)]`}>
          <ReservationPage />
        </div>
      )}
      {route === "/PosPage/PriceCheckerPage" && (
        <div className={`w-full h-[calc(100%-70px)]`}>
          <PriceCheckerPage />
        </div>
      )}
      {pos_config.enable_open_till_close_till && (
        <Modal
          sx={{
            boxShadow: 1,
            borderRadius: 2,
            p: 2,
            minWidth: 300,
            border: 0,
          }}
          disableAutoFocus
          open={openTill}
        >
          <div
            className={`bg-[${
              !darkMode
                ? pos_config?.popup_color
                : pos_config?.popup_color_darkmode
            }] p-[32px] mt-[100px] rounded-[12px] flex-col items-center justify-between border-none w-[450px] mx-auto select-none`}
          >
            <div
              className={`text-[${
                !darkMode
                  ? pos_config?.popup_text_color
                  : pos_config?.popup_text_color_darkmode
              }] text-[24px] font-[700] text-center`}
            >
              Enter Opening Cash
            </div>
            <form
              onSubmit={(e: any) => {
                console.log(
                  "ClosingCashFunction - meow",
                  openingCash !== undefined ? openingCash : 0
                );
                dispatch(
                  SET_OPENING_CASH(openingCash !== undefined ? openingCash : 0)
                );
                OpenTillSubmitHandler(e);
              }}
              className="mt-[32px] gap-4 flex items-center justify-center flex-col"
            >
              <input
                className="w-full h-[56px] border-slate-500 border rounded-lg px-4"
                type="number"
                placeholder="Enter Cash"
                value={openingCash}
                onChange={(e) => setOpeningCash(parseInt(e.target.value))}
                required
                autoFocus
              />
              <button
                type="submit"
                className={`cursor-pointer w-[165px] h-[46px] rounded-[62px] flex items-center justify-center bg-[${
                  !darkMode
                    ? pos_config?.popup_secondary_button_color
                    : pos_config?.popup_secondary_button_color_darkmode
                }] text-[${
                  !darkMode
                    ? pos_config?.popup_secondary_button_text_color
                    : pos_config?.popup_secondary_button_text_color_darkmode
                }] text-[16px] font-[700]`}
              >
                Okay
              </button>
            </form>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default PosPage;
