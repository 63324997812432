import React, { useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../..";
import { SET_FILTERED_ORDER } from "../../../Features/cart";
import { IoMdArrowDropdown } from "react-icons/io";
import { makeStyles } from "@mui/material";

const SelectOrderFilter = () => {
  const cart = useSelector((state: RootState) => state.cart);
  const dispatch = useDispatch();
  const [section, setSection] = React.useState("None");
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(SET_FILTERED_ORDER(event.target.value));
  };

  //   useEffect(() => {
  //     dispatch(SET_FILTERED_ORDER(section));
  //   }, [section]);

  return (
    <div className="flex justify-end items-center">
      <div className="font-[600] text-[16px] mr-2">Filter Orders </div>
      <div>
        <FormControl
          sx={{
            m: 1,
            minWidth: 120,
          }}
        >
          <Select
            value={cart.filteredOrder}
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={darkMode ? { color: "white" } : { color: "black" }}
            IconComponent={() => {
              return (
                <IoMdArrowDropdown
                  style={darkMode ? { color: "white" } : { color: "black" }}
                />
              );
            }}
          >
            <MenuItem value="None">
              <em>None</em>
            </MenuItem>
            <MenuItem value="hold">Hold</MenuItem>
            <MenuItem value="Dispatched">Dispatched</MenuItem>
            <MenuItem value="Suspended">Suspended</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default SelectOrderFilter;
