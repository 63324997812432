import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useRef, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { IoMdArrowDropdown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../..";

const OrderListingHeader = () => {
  const dispatch = useDispatch();
  const [searchField, setSearchField] = useState("");
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);

  const handleChange = (e: any) => {};
  const handleSelectChange = (e: any) => {};

  const ref = useRef(null);

  return (
    <div
      className={`${
        darkMode ? "text-white" : "text-black"
      } w-full h-[75px] flex items-center justify-between  px-[20px]`}
    >
      <div
        id="container"
        className={`${
          darkMode ? "bg-[#111827] border-[#2b2c37] border-[1px]" : "bg-white"
        } border w-full flex items-center justify-start h-[48px] rounded-full px-[20px] mr-[20px]`}
      >
        <div className="min-w-[25px] min-h-[25px] flex items-center justify-center">
          <BiSearch className="w-[25px] h-[25px]" />
        </div>
        <input
          ref={ref}
          id="search"
          // onFocus={handleFocus}
          onChange={handleChange}
          // onBlur={handleBlur}
          className={`${
            darkMode ? "bg-[#111827]" : "bg-white"
          } w-full h-full ml-[20px] outline-none font-[14px]`}
          placeholder="Search by Orders ID"
          type="text"
        />
      </div>
      <div className="">
        <div className="flex justify-end items-center py-[10px]">
          <div className="font-[600] text-[14px] px-[6px] whitespace-nowrap">
            Filter Orders:{" "}
          </div>
          <div className="h-[48px]">
            <Select
              className="max-h-[48px] min-w-[200px] p-0"
              onChange={handleSelectChange}
              defaultValue="None"
              inputProps={{ "aria-label": "Without label" }}
              sx={darkMode ? { color: "white" } : { color: "black" }}
              IconComponent={() => {
                return (
                  <IoMdArrowDropdown
                    size={16}
                    className="min-w-[16px] min-h-[16px] mr-[6px]"
                    style={darkMode ? { color: "white" } : { color: "black" }}
                  />
                );
              }}
            >
              <MenuItem value="None">
                <em>None</em>
              </MenuItem>
              <MenuItem value="In Progress">In Progress</MenuItem>
              <MenuItem value="Dispatched">Dispatched</MenuItem>
              <MenuItem value="Suspended">Suspended</MenuItem>
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderListingHeader;
